import { ChannelName, ChannelHandler, CoinCode, StandardResponse } from 'types';
import { marketUpdated } from 'store/actions';
import Services from 'services';
import { isEuro } from 'helpers/coin';
import { roundWithPrecision } from 'helpers/number';

const channel: ChannelName = 'TickerChannel';
type Channel = typeof channel;

class MarketsService {
  subscribe() {
    this.unsubscribe();
    return Services.get('cable').subscribe<Channel>({
      channel,
      handler: this.handler,
    });
  }
  private handler: ChannelHandler<Channel> = (message) => {
    if (!message) return;

    const { data } = message;
    Services.get('store').dispatch(marketUpdated(data));
  };
  unsubscribe() {
    Services.get('cable').unsubscribe({ channel });
  }

  convertEuro(from: CoinCode, to: CoinCode, quantity: number) {
    if (from === to) return quantity;
    if (!isEuro(from) && !isEuro(to)) throw new Error('Conversion only from or to EUR is possible!');

    return isEuro(from)
      ? this.convertFromEur(to, quantity)
      : this.convertToEur(from, quantity);
  }
  private async convertFromEur(currency: CoinCode, quantity: number) {
    type Response = StandardResponse<{ conversionRate: string, currencyCode: CoinCode }>;
    const result = await Services.get('api').post<Response>(
      'v2/market/crypto_conversion_rates',
      { currency, quantity },
    );
    if (typeof result.success !== 'undefined' && !result.success) throw new Error(result.message);

    return +result.conversionRate;
  }
  private async convertToEur(currency: CoinCode, quantity: number) {
    type Response = StandardResponse<{ conversionRate: string, currencyCode: CoinCode }>;
    const result = await Services.get('api').post<Response>(
      'v2/market/euro_conversion_rates',
      { currency, quantity },
    );
    if (typeof result.success !== 'undefined' && !result.success) throw new Error(result.message);

    return roundWithPrecision(+result.conversionRate, 2);
  }
}

export default MarketsService;
