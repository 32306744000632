import Services from 'services';
import { StandardResponse } from 'types';

class PriceAlertAutoAddingService {
  async fetch() {
    type Response = StandardResponse<{ data: { addAlertsAutomatically: boolean } }>;
    const result = await Services.get('api').get<Response>('settings/notifications/data');
    if (!result.success) throw new Error(result.message);

    return result.data.addAlertsAutomatically;
  }

  update(isEnabled: boolean) {
    return Services.get('api').post('user/notification_settings', {
      name: 'add_alerts_automatically',
      value: isEnabled,
    });
  }
}

export default PriceAlertAutoAddingService;
