import { CoinCode } from 'types';
import { REVERSABLE_COINS, EURO_SYMBOL } from 'constants/coins';
import { fixedClean } from './number';

export const isReversable = (code: CoinCode) => REVERSABLE_COINS.includes(code);

export const isEuro = (code: CoinCode) => code === CoinCode.EUR;

const bigNumberFormat = (num: string | number) => {
  const value = +num;

  if (value >= 1E9) return (value / 1E9).toFixed(2).replace(/\.0$/, '') + 'B';
  if (value >= 1E6) return (value / 1E6).toFixed(2).replace(/\.0$/, '') + 'M';
  return fixedClean(value);
};
export const formatEuro = (value: number | string) => `${ bigNumberFormat(value) }${ EURO_SYMBOL }`;
