import { createBrowserHistory } from 'history';
import { matchPath } from 'react-router';
import { detectLocale } from './locale';

export const history = createBrowserHistory();

export const matchRoute = (url: string, route: string) => matchPath(url, {
  path: route,
  exact: true,
  strict: true,
});

export const isRoute = (url: string, route: string) => matchRoute(url, route) !== null;

export const localize = (url: string) => {
  if (Array.isArray(url)) throw new Error('Cannot localize an array!');

  return `/${ detectLocale() }${ url }`;
};
