import isEqualWith from 'lodash/isEqualWith';

export const unique = <T>(list: T[]): T[] => Array.from(new Set<T>(list));

export const updateAtIndex = <T>(list: T[], index: number, data: Partial<T>): T[] => {
  list[index] = {
    ...list[index],
    ...data,
  };

  return list;
};
export const removeAtIndex = <T>(list: T[], index: number) => {
  list.splice(index, 1);
  return list;
};

export const last = <T>(list: T[]) => list[list.length - 1];

export const compareByProperty = <T>(list: T[], other: T[], property: keyof T): boolean =>
  isEqualWith(list, other, (a, b) => a && b && a[property] === b[property]);
