import { OrderbookEntry, OrderbookSide, OrderType } from 'types';
import { updateAtIndex, removeAtIndex } from 'utils';

export const updateOrderbookItems = (list: OrderbookEntry[], changed: OrderbookEntry[], type: OrderbookSide) => {
  if (!changed.length) return list;

  return changed.reduce((items, entry) => {
    const index = items.findIndex(({ price }) => price === entry.price);

    if (entry.size <= 0) {
      if (index === -1) return items;

      return removeAtIndex(items, index);
    }

    if (index !== -1) {
      return updateAtIndex(items, index, entry);
    }

    items.unshift(entry);
    // sort only when adding new entry
    return items.sort((a, b) => {
      const aPrice = a.price;
      const bPrice = b.price;

      if (type === 'asks') {
        return aPrice - bPrice;
      }

      return bPrice - aPrice;
    });
  }, [...list]);
};

export const parse = (entry: { price: string, size: string }): OrderbookEntry => ({
  price: parseFloat(entry.price),
  size: parseFloat(entry.size),
});

export const reverseOrderbookType = (type: OrderbookSide): OrderbookSide => type === 'bids' ? 'asks' : 'bids';
export const getOrderbookType = (orderType: OrderType, isReversed = false): OrderbookSide => {
  const type = orderType === 'buy' ? 'asks' : 'bids';
  if (isReversed) {
    return reverseOrderbookType(type);
  }

  return type;
};
