type Action<Type = string, Payload = any> = {
  type: Type,
  payload: Payload,
};

interface CreateSimple<Actions> {
  <T extends DefinedKeysNames<Actions>>(type: T, payload: Actions[T]): Action<T, Actions[T]>,
  <T extends UndefinedKeysNames<Actions>>(type: T): Action<T, undefined>,
}
// eslint-disable-next-line
// @ts-ignore
export const createSimple: <Actions>() => CreateSimple<Actions> = () => (type, payload?) => ({
  type,
  payload,
});


interface CreateTyped<Actions> {
  <T extends DefinedKeysNames<Actions>>(type: T): ((data: Actions[T]) => Action<T, Actions[T]>),
  <T extends UndefinedKeysNames<Actions>>(type: T): (() => Action<T>),
}
// eslint-disable-next-line
// @ts-ignore
export const createTyped: <Actions>() => CreateTyped<Actions> = () => (type) => (data?) => {
  if (typeof data === 'undefined') return { type };

  return {
    type,
    payload: data,
  };
};
// old version left for reference
// export const createTyped = <Actions>() => <T extends keyof Actions>(type: T) =>
//   (data: Actions[T]) => createAction(type, data);

type Handlers<Actions, State> = {
  [K in keyof Actions]: (state: State, action: Action<K, Actions[K]>) => State
};

export const createReducer =
  <State, ActionTypes>(initialState: State, handlers: Handlers<ActionTypes, State>) =>
    (state = initialState, action: Action<keyof ActionTypes, ActionTypes[keyof ActionTypes]>) => {
      if (handlers.hasOwnProperty(action.type)) {
        return (handlers[action.type])(state, action);
      } else {
        return state;
      }
    };
