import React from 'react';
import { Helmet } from 'react-helmet-async';
import { getTranslation } from 'components/Translate';
import { has } from 'helpers/translate';

type Props = {
  name: string,
  variables?: Record<string, string>,
};

const Head: React.FC<Props> = ({ name, variables = {}, children }: FunctionProps<Props>) => (
  <Helmet>
    { has(`meta.${ name }.title`) &&
        <title>{ getTranslation(`meta.${ name }.title`, variables) }</title>
    }
    { has(`meta.${ name }.desc`) &&
        <meta
          name="description"
          content={ getTranslation(`meta.${ name }.desc`, variables) }
        />
    }
    { children }
  </Helmet>
);

export default Head;



