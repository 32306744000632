import { StandardResponse, CoinCode, OrderType, Quote, Order, PendingOrder, Card } from 'types';
import Services from 'services';
import { isBuy, isCardPayment } from 'helpers/order';
import { isEuro } from 'helpers/coin';
import { IOrderKind, QuoteParams, OrderParams, ApiQuote } from './types';
import { logger } from 'utils';
import { getPendingOrders } from 'store/orders';

type QuantityType = 'from' | 'to';

abstract class BaseOrder implements IOrderKind {
  abstract order(params: OrderParams): Promise<Order | null>;
  abstract perform(params: any): Promise<Order[] | null>;
  abstract shouldHandle(params: OrderParams | QuoteParams): boolean;

  async getQuote({ valueType, orderType, volume, left, right, paymentMethod, hasAnyFunds }: QuoteParams) {
    let from = left;
    let to = right;
    if (isBuy(orderType)) {
      from = right;
      to = left;
    }

    const volumeCoin = valueType === 'fiat' ? CoinCode.EUR : left;
    let quantity = volume;

    const isEurMarket = isEuro(right);
    const isInEuro = isEuro(volumeCoin) || isEuro(left);
    const needsConversion = left !== volumeCoin;
    if (!isEurMarket && isInEuro && needsConversion) {
      quantity = await Services.get('markets').convertEuro(volumeCoin, left, volume);
    }

    let quantityType: QuantityType = isBuy(orderType) ? 'to' : 'from';

    // if is *-EUR market and value is in EUR then reverse
    if (isEurMarket && isEuro(volumeCoin)) {
      quantityType = quantityType === 'from' ? 'to' : 'from';
    }

    return this.fetchQuote({
      from,
      to,
      quantity,
      quantityType,
      orderType,
      cardId: isCardPayment(paymentMethod) ? paymentMethod.id : null,
      // if does not have any funds than will probably buy with card
      isCard: isCardPayment(paymentMethod) || !hasAnyFunds,
    });
  }
  async fetchQuote({ to, from, quantity, quantityType, cardId, isCard, ...data }: {
    from: CoinCode,
    to: CoinCode,
    orderType: OrderType,
    quantityType: QuantityType,
    quantity: number,
    cardId: Card['id'] | null,
    isCard: boolean,
  }): Promise<Quote> {
    type Response = StandardResponse<ApiQuote & { success?: boolean }>;
    const result = await Services.get('api').post<Response>(
      'v2/market/conversion_rates',
      {
        ...data,
        fromCurrency: from,
        toCurrency: to,
        fromQuantity: quantityType === 'from' ? quantity : undefined,
        toQuantity: quantityType === 'to' ? quantity : undefined,
        cardCharge: isCard,
        cardId,
      },
    );

    if (typeof result.success !== 'undefined' && !result.success) throw new Error(result.message);
    return this.parseQuote(result);
  }
  protected parseQuote(raw: ApiQuote & { success?: boolean }) {
    const { orderType, total, cardFee = 0, fee, fromCurrencyVolume,
      toCurrencyCurrentMarketPrice, success, ...rest } = raw;
    const quote: Quote = {
      ...rest,
      orderType: orderType as OrderType,
      cardFee: +cardFee,
      price: +toCurrencyCurrentMarketPrice,
      volume: +fromCurrencyVolume,
      total: +total,
      fee: +fee,
    };

    return quote;
  }

  protected get pendingOrderId(): PendingOrder['id'] | null {
    const orders = Services.get('store').select(getPendingOrders);
    if (!orders || !orders.length) return null;

    return orders[0].id;
  }

  protected log(...messages: any[]) {
    logger('order', ...messages);
  }
}

export default BaseOrder;
