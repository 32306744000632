import React, { ErrorInfo } from 'react';
import { Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { IS_SENTRY_ENABLED } from 'config';
import ModalsRoot from 'components/Modal/Root';
import Head from 'components/Head';
import { history } from 'helpers/router';
import Routes from './routes';

class App extends React.Component {
  componentDidCatch(error: Error, info: ErrorInfo) {
    if (!IS_SENTRY_ENABLED || !window.Sentry) return;

    window.Sentry.withScope((scope: any) => {
      scope.setExtras(info);
      window.Sentry.captureException(error);
    });
  }

  render() {
    return (
      <HelmetProvider>
        <Head name="main" />
        <Router history={ history }>
          <>
            <Routes />
            <ModalsRoot />
          </>
        </Router>
      </HelmetProvider>
    );
  }
}

export default App;
