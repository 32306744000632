import { CandleChartEntry, MarketPlain } from 'types';
import Services from 'services';

// use staging charts data in development
const BASE_URL = `${ __IS_MOCK__ ? 'https://staging.coinfalcon.com/' : '' }api/v1/markets`;

const MINUTE = 60;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;

const RESOLUTIONS = {
  1: MINUTE,
  5: MINUTE * 5,
  15: MINUTE * 15,
  30: MINUTE * 30,
  60: HOUR,
  180: HOUR * 3,
  240: HOUR * 4,
  360: HOUR * 6,
  720: HOUR * 12,
  D: DAY,
  '1D': DAY,
  '7D': DAY * 7,
  '1M': DAY * 30,
};

type DataArguments = {
  market: MarketPlain['name'],
  from: number,
  to: number,
  resolution: keyof typeof RESOLUTIONS,
};
// TODO: refactor
class MarketChartService {
  async fetch({ market, from, to, resolution }: DataArguments): Promise<CandleChartEntry[]> {
    const url = `${ BASE_URL }/${ market }/lines`;

    const endAt = to;
    const startAt = from;

    if (!RESOLUTIONS.hasOwnProperty(resolution.toString())) {
      throw new Error('[CHARTS] No rate for given period!');
    }

    const result = await Services.get('api').get<CandleChartEntry[]>(url, {
      rates: RESOLUTIONS[resolution],
      endAt,
      startAt,
    });

    const resolutionMilliSeconds = RESOLUTIONS[resolution] * 1000;
    const fromMilliSeconds = from * 1000;
    const flooredFrom = fromMilliSeconds - (fromMilliSeconds % resolutionMilliSeconds);

    const toMilliSeconds = to * 1000;
    const flooredTo = toMilliSeconds - (toMilliSeconds % resolutionMilliSeconds);

    const data = result.reduce((all, item) => {
      const entry = item;
      all[item[0]] = entry;
      return all;
    }, {} as Record<number, CandleChartEntry>);

    let start = flooredFrom;
    // initial close price should be either first items price or 0
    let lastClosePrice = result.length ? result[0][4] : 0;
    while (start <= flooredTo) {
      if (!data[start]) {
        const entry: CandleChartEntry = [start, lastClosePrice, lastClosePrice, lastClosePrice, lastClosePrice, 0];
        data[start] = entry;
      } else {
        lastClosePrice = data[start][4];
      }
      start += resolutionMilliSeconds;
    }

    const results: CandleChartEntry[] = Object.values(data);
    return results.sort((a, b) => a[0] - b[0]);
  }
}

export default MarketChartService;
