import { createTyped } from 'utils';
import { ActionsPayload } from './types';
import { AsyncAction, ReferralsStats } from 'types';
import Services from 'services';
import { hasModalsActive } from 'store/ui/selectors';
import { modalShow } from 'store/ui';
import { hasReferralClaimed } from './selectors';

const createTypedAction = createTyped<ActionsPayload>();

export const referralStatsSet = createTypedAction('referral/STATS_SET');
export const referralStatsFetch = (): AsyncAction<Promise<ReferralsStats>> =>
  async (dispatch) => {
    const data = await Services.get('api').get<ReferralsStats>('v2/referrals');
    dispatch(referralStatsSet(data));
    return data;
  };

export const referralAmountAdd = createTypedAction('referral/AMOUNT_TRADED');
export const referralAmountTraded = (amount: number): AsyncAction<Promise<void>> =>
  async (dispatch, getState) => {
    const hasPreviouslyClaimed = hasReferralClaimed(getState());
    dispatch(referralAmountAdd(amount));

    if (!hasPreviouslyClaimed && hasReferralClaimed(getState())) {
      await Services.get('store').when((state) => !hasModalsActive(state));
      dispatch(modalShow('referral-claimed'));
    }
  };
