import React, { SyntheticEvent, useImperativeHandle, useRef } from 'react';
import { KEY_ESC } from 'constants/keys';
import Icon from 'components/Icon';
import { getTranslation } from 'components/Translate';
import Input from '../Base';

type Props = {
  query: string,
  onChange: (value: string) => void,
  placeholder?: string,
  className?: string,
  onFocus?: (e: SyntheticEvent<HTMLInputElement>) => void,
  onBlur?: (e: SyntheticEvent<HTMLInputElement>) => void,
  maxLength?: number,
};

const SearchInput: React.RefForwardingComponent<{ focus: () => void }, Props> = (props: FunctionProps<Props>, ref) => {
  const {
    query,
    onChange,
    onFocus,
    onBlur,
    placeholder = getTranslation('common.label.search'),
    className = '',
    maxLength = 30,
  } = props;
  const inputRef = useRef<Input>();

  useImperativeHandle(ref, () => ({
    focus: () => {
      if (!inputRef.current) return;
      inputRef.current.focus();
    },
  }));

  return (
    <Input
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      ref={ inputRef }
      className={ className }
      theme="dimmed"
      value={ query }
      placeholder={ placeholder }
      onChange={ onChange }
      leftAddon={ <Icon.Inlined className="search__icon" name="search" color="dark-light" size="x-small" /> }
      rightAddon={
        query.length
        ? <Icon.Inlined name="x" color="dark-light" size="x-small" onClick={ () => onChange('') } />
        : null
      }
      attrs={ {
        onKeyDown: (e) => e.keyCode === KEY_ESC && onChange(''),
        maxLength,
      } }
      onFocus={ onFocus }
      onBlur={ onBlur }
    />
  );
};

export default React.forwardRef(SearchInput);



