import { ChannelName, OnlineCount, Chat, StandardResponse, ChannelHandler } from 'types';
import { chatboxInit, chatboxOnlineCount, addChatMessage, deleteChatMessage } from 'store/chatbox';
import { getChatUsername } from 'store/selectors';
import Services from 'services';

const channel: ChannelName = 'ChatChannel';
type Channel = typeof channel;

class ChatboxService {
  async setUsername(chatUsername: string) {
    const result = await Services.get('api').patch<StandardResponse<{ chatUsername: string }>>(
      'user/set_chat_username',
      { chatUsername },
    );
    if (!result.success) throw new Error(result.message);
    return result.chatUsername;
  }

  async sendMessage(body: string) {
    const result = await Services.get('api').post<StandardResponse<{ chat: Chat }>>(
      'chats',
      { body },
    );
    if (!result.success) throw new Error(result.message);
    return result;
  }

  async deleteMessage(id: Chat['id']) {
    const result = await Services.get('api').del<StandardResponse>(`chats/${ id }`);
    if (!result.success) throw new Error(result.message);
  }

  subscribe() {
    this.unsubscribe();
    return Services.get('cable').subscribe<Channel>({
      channel,
      handler: this.handler,
    });
  }
  private handler: ChannelHandler<Channel> = (message) => {
    if (!message) return;

    const { command, data } = message;
    if (command === 'init') {
      const { chats, onlineCount }  = data as { chats: Chat[], onlineCount: OnlineCount  };
      Services.get('store').dispatch(chatboxInit(chats.reverse()));
      this.updateOnlineCount(onlineCount);
    } else if (command === 'online_count') {
      this.updateOnlineCount(data as OnlineCount);
    } else if (command === 'message') {
      const chatData = data as Chat;
      const chatUsername = Services.get('store').select(getChatUsername);

      // Dont add chat message by the current user
      if (chatUsername !== chatData.chatUsername) {
        Services.get('store').dispatch(addChatMessage(chatData));
      }
    } else if (command === 'delete') {
      Services.get('store').dispatch(deleteChatMessage(data as Chat['id']));
    }
  };
  unsubscribe() {
    Services.get('cable').unsubscribe({ channel });
  }

  private updateOnlineCount(onlineCount: OnlineCount) {
    Services.get('store').dispatch(chatboxOnlineCount(onlineCount));
  }
}

if (__DEV__) {
/* eslint-disable no-underscore-dangle, @typescript-eslint/camelcase, @typescript-eslint/no-use-before-define */
  window.__CHAT_BOT = (() => {
    let chatBotInterval: any = null;
    let messageCount = 0;

    // Init chats with array of chat messages
    function init(count = 10) {
      const initMessage = [];
      for (let i = 0; i < count; i++) {
        initMessage.push(__getMessage());
      }
      window.__CABLE_MESSAGE('ChatChannel', {
        online_count: 404,
        init: { chats: initMessage },
        type: 'init',
      });
    }

    function start(intervalTime = 3000) {
      chatBotInterval = setInterval(send, intervalTime);
    }

    function stop() { clearInterval(chatBotInterval); }

    function send(chat = {}) {
      window.__CABLE_MESSAGE('ChatChannel', {
        chat: __getMessage(chat),
        type: 'message',
      });
    }

    function __getMessage(chat = {}) {
      messageCount += 1;
      return {
        body: `Bot Message ${ messageCount }`,
        chat_username: 'CoinfalconBot',
        deleted: false,
        admin: false,
        id: __uuid(),
        ...chat,
      };
    }

    function __uuid() { return __randomStr() + '-' + __randomStr(); }
    function __randomStr() { return Math.random().toString(36).substring(7); }

    return { init, start, send, stop };
  })();
}

export default ChatboxService;
