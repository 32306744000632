import { TransferType, Withdrawal, Transfer } from 'types';
import { updateAtIndex } from 'utils';
import Label from 'components/Label';

const CANCELABLE_STATUS: Array<Withdrawal['status']> = ['pending', 'approval_pending'];
const PENDING_STATUS: Array<Withdrawal['status']> = ['processing', 'pending', 'approval_pending'];
const WARNING_STATUS: Array<Withdrawal['status']> = ['processing', 'pending'];

export const isDeposit = (type: TransferType) => type === 'deposit';
export const isProcessing = (status: Withdrawal['status']) => status === 'processing';
export const isCancellable = (status: Withdrawal['status']) => CANCELABLE_STATUS.includes(status);
export const isPending = (status: Withdrawal['status']) => PENDING_STATUS.includes(status);
export const isWarningTag = (status: Withdrawal['status']) => WARNING_STATUS.includes(status);

export const onTransferUpdated = <T extends Transfer>(list: T[], transfer: Partial<T>): T[] => {
  const index = list.findIndex(({ id }) => id === transfer.id);
  if (index === -1) {
    return [...list, transfer as T];
  }

  return updateAtIndex([...list], index, transfer);
};

export const getTransferLabelColor = (status: Transfer['status']): GCP<typeof Label>['theme'] => {
  if (isPending(status)) return 'warning';
  if (status === 'canceled' || status === 'denied') return 'error';

  return 'success';
};
