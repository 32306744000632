import { Order, CoinCode } from 'types';
import { ITracker } from './index';
import Services from 'services';
import { getLatestCoinEuroPrice } from 'store/fiat';

class GoogleAnalyticsTracker implements ITracker {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onLogin() {
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onLogout() {
  }

  onOrderSuccess(order: Order) {
    if (!this.dataLayer) return;

    let revenue = +order.fee;
    if (order.feeCurrencyCode.toLowerCase() !== CoinCode.EUR) {
      const currencyEurPrice = Services.get('store').select(getLatestCoinEuroPrice)(order.feeCurrencyCode);
      revenue *= currencyEurPrice;
    }

    this.dataLayer.push({
      event: 'purchase',
      ecommerce: {
        currencyCode: 'EUR',
        purchase: {
          actionField: {
            id: order.id,
            revenue,
          },
          products: [{
            name: order.market,
            price: revenue,
            quantity: 1,
          },
          ],
        },
      },
    });
  }

  private get dataLayer() {
    return window.dataLayer;
  }
}

export default GoogleAnalyticsTracker;
