import { AsyncAction, PendingOrder, PendingOrderRequest, Order, CoinCode } from 'types';
import { createTyped } from 'utils';
import Services from 'services';
import { StoreState } from 'store';
import { userInfoSet } from 'store/user';
import { referralAmountTraded } from 'store/referral';
import { parseMarketName } from 'helpers/markets';

import { ActionsPayload } from './types';
import { getPendingOrders } from './selectors';

const createTypedAction = createTyped<ActionsPayload>();

export const ordersPendingSet = createTypedAction('orders/PENDING_SET');
export const ordersPendingFetch = (): AsyncAction<Promise<void>> =>
  async (dispatch) => {
    const result = await Services.get('orders').pending.fetch();
    dispatch(ordersPendingSet(result));
  };

type CreateParams = Omit<StoreState['buysellStore'], 'quote' | 'quoteError' | 'reverse' | 'reverseFiat'>;
export const orderPendingCreate = (data: CreateParams): AsyncAction<Promise<PendingOrder | null>> =>
  async (dispatch) => {
    try {
      const params: PendingOrderRequest = {
        orderType: data.type,
        leftCurrencyCode: data.left,
        rightCurrencyCode: data.right,
        amount: data.value,
        amountType: data.valueType,
        paymentMethod: data.paymentMethod.type,
        cardId: data.paymentMethod.id,
      };
      const result = await Services.get('orders').pending.create(params);
      dispatch(ordersPendingSet([result]));
      return result;
    } catch (e) {
      return null;
    }
  };

export const ordersPendingRemove = createTypedAction('orders/PENDING_REMOVE');

export const ordersPendingCancel = (id: PendingOrder['id']): AsyncAction<Promise<void>> =>
  async (dispatch) => {
    dispatch(ordersPendingRemove());
    await Services.get('orders').pending.cancel(id);
  };
export const ordersPendingCancelAll = (): AsyncAction<void> =>
  (dispatch, getState) => {
    const orders = getPendingOrders(getState());
    (orders || []).forEach(({ id }) => dispatch(ordersPendingCancel(id)));
  };


export const orderFulfilled = (order: Order | null): AsyncAction<void> =>
  (dispatch) => {
    Services.get('events').emit('BUY_SELL_ORDER_SUCCESS', order);

    dispatch(userInfoSet({ hasTrades: true }));
    if (!order) return;

    const { right } = parseMarketName(order.market);
    // referral trades only count if market is *-EUR
    if (right === CoinCode.EUR && order.price) {
      const amount = parseFloat(order.sizeFilled) * parseFloat(order.price);
      dispatch(referralAmountTraded(amount));
    }
  };
