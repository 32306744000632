import { CoinCode, FiatCurrency } from 'types';
import memoize from 'lodash/memoize';
import { fixed, toNumber, isNaN } from './number';

export const isFiat = (code: CoinCode) => code === CoinCode.EUR;
export const hasFiatPrecision = (code: CoinCode) => [CoinCode.EUR, CoinCode.USDT].includes(code);

const isIntlAvailable = 'Intl' in window && (window as any).Intl;
const getFormatter = (language: string, options: Intl.NumberFormatOptions = {}) => {
  if (!isIntlAvailable) {
    return {
      format: (value: number) => fixed(value, options.maximumFractionDigits || 2),
    };
  }

  return new Intl.NumberFormat(language, options);
};

const fiatFormatter = getFormatter('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});
const fiatFormatterDetailed = getFormatter('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 5,
});
export const formatFiatCurrencyNumber = (val: string | number, precision = 2) => {
  const numericValue = toNumber(val);
  const value = isNaN(numericValue) ? 0 : numericValue;

  if (precision === 2) return fiatFormatter.format(value);
  if (precision === 5) return fiatFormatterDetailed.format(value);

  return getFormatter('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: precision,
  }).format(value);
};

export const getCurrencySymbol = memoize((currency: FiatCurrency['code']): string => {
  if (!isIntlAvailable) {
    return currency.toUpperCase();
  }

  const result = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(0);

  return result.replace('0', '').replace(/ /g, '');
});

export const getFiatCurrencyString = (value: string | number, currency: FiatCurrency['code'], precision = 2) => {
  const numericValue = isNaN(value) ? 0 : toNumber(value);
  const symbol = getCurrencySymbol(currency);
  const separator = symbol.length > 2 ? ' ' : '';
  return `${ formatFiatCurrencyNumber(numericValue, precision) }${ separator }${ symbol }`;
};

export const getFiatEquivalentPrecision = (value: number) => {
  if (value > 0 && value < 0.01) return 5;

  return 2;
};
