import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import debounce from 'lodash/debounce';
import { history } from 'helpers/router';
import { DASHBOARD, LANDING } from 'constants/routes';
import { HEADER_STICKY_CLASS } from 'constants/ui';

import { isLoggedIn } from 'store/selectors';
import Logo from 'components/Logo';

import styles from './Header.module.scss';
import Authenticated from './Authenticated';
import Anonymous from './Anonymous';

// TODO: add JS onScroll function toggle class _isSticky to ONLY noauth__header

const Header: React.FC = () => {
  const [isSticky, setSticky] = useState(false);
  const isAuthenticated = useSelector(isLoggedIn);

  const handleScroll = useCallback(debounce(
    () => setSticky(window.scrollY > 0),
    50,
    { leading: true, trailing: true },
  ), []);
  useEffect(() => {
    if (isAuthenticated) return;

    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const classes = classnames(styles.header, {
    [styles.noauth__header]: !isAuthenticated,
    [styles.isSticky]: isSticky,
    [HEADER_STICKY_CLASS]: !isAuthenticated,
  });

  return (
    <header className={ classes }>
      <div className="container d-flex align-items-center justify-content-between">
        <Logo className={ styles.logo } onClick={ () => history.push(isAuthenticated ? DASHBOARD : LANDING) } />

        { isAuthenticated
          ? <Authenticated />
          : <Anonymous />
        }
      </div>
    </header>
  );
};

export default Header;
