import { CoinCode } from './coin-code';

export type PriceAlertType = 'more_than' | 'less_than' | 'increased_by' | 'decreased_by' | 'changed_by';
export const PRICE_ALERT_TYPES: PriceAlertType[] =
  ['more_than', 'less_than', 'increased_by', 'decreased_by', 'changed_by'];

export type Alert = {
  currencyCode: CoinCode,
  priceCurrencyCode: string,
  alertType: PriceAlertType,
  trigger: string,
};

export type PriceAlert = Alert & {
  id: number,
  basePrice: string,
};
