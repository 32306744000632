import React from 'react';
import { Route, RouteProps, Redirect } from 'react-router';
import { connect } from 'react-redux';
import { StoreState, MapActionsObject } from 'types';
import { LOGIN } from 'constants/routes';
import { isLoggedIn, isUserChecked } from 'store/selectors';
import { userFetch } from 'store/actions';

const select = (state: StoreState) => ({
  isChecked: isUserChecked(state),
  isAuthenticated: isLoggedIn(state),
});

const mapActions = {
  userFetch,
};

type MappedProps = ReturnType<typeof select>;
type MappedActions = MapActionsObject<typeof mapActions>;

class AuthRoute extends React.Component<MappedProps & MappedActions & RouteProps> {
  componentDidMount() {
    if (this.props.isChecked) return;
    this.check();
  }

  private check() {
    this.props.userFetch();
  }

  render() {
    const { component: Component, children, ...rest } = this.props;

    if (this.props.isChecked && !this.props.isAuthenticated) {
      return (
        <Redirect
          to={ {
            pathname: LOGIN,
            state: { from: this.props.location },
          } }
        />
      );
    }

    return (
      <Route
        { ...rest }
        render={ this.renderRoute }
      />
    );
  }
  private renderRoute = (props: any) => {
    if (!this.props.isChecked) return null;

    const { component: Component, children } = this.props;
    if (!Component) {
      return children;
    }

    return <Component { ...props } />;
  };
}

export default connect<MappedProps, typeof mapActions, RouteProps, StoreState>(
  select,
  mapActions,
)(AuthRoute);
