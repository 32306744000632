import { createSelector } from 'reselect';
import { StoreState } from '../index';

const getState = (state: StoreState) => state.notificationsStore || {};

export const getNotifications = createSelector(
  [getState],
  (state) => state.list,
);

