import React from 'react';
import { TextWeight, TextColor } from 'types';

type Props = {
  tag?: keyof React.ReactHTML,
  type?: 'normal' | 'small',
  weight?: TextWeight,
  color?: TextColor,
  className?: string,
  title?: string,
  onClick?: () => void,
};
const Subtitle: React.FC<Props> = ({
  tag = 'p', type = 'normal', weight = 'regular', color = 'black', className = '', title, onClick, children,
}: FunctionProps<Props>) => {
  const classNames = `subtitle subtitle--${ type } ${ weight } text-color--${ color } ${ className }`;
  return React.createElement(tag, {
    className: classNames,
    title,
    onClick: onClick && (() => onClick()),
  }, children);
};

export default Subtitle;
