import { pad } from './text';

export const isSameDay = (d1: Date, d2: Date) =>
  d1.getFullYear() === d2.getFullYear() &&
  d1.getMonth() === d2.getMonth() &&
  d1.getDate() === d2.getDate();


// eslint-disable-next-line max-len
const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const MONTHS_ABBREV = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const WEEK_DAY = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const WEEK_DAY_ABBREV = WEEK_DAY.map((day) => day.substring(0, 3));

export const getTime = (date: Date, seconds = true) => {
  const time = [pad(date.getHours()), pad(date.getMinutes())];

  if (seconds) {
    time.push(pad(date.getSeconds()));
  }

  return time.join(':');
};

export const getMonthName = (date: Date, abbreviation = false) =>
  abbreviation ? MONTHS_ABBREV[date.getMonth()] : MONTHS[date.getMonth()];
export const getDateWithMonthName = (date: Date, abbreviation = false) =>
  `${ pad(date.getDate()) } ${ getMonthName(date, abbreviation) } ${ date.getFullYear() }`;

export const getDate = (date: Date, separator = '.') =>
  [pad(date.getDate()), pad(date.getMonth() + 1), date.getFullYear()].join(separator);
export const getDateShortYear = (date: Date, separator = '-') =>
  [date.getFullYear() - 2000, pad(date.getMonth() + 1), pad(date.getDate())].join(separator);

export const dateText = (date: Date) =>
  `${ getDateShortYear(date) } - ${ getTime(date) }`;

export const getTimeZone = () => {
  try {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (err) {
    return 'UTC';
  }
};

export const getDateWithWeekDayName = (date: Date, abbreviation = true) =>
  [pad(date.getDate()), WEEK_DAY_ABBREV[date.getDay()], getMonthName(date, abbreviation), date.getFullYear()];
