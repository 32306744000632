import { Order, StandardResponse, CoinCode, OrderStatus, DateRange, PaginationResult, OrderTrade } from 'types';
import Services from 'services';

class OrderFetcher {
  async fetch(parameters: Partial<{
    page: number,
    market: string | null,
    side: string | null,
    type: string | null,
    status: OrderStatus | null,
    dates: string,
    datesRange: null | DateRange,
    dateFrom: string,
    dateTo: string,
  }> = {}) {
    type Response = StandardResponse<PaginationResult<Order>>;
    const result = await Services.get('api').get<Response>('history/data/orders', parameters);
    if (!result.success) {
      throw new Error(result.message);
    }

    return result;
  }

  async fetchBuySell(parameters: Partial<{
    page: number,
    currency: string | null,
    side: string | null,
    status: OrderStatus | null,
    dates: string,
    datesRange: null | DateRange,
    dateFrom: string,
    dateTo: string,
  }> = {}) {
    type Response = StandardResponse<PaginationResult<Order>>;
    const result = await Services.get('api').get<Response>('history/data/buy-sell', parameters);
    if (!result.success) {
      throw new Error(result.message);
    }

    return result;
  }
  async fetchSingle(id: Order['id']) {
    type Response = StandardResponse<{ order: Order }>;
    const result = await Services.get('api').get<Response>(`v2/orders/${ id }`);
    if (!result.success) {
      throw new Error(result.message);
    }

    return result.order;
  }
  async fetchLastBought() {
    type Response = StandardResponse<{ last: CoinCode | null }>;
    const result = await Services.get('api').get<Response>('v2/order/last_bought');
    if (!result.success) {
      throw new Error(result.message);
    }

    return result.last;
  }

  async fetchTrades(id: Order['id']) {
    type Response = StandardResponse<{ trades: OrderTrade[] }>;
    const result = await Services.get('api').get<Response>(`orders/${ id }/trades`);
    if (!result.success) {
      throw new Error(result.message);
    }

    return result.trades;
  }

  async fetchStatus(id: Order['id']) {
    const result = await this.fetchSingle(id);
    return result.status;
  }
}

export default OrderFetcher;
