import { CoinCode, ModalsCustomData, Card3dOrderCallback, Order } from 'types';
import get from 'lodash/get';
import Services from 'services';
import { getTranslation } from 'components/Translate';
import { last, delay } from 'utils';
import { getBalanceByCode } from 'store/balances';
import { getCardsList } from 'store/payments';
import { buysellUpdate } from 'store/buysell';
import { modalShow } from 'store/ui';
import { ordersPendingCancelAll } from 'store/orders';
import { referralStatsFetch } from 'store/actions';
import { getCoinByCode } from 'store/coins';

import { parsePurpose } from 'helpers/card';
import { parseMarketName } from 'helpers/markets';

import { CardOrderCallback } from '../types';

export const handleOrder = (result: CardOrderCallback) => {
  let config: ModalsCustomData['buysell-preview-card'];

  const isSuccess = result.status && result.orders && !!result.orders.length;
  if (isSuccess) {
    config = onSuccess(result);
  } else if (result.code === 'deposited') {
    config = onDeposited(result);
  } else {
    config = onError(result);
  }

  Services.get('store').dispatch(modalShow('buysell-preview-card', {
    custom: config,
  }));
};

function onSuccess(result: CardOrderCallback): ModalsCustomData['buysell-preview-card'] {
  return {
    step: 'processing',
    onProcessed: new Promise<Card3dOrderCallback>((resolve) =>
      checkOrder(last(result.orders!), resolve)),
  };
}
async function checkOrder(order: Order, resolve: (data: Card3dOrderCallback) => void) {
  if (order.status === 'fulfilled') {
    const { left: coinCode } = parseMarketName(order.market);
    const balance = +get(Services.get('store').select(getBalanceByCode)(coinCode), 'balance', '0');
    resolve({
      coin: Services.get('store').select(getCoinByCode)(coinCode)!,
      amount: +order.sizeFilled,
      balance,
    });

    onOrderSuccess();
    return;
  }

  await delay(1 * 1000);
  const fetched = await Services.get('orders').fetcher.fetchSingle(order.id);
  checkOrder(fetched, resolve);
}
function onOrderSuccess() {
  // cancel all pending orders after successfull order execution
  Services.get('store').dispatch(ordersPendingCancelAll());

  // refetch referral stats to have exact tradedAmount
  Services.get('store').dispatch(referralStatsFetch());
}


function onDeposited(result: CardOrderCallback): ModalsCustomData['buysell-preview-card'] {
  const { amount = '0', code = CoinCode.BTC } = parsePurpose(result.type);
  const cards = Services.get('store').select(getCardsList);
  const card = cards.length ? cards[0] : null;
  Services.get('store').dispatch(buysellUpdate({
    left: code,
    right: CoinCode.EUR,
    value: amount,
    valueType: 'current',
    paymentMethod: {
      id: card ? card.id : null,
      type: card ? 'card' : 'coin',
    },
  }));

  return {
    step: 'deposited',
    amount: result.amount,
    onProcessed: Promise.reject(),
  };
}


function onError(result: CardOrderCallback): ModalsCustomData['buysell-preview-card'] {
  return {
    step: 'error',
    error: result.message || getTranslation('buySell.cardError'),
    onProcessed: Promise.reject(),
  };
}
