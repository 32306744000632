import { OrderType, Coin, Market, ReceiptValues, Order, OrderRequest, StandardResponse } from 'types';
import Services from 'services';

import { last } from 'utils';

import BaseOrder from './base';
import { IOrderKind } from './types';

export type OrderParams = {
  isReversed: boolean,
  type: OrderType,
  rightCoin: Coin,
  leftCoin: Coin,
  market: Market | null,
  receiptValues: ReceiptValues,
};

class StandardOrder extends BaseOrder implements IOrderKind {
  async order({ isReversed, receiptValues, type }: OrderParams) {
    if (!receiptValues.quote) throw new Error('Quote not available!');
    const data: OrderRequest = receiptValues.quote.createOrderParams.order;

    this.log('perform', data, { isReversed, type });
    const orders = await this.perform(data);

    this.log('result', orders);
    return orders.length ? last(orders) : null;
  }
  async perform(data: OrderRequest) {
    const order = {
      ...data,
      pendingOrderUuid: this.pendingOrderId,
    };

    type Response = StandardResponse<{ orders: Order[] }>;
    const result = await Services.get('api').post<Response>('orders/create_order', { order });
    if (!result.success) throw new Error(result.message);

    return result.orders;
  }

  // default Order type
  shouldHandle() {
    return true;
  }

  protected log(...messages: any[]) {
    super.log('[STANDARD]', ...messages);
  }
}

export default StandardOrder;
