import { StandardResponse, UserSession, PaginationResult } from 'types';
import Services from 'services';

class SessionsService {
  fetch = async (page = 1) => {
    type Response = StandardResponse<PaginationResult<UserSession>>;
    const result = await Services.get('api').get<Response>('settings/security/sessions', {
      page,
    });
    if (!result.success) throw new Error(result.message);

    return result;
  };
  removeById(id: UserSession['sessionId']) {
    return this.remove(id);
  }
  removeAll() {
    return this.remove('all');
  }

  private async remove(id: UserSession['sessionId'] | 'all') {
    const result = await Services.get('api').del<StandardResponse>(`settings/security/sessions?id=${ id }`);
    if (!result.success) throw new Error(result.message);
  }
}

export default SessionsService;
