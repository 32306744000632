import { createSelector } from 'reselect';
import { StoreState } from '../index';

const getState = (state: StoreState) => state.chatboxStore || {};

export const getChatMessages = createSelector(
  [getState],
  (state) => state.chats.filter((chat) => !chat.deleted),
);

export const getOnlineCount = createSelector(
  [getState],
  (state) => state.onlineCount,
);
