import { StateBalance, ChartEntries } from 'types';
import { createReducer, updateAtIndex, logger } from 'utils';
import { ActionsPayload as UserActions } from 'store/user';
import { ActionsPayload } from './types';

export type State = Readonly<{
  balances: StateBalance[] | null,
  fundsChart: ChartEntries,
}>;

export const initialState: State = {
  balances: null,
  fundsChart: [],
};

const balancesReducer = createReducer<State, ActionsPayload & Pick<UserActions, 'user/LOGGED_OUT'>>(
  initialState,
  {
    'balances/SET': (state, { payload }) => ({
      ...state,
      balances: payload,
    }),
    'balances/UPDATED': (state, { payload }) => {
      const balances = [...(state.balances || [])];
      payload.forEach((balance) => {
        const index = balances.findIndex(({ currencyCode }) => currencyCode === balance.currencyCode);
        if (index === -1) {
          balances.push(balance);
          return;
        }

        if (__DEV__ || __STAGING__) {
          const prev = +balances[index].balance;
          const current = +balance.balance;
          logger('balance', '[UPDATE]',
            balance.currencyCode.toUpperCase(), current, '-', prev, '=', current - prev);
        }
        updateAtIndex(balances, index, balance);
      });

      return {
        ...state,
        balances,
      };
    },
    'balances/FUNDS_CHART_SET': (state, { payload }) => ({
      ...state,
      fundsChart: payload,
    }),
    'user/LOGGED_OUT': () => ({
      ...initialState,
    }),
  },
);

export default balancesReducer;
