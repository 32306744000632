import { Deposit, Withdrawal } from 'types';
import { createReducer } from 'utils';
import { onTransferUpdated } from 'helpers/transfers';
import { ActionsPayload as UserActions } from 'store/user';
import { ActionsPayload } from './types';

export type State = Readonly<{
  deposits: Deposit[] | null,
  withdrawals: Withdrawal[] | null,
}>;

export const initialState: State = {
  deposits: null,
  withdrawals: null,
};

const transfersReducer = createReducer<State, ActionsPayload & Pick<UserActions, 'user/LOGGED_OUT'>>(
  initialState,
  {
    'transfers/DEPOSITS_SET': (state, { payload }) => ({
      ...state,
      deposits: payload,
    }),
    'transfers/DEPOSIT_UPDATED': (state, { payload }) => ({
      ...state,
      deposits: onTransferUpdated(state.deposits || [], payload),
    }),

    'transfers/WITHDRAWALS_SET': (state, { payload }) => ({
      ...state,
      withdrawals: payload,
    }),
    'transfers/WITHDRAWAL_CANCEL': (state, { payload }) => ({
      ...state,
      withdrawals: onTransferUpdated(state.withdrawals || [], { ...payload, status: 'canceled' }),
    }),
    'transfers/WITHDRAWAL_UPDATED': (state, { payload }) => ({
      ...state,
      withdrawals: onTransferUpdated(state.withdrawals || [], payload),
    }),

    'user/LOGGED_OUT': () => ({
      ...initialState,
    }),
  },
);

export default transfersReducer;
