import { CoinCode, CoinPriceInfo } from 'types';

export const REVERSABLE_COINS = [CoinCode.EUR, CoinCode.BTC, CoinCode.ETH, CoinCode.USDT];
export const GENERATE_ADDRESS_COINS = [CoinCode.IOT];
export const FIAT_SORT_ORDER = ['eur', 'usd', 'cny', 'gbp', 'cad', 'aud'];

export const EURO_SYMBOL = '€';

export const COIN_PRICE_INFO_EMPTY: Omit<CoinPriceInfo, 'code'> = {
  chart: [],
  duration: '1d',
  percentChange: 0,
  priceChange: 0,
};
