import { ChannelMessages, ChannelName, ChannelDefinition } from 'types';

export const CHANNELS_LIST = {
  TickerChannel: {
    parameters: ['market'],
    received: (message: ChannelMessages['TickerChannel']) => {
      const command = 'update';
      const { marketInfo = {} } = message;
      return {
        command,
        data: marketInfo,
      };
    },
  },
  TradesChannel: {
    parameters: ['market'],
    received: (message: ChannelMessages['TradesChannel']) => {
      if (message.init) {
        return {
          command: 'init',
          data: message.init.trades,
        };
      }

      return {
        command: 'update',
        data: message.trade,
      };
    },
  },
  OrderbookChannel: {
    parameters: ['market'],
    received: (message: ChannelMessages['OrderbookChannel']) => {
      if (message.hasOwnProperty('init')) {
        return {
          command: 'init' as 'init',
          data: message.init,
        };
      }

      const { key, value: data } = message.update;
      return {
        command: key,
        data,
      };
    },
  },
  UserOrdersChannel: {
    parameters: ['market'],
    received: (message: ChannelMessages['UserOrdersChannel']) => {
      if (message.hasOwnProperty('init')) {
        return {
          command: 'init',
          data: message.init,
        };
      }

      return {
        command: 'update',
        data: message.order,
      };
    },
  },
  UserTradesChannel: {
    received: (message: ChannelMessages['UserTradesChannel']) => {
      if (message.init) {
        return {
          command: 'init',
          data: message.init.trades,
        };
      }

      return {
        command: 'update',
        data: message.trade,
      };
    },
  },
  AccountChannel: {
    received: (message: ChannelMessages['AccountChannel']) => {
      const LOGOUT = 'logout';

      if (message.messageType) {
        if (message.messageType === LOGOUT) {
          return {
            command: LOGOUT,
            data: null,
          };
        }

        return null;
      }

      let { balances = [] } = message;
      if (!Array.isArray(balances)) {
        if (!balances.hasOwnProperty('currencyCode')
          || !balances.hasOwnProperty('availableBalance')) {
          return null;
        }

        balances = [balances];
      }

      return {
        command: 'update',
        data: { balances },
      };
    },
  },
  AnnouncementsChannel: {
    received: (data: ChannelMessages['AnnouncementsChannel']) => ({ command: '', data }),
  },
  NotificationChannel: {
    received: (message: ChannelMessages['NotificationChannel']) => {
      if (message.transfer) {
        const data = message.transfer;
        return {
          command: data.type,
          data,
        };
      }

      if (message.alert) {
        const data = message.alert;
        return {
          command: 'alert',
          data,
        };
      }

      if (message.messageType === 'verification') {
        return {
          command: 'verification',
          data: {
            status: message.status,
            rejectReason: message.rejectReason,
          },
        };
      }

      return null;
    },
  },
  ChatChannel: {
    received: (message: ChannelMessages['ChatChannel']) => {
      if (message.type) {
        if (message.init) {
          return {
            command: message.type,
            data: {
              chats: message.init.chats,
              onlineCount: message.onlineCount,
            },
          };
        }

        if (message.type === 'online_count') {
          return {
            command: message.type,
            data: message.onlineCount,
          };
        }

        if (message.type === 'message') {
          return {
            command: message.type,
            data: message.chat,
          };
        }
        if (message.type === 'delete') {
          return {
            command: message.type,
            data: message.chat.id,
          };
        }
      }

      return null;
    },
  },
};

export const CHANNELS: Record<ChannelName, ChannelDefinition> = CHANNELS_LIST;
