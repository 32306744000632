import { Balance, CoinCode } from 'types';

export const BALANCE_EMPTY: Balance = {
  id: '',
  code: CoinCode.EMPTY,
  name: '',
  balance: '0',
  availableBalance: '0',
  holdBalance: '0',
  btc: 0,
  eur: 0,
  precision: 2,
};
