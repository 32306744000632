import React from 'react';
import classNames from 'classnames';
import styles from './Button.module.scss';

export type ButtonTheme = 'primary' | 'danger' | 'success' | 'ghost' | 'disabled' | 'link' | 'link-subtle' | 'subtle';
export type ButtonSize = 'tiny' | 'small' | 'medium' | 'large';

type Props = {
  theme?: ButtonTheme,
  size?: ButtonSize,
  fullWidth?: boolean,
  disabled?: boolean,
  isLoading?: boolean,
  loadingText?: string,
  className?: string,
  onClick?: () => any,
  onMouseDown?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => any,
  stopPropagation?: boolean,
};

class Button extends React.PureComponent<Required<Props>> {
  static defaultProps: OptionalProps<Props> = {
    theme: 'primary',
    size: 'medium',
    fullWidth: false,
    disabled: false,
    isLoading: false,
    loadingText: '',
    className: '',
    stopPropagation: false,
  };

  render() {
    const { theme, size, disabled, fullWidth, isLoading, loadingText, onClick, stopPropagation, onMouseDown,
      children } = this.props;
    const className = classNames(styles.button, styles[theme], styles[size], this.props.className, {
      [styles.isLoading]: isLoading,
      [styles.block]: fullWidth,
    });

    let content: React.ReactNode = null;
    if (!isLoading) {
      content = children;
    } else {
      content = (
        <>
          { <span className={ styles.loader } /> }
          { !!loadingText.length && <span className={ styles.loader__text }>{ loadingText }</span> }
        </>
      );
    }

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      if (disabled || isLoading) return;
      if (stopPropagation) e.stopPropagation();

      onClick();
    };

    return (
      <button
        className={ className }
        disabled={ disabled }
        onClick={ onClick && handleClick }
        onMouseDown={ onMouseDown && onMouseDown }
      >
        { content }
      </button>
    );
  }
}

export default Button;
