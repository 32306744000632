import React from 'react';
import { TextColor } from 'types';

type Props = {
  type: 'large' | 'medium' | 'small',
  color?: TextColor,
  className?: string,
};
const Heading: React.FC<Props> =
({
  type = 'medium',
  color = 'black',
  className = '',
  children,
}: FunctionProps<Props>) => {
  const typeMap: Record<Props['type'], keyof React.ReactHTML> = {
    large: 'h1',
    medium: 'h2',
    small: 'h3',
  };
  const classNames = `text-color--${ color } ${ className }`;

  return React.createElement(typeMap[type], {
    className: classNames,
  }, children);
};

export default Heading;
