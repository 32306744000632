import { AsyncAction, Notification } from 'types';
import { createTyped, delay } from 'utils';
import { hasModalsActive } from 'store/selectors';
import Services from 'services';
import { ActionsPayload } from './types';

const createTypedAction = createTyped<ActionsPayload>();

const DELAY_AFTER_MODAL_CLOSED = 0.5 * 1000;

const areModalsClosed = () => {
  const check = async (resolve: () => void) => {
    await Services.get('store').when((state) => !hasModalsActive(state));
    await delay(DELAY_AFTER_MODAL_CLOSED);
    const areActive = Services.get('store').select(hasModalsActive);
    if (areActive) {
      check(resolve);
    } else {
      resolve();
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  return new Promise(check);
};

export const notificationShowImmediately = createTypedAction('notifications/SHOW');
export const notificationShow = (data: Notification): AsyncAction<Promise<void>> =>
  async (dispatch) => {
    await areModalsClosed();
    dispatch(notificationShowImmediately(data));
  };

export const notificationHidden = createTypedAction('notifications/HIDE');
export const notificationHide = ({ id, type }: Notification): AsyncAction<Promise<void>> =>
  async (dispatch) => {
    dispatch(notificationHidden(id));
    if (type === 'api') {
      await Services.get('announcements').read(id);
    }
  };
