/* eslint-disable max-len */

import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import * as ROUTES from 'constants/routes';
import { loadable } from 'helpers/component';

import AppRoute from 'routes/App';
import { WITHDRAW_ROUTES } from 'routes/Withdraw/routes-group';
import { DEPOSIT_ROUTES } from 'routes/Deposit/routes-group';

const Dashboard = loadable(() => import(/* webpackChunkName: "dashboard" */ './Wrapper'));
const Withdraw = loadable(() => import(/* webpackChunkName: "withdraw" */ 'routes/Withdraw'));
const Deposit = loadable(() => import(/* webpackChunkName: "deposit" */ 'routes/Deposit'));
const CountryOfResidence = loadable(() => import(/* webpackChunkName: "country-of-residence" */ 'routes/CountryOfResidence'));
const Verification = loadable(() => import(/* webpackChunkName: "verification" */ 'routes/Verification'));
const BankAccountAdd = loadable(() => import(/* webpackChunkName: "bank-account-add" */ 'routes/BankAccountAdd'));

class Router extends Component {
  render() {
    return (
      <AppRoute>
        <Dashboard>
          <Switch>
            <Route exact={ true } path={ ROUTES.DASHBOARD } />
            <Route path={ [ROUTES.BUY, ROUTES.SELL] } />
            <Route
              exact={ true }
              path={ WITHDRAW_ROUTES }
              component={ Withdraw }
            />
            <Route
              exact={ true }
              path={ ROUTES.UPDATE_COUNTRY }
              component={ CountryOfResidence }
            />
            <Route
              exact={ true }
              path={ DEPOSIT_ROUTES }
              component={ Deposit }
            />
            <Route
              exact={ true }
              path={ ROUTES.VERIFICATION }
              component={ Verification }
            />
            <Route
              exact={ true }
              path={ ROUTES.BANK_ADD }
              component={ BankAccountAdd }
            />
          </Switch>
        </Dashboard>
      </AppRoute>
    );
  }
}

export default Router;
