import { localize } from 'helpers/router';

export const LOGIN = localize('/log-in');
export const SIGNUP = localize('/sign-up');
export const SIGNUP_SUCCESS = `${ SIGNUP }/success`;
export const EMAIL_VERIFIED = `${ SIGNUP }/verified`;
export const AUTHORIZE = localize('/authorize');
export const PASSWORD_RESET = localize('/password/new');
export const PASSWORD_CHANGE = localize('/password/edit');
export const CONFIRMATION_EXPIRED = localize('/confirmation/expired');
export const CONFIRMATION_VERIFIED = localize('/confirmation/verified');
