function prefix() {
  const element = document.createElement('fakeelement');
  const prefixMap = {
    animation: 'a',
    WebkitAnimation: 'webkitA',
  };

  try {
    const key = Object.keys(prefixMap).find((k) => element.style[k as any] !== undefined) || 'animation';
    return (prefixMap as any)[key];
  } catch (e) {
    return prefixMap.animation;
  }
}

export const TRANSITION_START = 'transitionstart';
export const TRANSITION_END = 'transitionend';
export const ANIMATION_END = `${ prefix() }nimationend`;
export const ANIMATION_ITERATION = `${ prefix() }nimationIteration`;
