import React from 'react';
import classnames from 'classnames';
import { Color, IconName } from 'types';
import Inlined from './Inlined';
import styles from './Icon.module.scss';

type Props = {
  className?: string,
  color?: Color | 'gray' | 'gray-shy' | 'red' | 'dark-light' | 'white',
  iconColor?: Color | 'white' | 'gray' | 'gray-light' | 'dark-light' | 'none',
  size?: 'xx-small' | 'x-small' | 'small' | 'normal' | 'medium' | 'large' | 'x-large',
  mark?: 'success' | 'add',
  name: IconName,
  onClick?: () => void,
};

const MARK_ICONS: { [mark: string]: IconName } = {
  success: 'check-white',
  add: 'plus-fat',
};

const CircledIcon: React.FC<Props> =
React.memo(({
  name, color = 'primary', iconColor = 'white', className = '', size = 'medium', onClick, mark,
}: FunctionProps<Props>) => (
  <div
    className={ classnames(styles.circle, styles[`circle--${ size }`], styles[`circle--${ color }`], className) }
    onClick={ onClick && (() => onClick()) }
  >
    <Inlined
      name={ name }
      color={ iconColor }
      size={ size }
    />
    { mark &&
      <span
        className={ classnames(styles.circleMark, styles[`circleMark--${ mark }`]) }
      >
        <Inlined name={ MARK_ICONS[mark] } />
      </span>
    }
  </div>
));

export default CircledIcon;
