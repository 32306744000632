import { Store } from 'store';
import { Action } from 'redux';
import { StoreState } from 'types';
import { ThunkAction } from 'redux-thunk';

class StoreService {
  store: Store | null = null;

  setStore(store: Store) {
    this.store = store;

    if (__DEV__) {
      // eslint-disable-next-line no-underscore-dangle
      window.__STORE = store;
    }
  }

  getState() {
    return this.store!.getState();
  }
  select<T>(selector: (state: StoreState) => T): T {
    return selector(this.getState());
  }
  dispatch(action: Action | ThunkAction<void, StoreState, null, any>) {
    if (!this.store) return null;

    // eslint-disable-next-line
    // @ts-ignore - because there are types errors when using ThunkAction
    return this.store.dispatch(action);
  }

  subscribe(callback: (state: StoreState) => void): (() => void) {
    if (!this.store) throw new Error('Store not yet initialized!');

    return this.store.subscribe(() => callback(this.getState()));
  }
  // helper method returning Promise that fullfills when state condition is met
  when(condition: (state: StoreState) => boolean): Promise<void> {
    if (condition(this.getState())) return Promise.resolve();

    return new Promise((resolve) => {
      const unsubscribe = this.subscribe((state) => {
        const result = condition(state);
        if (result) {
          resolve();
          unsubscribe();
        }
      });
    });
  }
}

export default StoreService;
