import { addClassToBody } from './ui';

export const ASSETS_URL = `${ process.env.PUBLIC_URL }/assets`;

export const getAssetUrl = (path: string) => {
  const startsWithSlash = path.length && path.startsWith('/');
  return `${ ASSETS_URL }${ startsWithSlash ? '' : '/' }${ path }`;
};


let webpIsSupported = false;
export const isWebpSupported = () => webpIsSupported;

const webpDetectSupport = () => {
  const webp = new Image();
  webp.onerror = () => {
    webpIsSupported = false;
  };
  webp.onload = () => {
    webpIsSupported = true;
    addClassToBody('js-webp');
  };
  webp.src = 'data:image/webp;base64,UklGRjIAAABXRUJQVlA4ICYAAACyAgCdASoBAAEALmk0mk0iIiIiIgBoSygABc6zbAAA/v56QAAAAA==';
};
// detect WebP support in non-blocking way
// if image checks `isWebpSupported` before feature detection finishes then defaults to not supported
webpDetectSupport();

export const tryWebp = (data: string | string[]) => Array.isArray(data)
  ? tryWebpLocal(data)
  : tryWebpPublic(data);

function tryWebpPublic(filename: string) {
  const [name, defaultExtension] = filename.split('.');
  return `${ name }.${ isWebpSupported() ? 'webp' : defaultExtension }`;
}
function tryWebpLocal(paths: string[]) {
  const webpPath = paths.find((p) => p.includes('.webp'));
  if (isWebpSupported() && webpPath) return webpPath;

  const standardPath = paths.find((p) => !p.includes('.webp'))!;
  return standardPath;
}
