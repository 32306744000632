import React from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { StoreState, MapActionsObject } from 'types';
import { MODALS_ROOT_ID, APP_ROOT_ID } from 'constants/ui';
import { hideOverflow, showOverflow } from 'helpers/ui';
import { hasModalsActive, isOverlayActive } from 'store/selectors';
import Services from 'services';

import styles from './ModalsRoot.module.scss';

const BLUR_CLASS = 'js-blur';

type Props = {
};

const select = (state: StoreState) => ({
  hasActive: hasModalsActive(state),
  isOverlayVisible: isOverlayActive(state),
});

const mapActions = {};

type MappedProps = ReturnType<typeof select>;
type MappedActions = MapActionsObject<typeof mapActions>;

class ModalsRoot extends React.Component<MappedProps & MappedActions & Required<Props>> {
  componentDidMount() {
    Services.get('events').on('KEY_ESC_PRESSED', this.onOverlayClick);
  }
  componentDidUpdate({ hasActive }: MappedProps) {
    if (this.props.hasActive === hasActive) return;

    if (this.props.hasActive) this.onOpened();
    else this.onClosed();
  }
  componentWillUnmount() {
    Services.get('events').off('KEY_ESC_PRESSED', this.onOverlayClick);
  }

  private onOpened() {
    hideOverflow();

    if (!this.appRoot) return;
    this.appRoot.classList.add(BLUR_CLASS);
  }
  private onClosed() {
    showOverflow();

    if (!this.appRoot) return;
    this.appRoot.classList.remove(BLUR_CLASS);
  }
  private get appRoot() {
    return document.getElementById(APP_ROOT_ID);
  }

  private onOverlayClick = () => Services.get('events').emit('MODAL_OVERLAY_CLICK');

  render() {
    const { hasActive, isOverlayVisible } = this.props;
    const isActive = hasActive || isOverlayVisible;

    return (
      <div
        className={ classnames(styles.overlay, { [styles.active]: isActive }) }
        onClick={ this.onOverlayClick }
      >
        <div id={ MODALS_ROOT_ID } />
      </div>
    );
  }
}

export default connect<MappedProps, typeof mapActions, Props, StoreState>(
  select,
  mapActions,
)(ModalsRoot);
