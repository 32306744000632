import React from 'react';
import classnames from 'classnames';
import './LocaleFlag.module.scss';

type Props = {
  name: string,
  className?: string,
};

const LocaleFlagIcon: React.FC<Props> =
React.memo(({ name, className }: FunctionProps<Props>) => {
  name = name.toLowerCase();
  return <span className={ classnames('locale-flag', `locale-flag--${ name }`, className) } />;
});

export default LocaleFlagIcon;
