import React from 'react';
import { FiatCurrency } from 'types';
import { getAssetUrl } from 'helpers/assets';
import CommonIcon, { CommonIconProps } from './Common';

type Props = {
  code: FiatCurrency['code'],
} & CommonIconProps;

const FiatCurrencyIcon: React.FC<Props> = ({ code, ...props }: FunctionProps<Props>) => (
  <CommonIcon
    src={ getAssetUrl(`/fiat_currency/${ code }.png`) }
    alt={ `${ code.toUpperCase() } currency icon` }
    async={ true }
    style={ { height: 'auto' } }
    { ...props }
  />
);

export default FiatCurrencyIcon;
