import React from 'react';
import { Notification as NotificationType, StoreAction } from 'types';
import Services from 'services';
import Notification from 'components/Notification';

import styles from './Notifications.module.scss';

type Props = {
  notifications: NotificationType[],
  close: StoreAction<'notificationHide'>,
};

class Notifications extends React.Component<Props> {
  componentDidMount() {
    Services.get('notifications').subscribe();
  }
  componentWillUnmount() {
    Services.get('notifications').unsubscribe();
  }

  render() {
    const { notifications, close } = this.props;
    return (
      <div className={ styles.container }>
        { notifications.map((notification) => (
          <Notification
            key={ notification.id }
            { ...notification }
            close={ () => close(notification) }
          />
        )) }
      </div>
    );
  }
}

export default Notifications;
