import React from 'react';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { StoreState } from 'store';
import { getCurrentLocale, localeUpdatedTimestamp } from 'store/selectors';
import { getHtml, get, has } from 'helpers/translate';

type Props = {
  path: string | string[],
  variables?: {},
  isHtml?: boolean,
};
const provider = connect<{ locale: string, localeLoaded: number }, void, Props, StoreState>(
  (state) => ({
    locale: getCurrentLocale(state),
    localeLoaded: localeUpdatedTimestamp(state),
  }));

// TODO: typing
export const translationRefreshHOC = (component: React.ComponentType) => connect(
  (state: StoreState) => ({ localeLoaded: localeUpdatedTimestamp(state) }),
)(component);

export const translateReact = ({ path, variables, isHtml = false }: Props) => {
  if (Array.isArray(path)) {
    if (isHtml) {
      throw new Error('[TRANSLATIONS] multiple paths cannot be used html containing messages');
    }

    return path.map((p) => get(p, variables)).join(' ');
  }

  if (isHtml) {
    return getHtml(path, variables);
  }

  return get(path, variables);
};

const translateReactMemoized = React.memo(translateReact as any, isEqual);
const TranslateComponent = provider(translateReactMemoized);
export const t = (path: string | string[], variables?: {}, isHtml = false) =>
  React.createElement(TranslateComponent, { path, variables, isHtml });

export const getTranslation = get;
export const T = TranslateComponent;


export const tFallback = (key: string, fallback: string, variables = {}, isHtml = false) => {
  const path = has(key) ? key : fallback;
  return t(path, variables, isHtml);
};

export const translateScoped = (base: string) => ({
  t: (...args: Args<typeof t>) => {
    const [key, ...rest] = args;
    const path = [base].concat(key).join('.');
    return t(path, ...rest);
  },
  getTranslation: (...args: Args<typeof getTranslation>) => {
    const [key, ...rest] = args;
    const path = [base].concat(key).join('.');
    return getTranslation(path, ...rest);
  },
  tFallback: (...args: Args<typeof tFallback>) => {
    const [key, fallback, ...rest] = args;
    return tFallback(
      [base].concat(key).join('.'),
      [base].concat(fallback).join('.'),
      ...rest,
    );
  },
});
