import React from 'react';
import { CoinCode } from 'types';
import { getAssetUrl } from 'helpers/assets';
import CommonIcon, { CommonIconProps } from './Common';

const getPath = (code: CoinCode) => getAssetUrl(`/coin/${ code.toLowerCase() }.svg`);

type Props = {
  code: CoinCode,
} & CommonIconProps;

const CoinIcon: React.FC<Props> =
React.memo(({ size = 'medium', code, ...props }: FunctionProps<Props>) => (
  <CommonIcon
    src={ getPath(code) }
    alt={ code }
    size={ size }
    { ...props }
  />
));

export default CoinIcon;
