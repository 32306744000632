import { objectFromCamelCase, objectToCamelCase } from 'utils';

export const deserialize = (data: {} | null, { format = true } = {}) => {
  if (!data) {
    return {};
  }

  if (!format) {
    return data;
  }

  return objectToCamelCase(data);
};

export const serialize = (data = {}) => {
  if (data instanceof FormData) return data;

  const result = objectFromCamelCase(data);
  return JSON.stringify(result);
};
