import { User } from 'types';
import { createReducer } from 'utils';
import { ActionsPayload as PaymentsActions } from 'store/payments';
import { ActionsPayload } from './types';
import { CARDS_ARE_ENABLED } from 'config';

export type State = Readonly<{
  isChecked: boolean,
  user: User | null,
  chatUsername: string,
  admin?: boolean | null,

  hasTrades: boolean,
  hasPaymentMethod: boolean,
  hasVerifiedEmail: boolean,
  hasCardsEnabled: boolean,
  fee: number,
}>;

export const initialState: State = {
  isChecked: false,
  user: null,
  chatUsername: '',
  admin: false,

  hasTrades: false,
  hasPaymentMethod: false,
  hasVerifiedEmail: false,
  hasCardsEnabled: CARDS_ARE_ENABLED,
  fee: 0.01,
};

type Actions = ActionsPayload & Pick<PaymentsActions, 'payments/BANK_ACCOUNT_ADD' | 'payments/CARD_ADD'>;
const userReducer = createReducer<State, Actions>(
  initialState,
  {
    'user/CHECKED': (state, { payload }) => ({
      ...state,
      isChecked: payload,
    }),
    'user/LOGGED_OUT': () => ({
      ...initialState,
    }),
    'user/UPDATED': (state, { payload }) => ({
      ...state,
      user: {
        ...state.user!,
        ...payload,
      },
    }),
    'user/INFO_SET': (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    'user/FEE_SET': (state, { payload }) => ({
      ...state,
      fee: payload,
    }),

    'payments/BANK_ACCOUNT_ADD': (state) => ({
      ...state,
      hasPaymentMethod: true,
    }),
    'payments/CARD_ADD': (state) => ({
      ...state,
      hasPaymentMethod: true,
    }),
  },
);

export default userReducer;
