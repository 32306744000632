import { WithdrawalRequestParams, Withdrawal, StandardResponse, CoinCode, Coin, Notification } from 'types';
import { withdrawalUpdated } from 'store/actions';
import { toNumber } from 'helpers/number';
import { history } from 'helpers/router';
import { getTranslation } from 'components/Translate';
import { SETTINGS_HISTORY } from 'constants/routes';
import Services from 'services';

export default class WithdrawsService {
  async fetchAll(): Promise<Withdrawal[]> {
    type Response = StandardResponse<{ data: Withdrawal[] }>;
    const result = await Services.get('api').get<Response>('withdraw/list');
    if (!result.success) {
      throw new Error(`Error while fetching withdrawals: ${ result.message }`);
    }

    return result.data;
  }
  async fetchSingle(id: string): Promise<Withdrawal> {
    type Response = StandardResponse<{ data: Withdrawal }>;
    const result = await Services.get('api').get<Response>(`v2/withdraw/${ id }`);
    if (!result.success) {
      throw new Error(result.message);
    }

    return result.data;
  }

  async create(data: WithdrawalRequestParams) {
    type Response = StandardResponse<{ currentStep: '2factor' | 'email' }>;
    const result = await Services.get('api').post<Response>('withdraw', data);
    if (!result.success) {
      throw new Error(result.message);
    }

    return result;
  }

  async cancel(id: Withdrawal['id']) {
    const result = await Services.get('api').post<StandardResponse>(`withdraw/${ id }/cancel`, {});
    if (!result.success) {
      throw new Error(result.message);
    }
  }

  async getFee(coin: Coin, amount: number, address = '') {
    if (coin.isToken && coin.tokenPlatformCode === CoinCode.ETH) {
      type Response = StandardResponse<{ fee: string, estimateEurPrice: string }>;
      const result = await Services.get('api').post<Response>('withdraw/fee', {
        currencyCode: coin.code,
        amount,
        address,
      });

      if (!result.success) {
        return {
          fee: 0,
          eurEstimate: 0,
        };
      }

      return {
        fee: toNumber(result.fee),
        eurEstimate: toNumber(result.estimateEurPrice),
      };
    }

    const fee = toNumber(coin.withdrawFee);
    return {
      fee,
      eurEstimate: fee * parseFloat(coin.euroPrice),
    };
  }

  onUpdate(withdraw: Withdrawal) {
    Services.get('store').dispatch(withdrawalUpdated(withdraw));

    if (withdraw.status === 'processing' || withdraw.status === 'completed') {
      this.showNotification(withdraw);
    }
  }
  showNotification(withdraw: Withdrawal) {
    const isCompleted = withdraw.status === 'completed';
    const amount = [withdraw.amount, withdraw.currencyCode.toUpperCase()].join(' ');
    const data: Notification = {
      id: withdraw.id,
      type: 'local',
      icon: {
        color: isCompleted ? 'success' : 'primary',
        name: isCompleted ? 'check-white' : 'loader',
      },
      coinCode: withdraw.currencyCode,
      title: getTranslation(`notification.withdraw.${ withdraw.status }.title`),
      description: getTranslation(`notification.withdraw.${ withdraw.status }.description`, { amount }),
      onClick: () => history.push(SETTINGS_HISTORY),
    };

    Services.get('notifications').show(data);
  }
}
