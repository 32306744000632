import Services from 'services';
import { StandardResponse } from 'types';

type EmailPasswordData = {
  email: string,
  password: string,
  token?: string | null,
};

type CountryData = {
  country: string,
  state: string | null,
};

class AuthService {
  login(data: EmailPasswordData & { otpAttempt?: string }) {
    const { otpAttempt } = data;
    if (otpAttempt) return this.loginWith2FA({ ...data, otpAttempt });
    else return this.loginSimple(data);
  }
  private async loginSimple({ token, ...data }: EmailPasswordData) {
    type Response = StandardResponse<{ otpEnabled: boolean, deviceToken?: string }>;
    const result = await Services.get('api').post<Response>('user/check_login', {
      ...data,
      'g-recaptcha-response': token,
    });
    if (!result.success) throw new Error(result.message);

    return result;
  }
  private async loginWith2FA(user: EmailPasswordData & { otpAttempt: string }) {
    type Response = StandardResponse<{ deviceToken?: string }>;
    const result = await Services.get('api').post<Response>('login', {
      user,
      'g-recaptcha-response': user.token,
    });
    if (result && !result.success) throw new Error(result.message);

    return { ...result, otpEnabled: false };
  }

  logout() {
    return Services.get('api').del('logout');
  }

  async signUp({ token, trackingId, ...user }: EmailPasswordData & CountryData & { trackingId?: string }) {
    const result = await Services.get('api').post<StandardResponse>('', {
      user,
      'g-recaptcha-response': token,
      trackingId,
    });
    if (!result.success) throw new Error(result.message);
  }

  async resetPassword(email: string, token?: string | null) {
    const result = await Services.get('api').post<StandardResponse>('password', {
      user: { email },
      'g-recaptcha-response': token,
    });
    if (!result.success) throw new Error(result.message);
  }
  // when reseting password
  async changePassword(data: { password: string, passwordConfirmation: string, resetPasswordToken: string }) {
    const result = await Services.get('api').put<StandardResponse>('password', { user: data });
    if (!result.success) throw new Error(result.message);
  }
  // when updating in settings
  async updatePassword(data: {
    currentPassword: string,
    password: string,
    passwordConfirmation: string,
    otpAttempt?: string,
  }) {
    const result = await Services.get('api').patch<StandardResponse<{ currentStep?: 'check_password' }>>(
      'settings/security/password_update',
      { user: data },
    );
    if (!result.success) throw new Error(result.message);
    return result;
  }
}

export default AuthService;
