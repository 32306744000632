export const prepareForTextComparison = (text: string) =>
  text.toLowerCase().replace(/\W/g, '').trim();

export const selectText = (element: HTMLElement) => {
  const range = document.createRange();
  range.selectNode(element);
  const selection = window.getSelection();
  if (!window || !selection) return;

  selection.removeAllRanges();
  selection.addRange(range);
};

export const removeWhitespace = (text: string) => text.replace(/\s/g, '');

export const capitalize = (text: string) => text.charAt(0).toUpperCase() + text.slice(1);
export const pad = (value: number | string) => `0${ value }`.slice(-2);
export const trunc = (text: string, length = 25) => {
  if (text.length > length) {
    return text.substring(0, length - 1) + '...';
  }

  return text;
};
