import React from 'react';
import classnames from 'classnames';
import './FiatFlag.module.scss';

type Props = {
  name: string,
  className?: string,
};

const FiatFlagIcon: React.FC<Props> =
React.memo(({ name, className }: FunctionProps<Props>) => {
  name = name.toLowerCase();
  return <span className={ classnames('fiat-flag', `fiat-flag--${ name }`, className) } />;
});

export default FiatFlagIcon;
