import { objectFromCamelCase } from './camel-case';

export const fromQueryString = (rawQuery: string): { [key: string]: string | boolean } => {
  let query = rawQuery;
  if (query.startsWith('?')) {
    query = query.substr(1);
  }

  const result = {};
  query.split('&').forEach((str) => {
    const [key, value] = str.split('=');
    (result as any)[key.toLowerCase()] = decodeURIComponent(value) || true;
  });

  return result;
};

export const toQueryString = (raw = {}): string => {
  if (!Object.keys(raw).length) return '';

  const data = objectFromCamelCase(raw);
  const str = Object.keys(data)
    .filter((key) => typeof (data as any)[key] !== 'undefined')
    .map((key) => `${ key }=${ encodeURIComponent((data as any)[key]) }`)
    .join('&');

  return `?${ str }`;
};
