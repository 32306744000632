import { AsyncAction, BankAccount, Card, CardCreateParams, CardChargeParams } from 'types';
import { createTyped } from 'utils';
import Services from 'services';
import { getBSPaymentMethod, buysellInitDefault } from 'store/buysell';
import { ActionsPayload } from './types';
import { getCards } from './selectors';

const createTypedAction = createTyped<ActionsPayload>();

export const bankAccountsSet = createTypedAction('payments/BANK_ACCOUNTS_SET');
export const bankAccountsFetch = (): AsyncAction<Promise<BankAccount[]>> =>
  async (dispatch) => {
    const data = await Services.get('payments').bankAccount.fetchAll();
    dispatch(bankAccountsSet(data));
    return data;
  };

export const bankAccountAdd = createTypedAction('payments/BANK_ACCOUNT_ADD');
export const bankAccountRemoved = createTypedAction('payments/BANK_ACCOUNT_REMOVE');
export const bankAccountRemove = (id: BankAccount['id']): AsyncAction<Promise<void>> =>
  async (dispatch) => {
    await Services.get('payments').bankAccount.remove(id);
    dispatch(bankAccountRemoved(id));
  };

export const cardsSet = createTypedAction('payments/CARDS_SET');
export const cardsFetch = (): AsyncAction<Promise<Card[]>> =>
  async (dispatch) => {
    const data = await Services.get('payments').card.fetchAll();
    dispatch(cardsSet(data));
    return data;
  };
export const cardsGet = (): AsyncAction<void> =>
  (dispatch, getState) => {
    if (getCards(getState())) return;
    dispatch(cardsFetch());
  };

export const cardAdd = createTypedAction('payments/CARD_ADD');
export const cardCreate = (data: CardCreateParams): AsyncAction<Promise<Card | null>> =>
  async (dispatch) => {
    const { card } = await Services.get('payments').card.create(data);
    if (card) {
      dispatch(cardAdd(card));
    }
    return card;
  };

export const cardRemoved = createTypedAction('payments/CARD_REMOVE');
export const cardRemove = (id: Card['id']): AsyncAction<Promise<void>> =>
  async (dispatch, getState) => {
    await Services.get('payments').card.remove(id);
    dispatch(cardRemoved(id));

    // if removed card was currently selected payment method
    // then select default
    const paymentMethod = getBSPaymentMethod(getState());
    if (paymentMethod.type === 'card' && id === paymentMethod.id) {
      dispatch(buysellInitDefault());
    }
  };

export const cardCharge = (data: CardChargeParams & { id: Card['id'] }): AsyncAction<Promise<void>> =>
  async () => {
    const { redirectUrl } = await Services.get('payments').card.charge(data);
    if (redirectUrl !== null) return;
  };

export const cardCreateAndCharge = (data: CardCreateParams & CardChargeParams): AsyncAction<Promise<Card | null>> =>
  async (dispatch) => {
    const { card } = await Services.get('payments').card.createAndCharge(data);
    if (card) {
      dispatch(cardAdd(card));
      return card;
    }

    return null;
  };
