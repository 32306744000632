import { createSelector } from 'reselect';
import memoize from 'lodash/memoize';
import { CoinCode, Coin } from 'types';
import { sortAlphabetically } from 'helpers/sort';
import { StoreState } from '../index';

const getState = (state: StoreState) => state.coinsStore || {};

export const getCoinsList = createSelector(
  [getState],
  (state) => state.list,
);
export const getDelistedCoinsList = createSelector(
  [getState],
  (state) => state.delisted,
);
export const areCoinsFetched = createSelector(
  [getCoinsList],
  (list) => !!list.length,
);
export const getCoinsListSorted = createSelector(
  [getState],
  (state) => state.list.sort((a, b) => sortAlphabetically(a.name, b.name)),
);
export const getCoinByCode = createSelector(
  [getCoinsList],
  (coins) => memoize((coinCode: CoinCode) =>
    coins.find(({ code }) => code === coinCode) || null),
);

export const getAllCoinsList = createSelector(
  [getState],
  (state) => [...state.list, ...state.delisted],
);
export const getAllCoinByCode = createSelector(
  [getAllCoinsList],
  (coins) => memoize((coinCode: CoinCode) =>
    coins.find(({ code }) => code === coinCode) || null),
);

export const getCoinByName = createSelector(
  [getCoinsList],
  (coins) => (coinName: Coin['name']) =>
    coins.find(({ name }) => name.toLowerCase() === coinName) || null,
);


export const getCoinByCodeWithDelisted = createSelector(
  [getCoinByCode, getDelistedCoinsList],
  (getByCode, delisted) => (coinCode: CoinCode) => {
    const coin = getByCode(coinCode);
    if (coin) return coin;

    return delisted.find(({ code }) => code === coinCode) || null;
  },
);
