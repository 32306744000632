import { ANIMATION_END } from 'constants/css';

export const onAnimationEnd = (element: HTMLElement, event = ANIMATION_END) => new Promise((resolve) => {
  const handler = () => {
    element.removeEventListener(event, handler);
    resolve();
  };

  element.addEventListener(event, handler);
});
