import { createReducer } from 'utils';
import { FiatCurrency, FiatCurrencyRate, CoinCode } from 'types';
import { ActionsPayload } from './types';
import { ActionsPayload as UserActions } from 'store/user';

export type State = Readonly<{
  current: FiatCurrency['code'],
  list: FiatCurrency[],
  rate: FiatCurrencyRate,
}>;

export const initialState: State = {
  current: CoinCode.EUR,
  list: [],
  rate: '1',
};

const fiatReducer = createReducer<State, ActionsPayload & Pick<UserActions, 'user/LOGGED_OUT'>>(
  initialState,
  {
    'fiat/SET_LIST': (state, { payload }) => ({
      ...state,
      list: payload,
    }),
    'fiat/SET_CURRENT': (state, { payload }) => ({
      ...state,
      current: payload.code,
      rate: payload.rate,
    }),
    'user/LOGGED_OUT': (state) => ({
      ...state,
      rate: '1',
    }),
  },
);

export default fiatReducer;
