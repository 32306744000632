import { floorWithPrecision } from './number';

// cause when buying 100 eur => 100 = x - (x * FEE_PERCENT + FEE_FIXED)
export const calcAmountWithFee = (amount: number, percentFee: number, fixedFee: number, minFee = 0) =>
  Math.max(
    amount + minFee,
    floorWithPrecision((amount / (1 - percentFee / 100)) + fixedFee),
  );

export const calcFee = (amount: number, percentFee: number, fixedFee: number, minFee = 0) =>
  calcAmountWithFee(amount, percentFee, fixedFee, minFee) - amount;
