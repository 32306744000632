import { CustomEvents, Wallet } from 'types';
import Services from 'services';
import { BUY, SELL } from 'constants/routes';
import { history, isRoute } from 'helpers/router';
import { isBuy } from 'helpers/order';
import { load, save } from 'helpers/storage';

import { getBSPaymentMethod, getBSRightCoinCode, getBSState } from 'store/selectors';
import { COIN_DETAILS_ROUTES } from 'routes/CoinDetails/routes-group';


const LAST_WALLET_KEY = 'bs-last-wallet';

class BuySellService {
  private lastWalletData = load<Wallet>(LAST_WALLET_KEY);

  init() {
    Services.get('events').on('BUY_SELL_CHANGE', this.onChange);
    Services.get('events').on('BUY_SELL_ORDER_SUCCESS', this.saveWalletData);
  }

  private onChange = (data: CustomEvents['BUY_SELL_CHANGE']) => {
    const isCoinDetails = COIN_DETAILS_ROUTES.some((route) => isRoute(history.location.pathname, route));
    if (isCoinDetails) return;

    this.navigate(data);
    this.saveWalletData();
  };
  saveWalletData = () => {
    const method = Services.get('store').select(getBSPaymentMethod);
    const code = Services.get('store').select(getBSRightCoinCode);

    this.lastWalletData = {
      ...method,
      code,
    };

    save(LAST_WALLET_KEY, this.lastWalletData);
  };
  get lastWallet() {
    return this.lastWalletData;
  }

  navigateToCurrent() {
    const { type, left, right } = Services.get('store').select(getBSState);
    if (!right) return;

    this.navigate({ type, left, right });
  }
  navigate({ type, left, right }: CustomEvents['BUY_SELL_CHANGE']) {
    history.push(`${ isBuy(type) ? BUY : SELL }/${ left }-${ right }`);
  }
}

export default BuySellService;
