import React from 'react';
import classnames from 'classnames';
import { PublicImage } from 'components/Image';
import styles from './Loader.module.scss';

type Props = {
  className?: string,
};

const Loader: React.FC<Props> = ({ className = '' }) => (
  <div className={ classnames(styles.loader, className) }>
    <PublicImage
      src="loader.png"
      className={ styles.icon }
      hasWebp={ false }
      width={ 48 }
      height={ 48 }
    />
  </div>
);

export default Loader;
