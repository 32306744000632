import React from 'react';
import classnames from 'classnames';
import { STORE_URL_IOS, STORE_URL_ANDROID } from 'config';
import styles from './MobileApp.module.scss';
import { getAssetUrl } from 'helpers/assets';

type Props = {
  className?: string,
  iosClass?: string,
  androidClass?: string,
};

const MobileAppsButtons: React.FC<Props> = React.memo(({
  className,
  iosClass = '',
  androidClass = '',
}: FunctionProps<Props>) => (
  <div
    className={ classnames(className, 'd-flex align-items-center justify-content-center justify-content-lg-start') }
  >
    <a
      href={ STORE_URL_IOS }
      title="Coinfalcon App Store"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        className={ classnames(styles.appstore, iosClass) }
        alt="Download on the App Store"
        src={ getAssetUrl('/other/app-store.svg') }
        height="54"
      />
    </a>
    <a
      href={ STORE_URL_ANDROID }
      title="Coinfalcon Play Store"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        className={ classnames(styles.gplay, androidClass) }
        alt="Get it on Google Play"
        src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png"
        height="80"
      />
    </a>
  </div>
));

export default MobileAppsButtons;
