import { VERIFICATION } from 'constants/routes';
import { VERIFICATION_CONTAINER_ID } from 'constants/verification';
import { history } from 'helpers/router';
import loadScript from 'load-script';
import Services from 'services';
import { isUserVerified, userUpdated } from 'store/user';
import { VerificationStatus, VerificationStatusChangeMessage } from 'types';
import { logger } from 'utils';

const URL = 'https://static.sumsub.com/idensic/static/sns-websdk-builder.js';

class VerificationService {
  constructor() {
    if (__DEV__ || __STAGING__) {
      // eslint-disable-next-line
      window.__VERIFICATION = this;
    }
  }

  async run(accessToken: string) {
    await this.load();
    return this.start(accessToken);
  }
  async load() {
    return new Promise((resolve, reject) => loadScript(URL, (error: string | null) => {
      if (error) reject();
      else resolve(true);
    }));
  }
  private start(accessToken: string) {
    return new Promise((resolve, reject) => {
      if (!window.snsWebSdk) {
        reject();
        return;
      }

      // const SUMSUB_URL = __DEV__ || __STAGING__
      //   ? 'https://test-api.sumsub.com'
      //   : 'https://api.sumsub.com';
      // const snsWebSdkInstance = window.snsWebSdk.Builder(SUMSUB_URL, 'web')
      //   .withAccessToken(accessToken, () => accessToken)
      //   .withConf(this.getConfig(externalUserId, resolve, reject))
      //   .build();

      const snsWebSdkInstance = window.snsWebSdk.init(
        accessToken,
        // token update callback, must return Promise
        () => accessToken
      )
        .withConf({
          lang: 'en', //language of WebSDK texts and comments (ISO 639-1 format)
        })
        .on('onError', () => {
          reject();
        })
        .onMessage((type: string, payload: string) => {
          logger('verification', '[CALLBACK]', type, payload);

          if (type === 'idCheck.onApplicantSubmitted') resolve(true);
        })
        .build();

      snsWebSdkInstance.launch(`#${ VERIFICATION_CONTAINER_ID }`);
    });
  }
  // private getConfig(externalUserId: string, resolve: () => void, reject: () => void) {
  //   return {
  //     lang: 'en',
  //     email: externalUserId,
  //     onMessage: (type: string, payload: any) => {
  //       logger('verification', '[CALLBACK]', type, payload);

  //       if (type === 'idCheck.onApplicantSubmitted') resolve();
  //     },
  //     onApplicantSubmitted: () => resolve(),
  //     onApplicantResubmitted: () => resolve(),
  //     onError: () => reject(),
  //     uiConf: {
  //     },
  //   };
  // }
  clear() {
    // TODO: remove if not needed - check later
  }

  createApplicant() {
    type Response = { externalUserId: string, accessToken: string };
    return Services.get('api').post<Response>('verification/create_applicant');
  }

  async getStatus() {
    type Response = { status: VerificationStatus };
    const result = await Services.get('api').get<Response>('verification/get_verification_status');
    return result.status;
  }
  canSubmit(status: VerificationStatus) {
    const VERIFICATION_POSSIBLE_STATUSES: VerificationStatus[] = ['can_submit', 'retry'];
    return VERIFICATION_POSSIBLE_STATUSES.includes(status);
  }

  onStatusChange({ status, rejectReason }: VerificationStatusChangeMessage) {
    if (status === 'processing') return;

    if (status === 'retry' || status === 'final') {
      history.push(VERIFICATION, { status, rejectReason });
    }
    if (status === 'verified') this.onVerified();
  }
  private onVerified() {
    if (Services.get('store').select(isUserVerified)) return;

    Services.get('store').dispatch(userUpdated({ ifVerified: true }));
    history.push(VERIFICATION, { status: 'verified' });
  }
}

export default VerificationService;

