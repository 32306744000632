import React from 'react';
import classnames from 'classnames';
import { TextWeight, TextColor } from 'types';

type Props = {
  weight?: Exclude<TextWeight, 'bold'>,
  color?: TextColor,
  className?: string,
};
const Caption: React.FC<Props> =
({ weight = 'regular', color = 'black', className = '', children }: FunctionProps<Props>) => (
  <span
    className={ classnames('caption', weight, `text-color--${ color }`, className) }
  >
    { children }
  </span>
);

export default Caption;
