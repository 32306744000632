import { prepareForTextComparison } from 'helpers/text';

export const filter = <T>(items: T[], rawQuery: string, filterBy: (item: T) => string): T[] => {
  const query = prepareForTextComparison(rawQuery);
  if (!query.length) return items;

  return items.filter((item) => {
    const text = filterBy(item);
    const preparedText = prepareForTextComparison(text);
    return preparedText.includes(query);
  });
};
