import React from 'react';
import classnames from 'classnames';
import { NavLink, Link } from 'react-router-dom';
import * as ROUTES from 'constants/routes';
import { MOBILE_MENU_OPEN_CLASS } from 'constants/ui';
import { removeClassFromBody, addClassToBody } from 'helpers/ui';
import { delay } from 'utils';

import Button from 'components/Button';
import { t } from 'components/Translate';
import Icon from 'components/Icon';
import ClickOutside from 'components/ClickOutside';
import { MediaQueryRenderer } from 'components/MediaQuery';
import Logo from 'components/Logo';

import styles from '../Header.module.scss';
import Mobile from './Mobile';
import Products from './Products';
import Company from './Company';

type Props = {
};
type State = {
  isMobileActive: boolean,
  isProductsActive: boolean,
  isCompanyActive: boolean,
};
class HeaderAnonymous extends React.PureComponent<Props, State> {
  state: State = {
    isMobileActive: false,
    isProductsActive: false,
    isCompanyActive: false,
  };

  componentWillUnmount() {
    removeClassFromBody(MOBILE_MENU_OPEN_CLASS);
  }

  private toggleMobile = () => {
    (this.state.isMobileActive ? removeClassFromBody : addClassToBody)(MOBILE_MENU_OPEN_CLASS);
    this.setState((state) => ({ ...state, isMobileActive: !state.isMobileActive }));
  };
  private toggleProducts = () => {
    this.setState((state) => ({ ...state, isProductsActive: !state.isProductsActive }));
  };
  private toggleCompany = () => {
    this.setState((state) => ({ ...state, isCompanyActive: !state.isCompanyActive }));
  };
  private hideProducts = async () => {
    const isActive = this.state.isProductsActive;
    await delay(30);
    if (this.state.isProductsActive !== isActive) return;

    this.setState({ isProductsActive: false });
  };

  render() {
    const { isMobileActive, isProductsActive, isCompanyActive } = this.state;

    return (
      <>
        <div className={ classnames(styles.menu, { [styles.is__active]: !isProductsActive }) }>
          <Button theme="link" onClick={ this.toggleProducts } stopPropagation={ true }>
            { t('common.link.products') }
            <Icon.Inlined name={ isProductsActive ? 'chevron-dropup' : 'chevron-dropdown' } size="x-small" />
          </Button>
          <NavLink to={ ROUTES.PRICES } activeClassName="is-link-active">
            <Button theme="link">
              { t('common.link.prices') }
            </Button>
          </NavLink>
          <ClickOutside onClickOutside={ () => this.setState({ isCompanyActive: false }) }>
            <>
              <Button theme="link" onClick={ this.toggleCompany } stopPropagation={ true }>
                { t('common.link.company') }
                <Icon.Inlined name={ isCompanyActive ? 'chevron-dropup' : 'chevron-dropdown' } size="x-small" />
              </Button>
              <Company isActive={ isCompanyActive } onHide={ this.toggleCompany } />
            </>
          </ClickOutside>
        </div>
        <div className={ styles.auth }>
          <Link to={ ROUTES.LOGIN } className="d-none d-lg-inline-block">
            <Button theme="link" className="mr-2">
              { t('common.action.login') }
            </Button>
          </Link>
          <Link to={ ROUTES.SIGNUP }>
            <Button className={ styles.btn__signup }>
              { t('common.action.getStarted') }
            </Button>
          </Link>
          <span className="d-inline-block d-lg-none ml-3 cursor--pointer">
            <Icon.Normal name="menu" onClick={ this.toggleMobile } />
          </span>
        </div>
        <MediaQueryRenderer down="md">
          <Mobile
            logo={ <Logo className={ styles.logo } /> }
            isActive={ isMobileActive }
            toggle={ this.toggleMobile }
          />
        </MediaQueryRenderer>
        { isProductsActive &&
          <ClickOutside onClickOutside={ this.hideProducts }>
            <Products isActive={ isProductsActive } />
          </ClickOutside>
        }
      </>
    );
  }
}

export default HeaderAnonymous;
