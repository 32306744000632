/* eslint-disable id-blacklist */
import convertToNumber from 'lodash/toNumber';
import isNumber from 'lodash/isNumber';
import Decimal from 'decimal.js-light';
import { CoinCode } from 'types';
import { hasFiatPrecision } from './fiat';

export const toNumber = (value: any): number => convertToNumber(value);
export const isNaN = (value: string | number) => Number.isNaN(parseFloat(value as string));

export const fixed = (value: string | number, precision = 2) => {
  if (typeof value !== 'number' && typeof value !== 'string') return '';
  if (isNaN(value)) return '';

  return new Decimal(value).toFixed(precision);
};
export const fixedClean = (value: string | number, precision = 2) => {
  const result = fixed(value, precision);
  return result.replace(/0+$/, '').replace(/[.,]$/, '');
};
export const fixedFiat = (code: CoinCode, value: string | number, precision = 2) =>
  hasFiatPrecision(code) ? fixed(value, 2) : fixedClean(value, precision);
export const parse = (input: string | number) => {
  const value = parseFloat(input as string);
  if (Number.isNaN(value) || value < 0) {
    return 0;
  }

  return value;
};

export const floatMultiply = (first: string | number, second: string | number) => {
  if (isNaN(first) || isNaN(second)) return 0;
  if (!Number.isFinite(+first) || !Number.isFinite(+second)) return (+first) * (+second);

  return new Decimal(first).mul(second).toNumber();
};
export const floatDivide = (first: string | number, second: string | number) => {
  if (isNaN(first) || isNaN(second)) return 0;
  if (second === 0) return Number.POSITIVE_INFINITY;
  if (!Number.isFinite(+first) || !Number.isFinite(+second)) return (+first) / (+second);

  return new Decimal(first).div(second).toNumber();
};


export const floorWithPrecision = (value: number | string, precision = 2) => {
  if (isNaN(value)) return 0;

  return new Decimal(value).toDecimalPlaces(precision, Decimal.ROUND_DOWN).toNumber();
};
export const ceilWithPrecision = (value: number | string, precision = 2) => {
  if (isNaN(value)) return 0;

  return new Decimal(value).toDecimalPlaces(precision, Decimal.ROUND_UP).toNumber();
};
export const roundWithPrecision = (value: number | string, precision = 2) => {
  if (isNaN(value)) return 0;

  return new Decimal(value).toDecimalPlaces(precision).toNumber();
};

export const getPrecision = (value: number | string) => {
  if (isNaN(value)) return 0;
  return new Decimal(value).decimalPlaces();
};
export const getPrecisionUnit = (precision: number) => Number((10 ** -precision).toFixed(precision));

export const getNumberAsStringPrecision = (value: string) => {
  const [, currentPrecision = ''] = value.split('.');
  return currentPrecision.length;
};
export const hasCorrectPrecision = (value: string, precision: number) => {
  if (!value.length) return true;

  return getNumberAsStringPrecision(value) <= precision;
};

export const isCorrectPositiveNumber = (value: string | number) => !isNaN(value) && toNumber(value) >= 0;
export const isScientificNotation = (value: string) => value.includes('e');

export const decimalToFixed = (value: string | number) => {
  if (isNaN(value)) return '';
  return new Decimal(value).toFixed();
};
export const removeScientificNotation = decimalToFixed;

export { isNumber };
