import { AsyncAction, Announcement } from 'types';
import { createTyped } from 'utils';
import Services from 'services';
import { ActionsPayload } from './types';

const createTypedAction = createTyped<ActionsPayload>();

export const announcementSet = createTypedAction('announcements/SET');
export const announcementShow = createTypedAction('announcements/SHOW');

export const announcementHidden = createTypedAction('announcements/HIDE');
export const announcementHide = (id: Announcement['id']): AsyncAction<void> =>
  (dispatch) => {
    dispatch(announcementHidden(id));
    Services.get('announcements').read(id);
  };

export const announcementsFetch = (): AsyncAction<Promise<void>> =>
  async (dispatch) => {
    const list = await Services.get('announcements').fetch();
    dispatch(announcementSet(list));
  };
