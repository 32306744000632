import Services from 'services';
import { CoinChartDuration, CoinCode, FiatCurrency, CoinPriceInfo } from 'types';

const BASE_URL = __IS_MOCK__ ? 'https://beta.coinfalcon.com/' : '';

const DURATION_TEXT_MAP: Record<CoinChartDuration, string> = {
  '1d': '1D',
  '1w': '1W',
  '1M': '1M',
  '1y': '1Y',
};

type Params = {
  code: CoinCode,
  duration: CoinChartDuration,
  fiat?: FiatCurrency['code'],
};

type CoinPriceResponse = {
  currency: CoinCode,
  duration: CoinChartDuration,
  quote_currency: string,
  data: Record<string, number>,
  price_change: number,
  percent_change: number,
};

class CoinChartService {
  fetchAll(params: Omit<Params, 'code'>) {
    return this.runRequest({ code: 'all', ...params });
  }
  async fetch(params: Params) {
    const result = await this.runRequest(params);
    if (!result.length) throw new Error('No results!');

    return result[0];
  }
  async fetchChart(params: Params) {
    const result = await this.fetch(params);
    return result.chart;
  }

  private async runRequest({ code, duration, fiat = 'eur' }: Omit<Params, 'code'> & { code: CoinCode | 'all' }) {
    const url = `${ BASE_URL }api/v1/currencies/${ code }/historical_prices`;
    if (!this.durations.includes(duration)) throw new Error(`Duration '${ duration }' does not exist!'`);

    const result = await Services.get('api').get<CoinPriceResponse[]>(
      url,
      { duration, quoteCurrency: fiat },
      { formatResponse: false },
    );

    return result.map(this.parseResponse);
  }

  get durations() {
    return Object.keys(DURATION_TEXT_MAP) as CoinChartDuration[];
  }
  get durationsMap() {
    return DURATION_TEXT_MAP;
  }

  private parseResponse = (response: CoinPriceResponse): CoinPriceInfo => ({
    code: response.currency,
    duration: response.duration,
    chart: Object.entries(response.data).map(([key, value]) => [new Date(key).getTime(), value]),
    percentChange: response.percent_change,
    priceChange: response.price_change,
  });
}

export default CoinChartService;
