import { PaymentMethod, Coin, ReceiptValues } from 'types';
import Services from 'services';
import { last } from 'utils';

import { isCardPayment } from 'helpers/order';
import { roundWithPrecision } from 'helpers/number';

import BaseOrder from './base';
import { IOrderKind, QuoteParams, ApiQuote } from './types';

export type OrderParams = {
  paymentMethod: PaymentMethod,
  leftCoin: Coin,
  receiptValues: ReceiptValues,
  fiatValue?: number,
};

class CardOrder extends BaseOrder implements IOrderKind {
  async order(data: OrderParams) {
    if (!isCardPayment(data.paymentMethod) || !data.paymentMethod.id) {
      throw new Error('Cannot perform card order with payment method being \'coin\'');
    }

    this.log('perform', data);
    const result = await this.perform({
      id: data.paymentMethod.id,
      volume: data.receiptValues.amount,
      code: data.leftCoin.code,
      amount: data.fiatValue ? data.fiatValue : data.receiptValues.total,
    });

    this.log('result', result);
    return (result && result.length) ? last(result) : null;
  }
  async perform({ id, amount, volume, code }: { id: string, amount: number, volume: number, code: string }) {
    const result = await Services.get('payments').card.charge({
      id,
      purpose: 'order',
      specificPurpose: `:${ volume }${ code.toUpperCase() }`,
      total: roundWithPrecision(amount),
      pendingOrderUuid: this.pendingOrderId!,
    });

    return result.orders || null;
  }

  // same as for CrossMarket as api may return cross_market_order when geting quote for card
  protected parseQuote(raw: ApiQuote & { success?: boolean }) {
    const quote = super.parseQuote(raw);
    if (!quote.crossOrderParams || (quote.orderType as any) !== 'cross_market_order') return quote;

    quote.orderType = quote.crossOrderParams.hasOwnProperty('funds') ? 'buy' : 'sell';
    if (quote.orderType === 'buy') {
      quote.total = quote.volume;
    }
    return quote;
  }

  shouldHandle(params: OrderParams | QuoteParams) {
    return params.paymentMethod && isCardPayment(params.paymentMethod);
  }

  protected log(...messages: any[]) {
    super.log('[CARD]', ...messages);
  }
}

export default CardOrder;
