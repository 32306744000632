import { IS_SENTRY_ENABLED } from 'config';

if (IS_SENTRY_ENABLED) {
  const load = async () => {
    const Sentry = await import('@sentry/browser');
    Sentry.init({
      dsn: 'https://a99aebe23783405f92e9c1e9f132cb49@sentry.coinfalcon.com:8443/6',
      whitelistUrls: [
        /https?:\/\/coinfalcon\.com/,
      ],
      blacklistUrls: [
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
      ],
      ignoreErrors: [
        'Cannot read property \'style\' of null',
        'missing ) after argument list',
        'find variable: $crisp',
      ],
    });
    Sentry.setExtra('release_hash', __COMMIT_HASH__);
    window.Sentry = Sentry;
  };

  load();
}
