import get from 'lodash/get';
import validate from 'card-validator';
import { getTranslation } from 'components/Translate';
import { CoinCode } from 'types';
import Services from 'services';
import { hasCardsEnabled } from 'store/user';
import { calcAmountWithFee, calcFee } from './payments';
import { getConfig } from 'store/config';

export const showCardNumber = (digits: string) => `•••• ${ digits }`;

export const validateCardNumber = (ccn: string) => validate.number(ccn);
export const isValidCardNumber = (ccn: string) => validateCardNumber(ccn).isValid;
export const validateExpiration = (expiration: string) => validate.expirationDate(expiration);
export const isValidExpiration = (expiration: string) => validateExpiration(expiration).isValid;

export const getCardInfo = (ccn: string) => {
  const validation = validateCardNumber(ccn);
  return validation.card;
};
export const getCardType = (ccn: string) => get(getCardInfo(ccn), 'type', null);

export const getCVCName = (ccn: string): string => get(getCardInfo(ccn), 'code.name', 'CVC');
export const getCVCLength = (ccn: string): number => get(getCardInfo(ccn), 'code.size', 4);

export const validateCardData = (data: { ccn: string, expiration: string, cvc: string }) => {
  if (!isValidCardNumber(data.ccn)) {
    return getTranslation('card.error.ccn');
  }

  if (!isValidExpiration(data.expiration)) {
    return getTranslation('card.error.expiration');
  }

  if (data.cvc.length !== getCVCLength(data.ccn)) {
    return getTranslation('card.error.cvc');
  }

  return null;
};

export const calcCardAmountWithFee = (amount: number) => {
  const config = Services.get('store').select(getConfig)('card');
  return calcAmountWithFee(amount, config.feePercent, config.feeFixed);
};
export const calcCardFee = (amount: number) => {
  const config = Services.get('store').select(getConfig)('card');
  return calcFee(amount, config.feePercent, config.feeFixed);
};


export const parsePurpose = (purpose: string): { type: string, amount?: string, code?: CoinCode } => {
  if (!purpose.includes('order')) return { type: purpose };

  const regex = /^(order):(\d+(?:\.\d+)?)(\w+)$/i;
  if (!regex.test(purpose)) return { type: purpose };

  const [, type, amount, rawCode] = regex.exec(purpose)!;
  const code = rawCode.toLowerCase() as CoinCode;

  return {
    type,
    amount,
    code,
  };
};

export const areCardsEnabled = () => Services.get('store').select(hasCardsEnabled);
