import { ModalName, ModalShowParams } from 'types';
import { createTyped, createSimple } from 'utils';
import { ActionsPayload } from './types';

const createTypedAction = createTyped<ActionsPayload>();
const createAction = createSimple<ActionsPayload>();

export const modalShow = <T extends ModalName>(name: T, config?: ModalShowParams<T>['config']) =>
  createAction('ui/MODAL_SHOW', { name, config: { custom: {}, ...config } });
export const modalHide = createTypedAction('ui/MODAL_HIDE');

export const overlaySetActive = createTypedAction('ui/OVERLAY_ACTIVE');
