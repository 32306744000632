import React from 'react';
import { MediaQueryType } from 'types';
import MediaQuery from './MediaQuery';

type Props = {
  up?: MediaQueryType,
  down?: MediaQueryType,
};

const MediaQueryRenderer: React.FC<Props> = ({ up, down, children }: FunctionProps<Props>) => (
  <MediaQuery>
    { ({ isUp, isDown }) => {
      if (up && !isUp(up)) return null;
      if (down && !isDown(down)) return null;

      return children;
    } }
  </MediaQuery>
);

export default MediaQueryRenderer;
