// 'to' and 'from' based on: https://github.com/sindresorhus/decamelize and https://github.com/sindresorhus/camelcase

import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';

const SEPARATOR = '_';

export const toCamelCase = (str: string): string => {
  if (typeof str !== 'string') return str;

  return str
    .replace(/^[_.\- ]+/, '')
    .toLowerCase()
    .replace(/[_.\- ]+(\w|$)/g, (_m, p1) => p1.toUpperCase());
};

export const fromCamelCase = (str: string): string => {
  if (typeof str !== 'string') return str;

  return str
    .replace(/([a-z\d])([A-Z])/g, `$1${ SEPARATOR }$2`)
    .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, `$1${ SEPARATOR }$2`)
    .toLowerCase();
};

export const objectToCamelCase = (data: {} | any[]): any => {
  let result: Record<string, any> = {};
  if (isArray(data)) {
    result = [];
  }

  Object.entries(data).forEach(([key, v]) => {
    let value = v;
    if (isPlainObject(value) || isArray(value)) {
      value = objectToCamelCase(value);
    }

    result[toCamelCase(key)] = value;
  });

  return result;
};

export const objectFromCamelCase = (data: {} | any[]) => {
  let result: Record<string, any> = {};
  if (isArray(data)) {
    result = [];
  }

  Object.entries(data).forEach(([key, v]) => {
    let value = v;
    if (isPlainObject(value) || isArray(value)) {
      value = objectFromCamelCase(value);
    }

    result[fromCamelCase(key)] = value;
  });

  return result;
};
