import React from 'react';

type Props = {
  className?: string,
  to: string,
  onClick?: () => void,
  openInNewTab?: boolean,
};

const StandardLink: React.FC<Props> =
({ className = '', to, openInNewTab = false, onClick, children }: FunctionProps<Props>) => (
  <a
    className={ className }
    href={ to }
    onClick={ onClick && (() => onClick()) }
    target={ openInNewTab ? '_blank' : undefined }
    rel={ openInNewTab ? 'noopener' : undefined }
  >
    { children }
  </a>
);

export default StandardLink;



