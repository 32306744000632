import ordersStore, { initialState as ordersStoreInitial } from './orders/reducer';
import balancesStore, { initialState as balancesStoreInitial } from './balances/reducer';
import buysellStore, { initialState as buysellStoreInitial } from './buysell/reducer';
import notificationsStore, { initialState as notificationsStoreInitial } from './notifications/reducer';
import announcementsStore, { initialState as announcementsStoreInitial } from './announcements/reducer';
import transfersStore, { initialState as transfersStoreInitial } from './transfers/reducer';
import orderbookStore, { initialState as orderbookStoreInitial } from './orderbook/reducer';
import paymentsStore, { initialState as paymentsStoreInitial } from './payments/reducer';
import userStore, { initialState as userStoreInitial } from './user/reducer';
import marketsStore, { initialState as marketsStoreInitial } from './markets/reducer';
import fiatStore, { initialState as fiatStoreInitial } from './fiat/reducer';
import referralStore, { initialState as referralStoreInitial } from './referral/reducer';
import coinsStore, { initialState as coinsStoreInitial } from './coins/reducer';
import uiStore, { initialState as uiStoreInitial } from './ui/reducer';
import localeStore, { initialState as localeStoreInitial } from './locale/reducer';
import chatboxStore, { initialState as chatboxStoreInitial } from './chatbox/reducer';
import configStore, { initialState as configStoreInitial } from './config/reducer';

// TODO: make reducers dynamic - load only when needed

export const reducers = {
  localeStore,
  uiStore,
  coinsStore,
  fiatStore,
  referralStore,
  marketsStore,
  userStore,
  paymentsStore,
  orderbookStore,
  transfersStore,
  notificationsStore,
  announcementsStore,
  buysellStore,
  balancesStore,
  ordersStore,
  chatboxStore,
  configStore,
};

export const initialState = {
  localeStore: localeStoreInitial,
  uiStore: uiStoreInitial,
  coinsStore: coinsStoreInitial,
  fiatStore: fiatStoreInitial,
  referralStore: referralStoreInitial,
  marketsStore: marketsStoreInitial,
  userStore: userStoreInitial,
  paymentsStore: paymentsStoreInitial,
  orderbookStore: orderbookStoreInitial,
  transfersStore: transfersStoreInitial,
  notificationsStore: notificationsStoreInitial,
  announcementsStore: announcementsStoreInitial,
  buysellStore: buysellStoreInitial,
  balancesStore: balancesStoreInitial,
  ordersStore: ordersStoreInitial,
  chatboxStore: chatboxStoreInitial,
  configStore: configStoreInitial,
};
