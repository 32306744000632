import { ColorTheme } from 'types';
import React from 'react';
import classnames from 'classnames';
import { Caption } from 'components/Text';
import styles from './Label.module.scss';

type Props = {
  theme?: ColorTheme,
  className?: string,
};

const Label: React.FC<Props> = ({ theme = 'primary', className, children }: FunctionProps<Props>) => (
  <Caption
    className={ classnames(styles.label, className, styles[theme]) }
    weight="medium"
  >
    { children }
  </Caption>
);

export default Label;
