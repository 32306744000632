import { createSelector } from 'reselect';
import { StoreState } from '../index';

const getState = (state: StoreState) => state.orderbookStore || {};

export const getOrderbook = createSelector(
  [getState],
  (state) => state,
);

export const getOrderbookTimestamp = createSelector(
  [getState],
  (state) => state.timestamp,
);

export const getBids = createSelector(
  [getState],
  (state) => state.bids,
);
export const getAsks = createSelector(
  [getState],
  (state) => state.asks,
);

export const getOrderbookPricePrecision = createSelector(
  [getOrderbook],
  (state) => state.pricePrecision,
);
export const getOrderbookSizePrecision = createSelector(
  [getOrderbook],
  (state) => state.sizePrecision,
);

