import React from 'react';
import classnames from 'classnames';
import { Locale, LocaleCode } from 'types';
import Select from 'components/Select';
import { t } from 'components/Translate';
import Icon from 'components/Icon';

import styles from './LocalePicker.module.scss';
import { Text } from 'components/Text';

type SelectProps = GCP<Select<Locale>>;
type Props = {
  locales: Locale[],
  current: LocaleCode,
  onChange: (code: LocaleCode) => void,
  fetch: () => void,
};

class LocalePicker extends React.Component<Required<Props>> {
  componentDidMount() {
    if (this.props.locales.length) return;

    this.props.fetch();
  }

  private onChange: SelectProps['onChange'] = (item) => this.props.onChange(item.code);

  render() {
    const { current, locales } = this.props;
    if (!locales.length) return null;

    return (
      <Select<Locale>
        className={ classnames('col btn__shadow', styles.scrollbar) }
        title={ t('common.localePicker.title') }
        items={ locales }
        selected={ current }
        keyParam="code"
        onChange={ this.onChange }
        renderSelected={ this.renderSelected }
        renderItem={ this.renderItem }
      />
    );
  }
  private renderSelected: SelectProps['renderSelected'] = (item) => {
    if (!item) return null;

    return (
      <div className={ styles.inner }>
        <div className={ styles.icon }>
          <Icon.LocaleFlag name={ item.code } />
        </div>
        <Text weight="medium" className="text-clip">
          { item.name }
        </Text>
        <div className={ styles.chevron }>
          <Icon.Normal name="chevron-down" size="x-small" />
        </div>
      </div>
    );
  };
  private renderItem: SelectProps['renderItem'] = ({ key, item, onClick }) => (
    <li key={ key } onClick={ () => onClick() } className={ this.props.current }>
      <Icon.LocaleFlag name={ item.code } />
      <Text type="small" weight="medium">
        { item.name }
      </Text>
    </li>
  );
}

export default LocalePicker;
