import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { SIGNUP, ADVANCED_VIEW } from 'constants/routes';

import { Heading, Subtitle } from 'components/Text';
import { t } from 'components/Translate';
import Icon from 'components/Icon';
import MobileAppsButtons from 'components/MobileAppsButtons';

import styles from '../Header.module.scss';

// TODO: move android ios badges to components and reuse on landing

type Props = {
  isActive: boolean,
};
const HeaderAnonymousProducts: React.FC<Props> = ({ isActive }: FunctionProps<Props>) => (
  <div className={ classnames(styles.productMenu, { [styles.productMenuActive]: isActive }) }>
    <div className="container">
      <div className={ styles.productMenuImage } />
      <div className="row">
        <div className="col-5">
          <Subtitle color="dark-light" type="small" className="mt-0 mb-2">
            { t('landing.header.ourProduct') }
          </Subtitle>
          <Link to={ SIGNUP }>
            <div className={ styles.productMenu_product }>
              <Icon.Circled color="primary" name="zap" className="mr-3" />
              <div className={ styles.productMenu_productTxt }>
                <Subtitle weight="medium" type="normal" className="mt-0 mb-0">
                  { t('common.link.instant') }
                </Subtitle>
                <Subtitle color="dark-light" type="small" className="mt-0 mb-0">
                  { t('landing.header.instantDesc') }
                </Subtitle>
              </div>
            </div>
          </Link>
          <a href={ ADVANCED_VIEW }>
            <div className={ styles.productMenu_product }>
              <Icon.Circled color="black" name="trending-up" className="mr-3" />
              <div className={ styles.productMenu_productTxt }>
                <Subtitle weight="medium" type="normal" className="mt-0 mb-0">
                  { t('common.link.advanced') }
                </Subtitle>
                <Subtitle color="dark-light" type="small" className="mt-0 mb-0">
                  { t('landing.header.advancedDesc') }
                </Subtitle>
              </div>
            </div>
          </a>
        </div>
        <div className="col-4 pl-4">
          <Heading type="large" className="mt-2 mb-3">
            { t('landing.header.adsTitle') }
          </Heading>
          <Subtitle color="dark-light" type="small" className="mt-0 mb-4">
            { t('landing.header.adsDesc') }
          </Subtitle>
          <MobileAppsButtons
            className="d-flex align-items-center"
            iosClass={ styles.appstore }
            androidClass={ styles.gplay }
          />
        </div>
      </div>
    </div>
  </div>
);

export default HeaderAnonymousProducts;
