import React from 'react';
import { Switch, Route } from 'react-router';

import { loadable } from 'helpers/component';
import App from 'routes/App';

import * as ROUTES from './routes';
import styles from './Statics.module.scss';
import Services from 'services';

const About = loadable(() => import(/* webpackChunkName: "statics-about" */ './route/About'));
const AmlPolicy = loadable(() => import(/* webpackChunkName: "statics-amlpolicy" */ './route/AmlPolicy'));
const Contact = loadable(() => import(/* webpackChunkName: "statics-contact" */ './route/Contact'));
const CookiePolicy = loadable(() => import(/* webpackChunkName: "statics-cookiepolicy" */ './route/CookiePolicy'));
const Fees = loadable(() => import(/* webpackChunkName: "statics-fees" */ './route/Fees'));
const PrivacyPolicy = loadable(() => import(/* webpackChunkName: "statics-privacypolicy" */ './route/PrivacyPolicy'));
const RefundPolicy = loadable(() => import(/* webpackChunkName: "statics-refundpolicy" */ './route/RefundPolicy'));
const Terms = loadable(() => import(/* webpackChunkName: "statics-terms" */ './route/Terms'));
const Unsubscribe = loadable(() => import(/* webpackChunkName: "statics-unsubscribe" */ './route/Unsubscribe'));
const PageNotFound = loadable(() => import(/* webpackChunkName: "page-not-found" */ './route/PageNotFound'));

class Statics extends React.Component {
  render() {
    return (
      <App>
        <div className={ styles.app }>
          <Switch>
            <Route path={ ROUTES.ABOUT } component={ About } />
            <Route path={ ROUTES.AML_POLICY } component={ AmlPolicy } />
            <Route path={ ROUTES.CONTACT } component={ Contact } />
            <Route path={ ROUTES.COOKIE_POLICY } component={ CookiePolicy } />
            <Route path={ ROUTES.FEES } component={ Fees } />
            <Route path={ ROUTES.PRIVACY_POLICY } component={ PrivacyPolicy } />
            <Route path={ ROUTES.REFUND_POLICY } component={ RefundPolicy } />
            <Route path={ ROUTES.TERMS_OF_USE } component={ Terms } />
            <Route path={ ROUTES.UNSUBSCRIBE } component={ Unsubscribe } />
            <Route
              path={ ROUTES.NOT_FOUND }
              render={ () => {
                // if route not found than maybe it should be localized, so check
                // if its just 404 then NotFound will be shown
                if (Services.get('translations').ensureLocalizedUrl()) return null;

                return <PageNotFound />;
              } }
            />
          </Switch>
        </div>
      </App>
    );
  }
}

export default Statics;
