import React from 'react';
import { MediaQueryType, MediaQueryTypeResponse } from 'types';
import Services from 'services';
import last from 'lodash/last';
import { BREAKPOINTS_MAP } from 'constants/ui';

type Props = {
  children: (device: MediaQueryTypeResponse) => React.ReactNode,
};

type State = {
  currentQuery: MediaQueryType,
};

const BREAKPOINTS = Object.keys(BREAKPOINTS_MAP) as Array<keyof typeof BREAKPOINTS_MAP>;
const BREAKPOINTS_ASC = [...BREAKPOINTS].reverse();
const getIndex = (query: MediaQueryType) => BREAKPOINTS_ASC.findIndex((breakpoint) => breakpoint === query);
const isDown = (current: MediaQueryType) => (down: MediaQueryType) => getIndex(current) <= getIndex(down);
const isUp = (current: MediaQueryType) => (up: MediaQueryType) => getIndex(current) >= getIndex(up);

class MediaQuery extends React.Component<Required<Props>> {
  state: State = {
    currentQuery: this.currentQuery,
  };

  componentDidMount() {
    Services.get('events').on('RESIZE', this.onResize);
  }
  componentWillUnmount() {
    Services.get('events').off('RESIZE', this.onResize);
  }

  private get currentQuery() {
    const defaultBreakpoint = last(BREAKPOINTS)!;
    const width = window.innerWidth;
    return BREAKPOINTS.find((breakpoint) => width >= BREAKPOINTS_MAP[breakpoint]) || defaultBreakpoint;
  }

  private onResize = () => {
    const mediaQuery = this.currentQuery;

    // Update state if mediaQuery changes
    if (mediaQuery !== this.state.currentQuery) {
      this.setState({
        currentQuery: this.currentQuery,
      });
    }
  };

  render() {
    const { currentQuery } = this.state;

    return this.props.children({
      query: currentQuery,
      isXS: currentQuery === 'xs',
      isSM: currentQuery === 'sm',
      isMD: currentQuery === 'md',
      isLG: currentQuery === 'lg',
      isXL: currentQuery === 'xl',
      isDown: isDown(currentQuery),
      isUp: isUp(currentQuery),
    });
  }
}

export default MediaQuery;
