import { ChannelName, FiatCurrencyResponse, User, ChannelHandler, StandardResponse,
  Country, CountryState } from 'types';
import { userLogout, balancesUpdated } from 'store/actions';
import Services from 'services';
import { waitForRunning } from 'utils';
import * as cookies from 'helpers/cookies';

const channel: ChannelName = 'AccountChannel';
type Channel = typeof channel;

const FIAT_COOKIE = '_CoinFalcon_fiat';

class UserService {
  constructor() {
    // eslint-disable-next-line
    this.singleFetch = waitForRunning(this.singleFetch);
  }

  fetch() {
    return this.singleFetch();
  }
  private singleFetch() {
    type Response = {
      fiatRate: string,
      user: User,
      chatUsername: string,
      admin: boolean,
      hasTrades: boolean,
      hasPaymentMethod: boolean,
      hasVerifiedEmail: boolean,
      hasCardsEnabled: boolean,
      fee: number,
    };
    return Services.get('api').get<Response>('v2/user');
  }

  setFiatCurrency(currencyCode: string) {
    cookies.set(FIAT_COOKIE, currencyCode);
    return Services.get('api').put<FiatCurrencyResponse>('settings/account/update_json', { user: { currencyCode } });
  }
  async setCountryState(data: { countryUuid: string | null, stateUuid: string | null }) {
    type Response = StandardResponse<{ country: Country['name'], state: CountryState['name'] }>;
    const result = await Services.get('api').post<Response>('user/update_country_state', data);
    if (!result.success) throw new Error(result.message);

    const { success, ...rest } = result;
    return rest;
  }

  subscribe() {
    this.unsubscribe();
    return Services.get('cable').subscribe<Channel>({
      channel,
      handler: this.handler,
    });
  }
  private handler: ChannelHandler<Channel> = async (message) => {
    if (!message) return;

    const { command } = message;

    if (command === 'logout') {
      // eslint-disable-next-line
      await Services.get('store').dispatch(userLogout());
      window.location.pathname = '/';
    } else {
      // eslint-disable-next-line
      // @ts-ignore
      const { data: { balances } } = message;
      Services.get('store').dispatch(balancesUpdated(balances));
    }
  };

  unsubscribe() {
    Services.get('cable').unsubscribe({ channel });
  }
}

export default UserService;
