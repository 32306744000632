
export const ADVANCED_VIEW = '/advanced-view';
export const SUPPORT = 'https://support.coinfalcon.com';

export * from 'routes/Onboarding/routes';
export * from 'routes/Landing/routes';
export * from 'routes/Prices/routes';
export * from 'routes/Statics/routes';

export * from 'routes/Dashboard/routes';
export * from 'routes/Dashboard/BuySell/routes';
export * from 'routes/Settings/routes';
export * from 'routes/CoinDetails/routes';
export * from 'routes/Withdraw/routes';
export * from 'routes/Deposit/routes';
export * from 'routes/Referral/routes';
export * from 'routes/Verification/routes';
export * from 'routes/BankAccountAdd/routes';
export * from 'routes/CountryOfResidence/routes';
