import { LocaleCode } from 'types';
import { SUPPORTED_LOCALES, DEFAULT_LOCALE } from 'config';
import * as Cookies from './cookies';

const COOKIE = '_CoinFalcon_language';

export const LOCALE_URL_PATTERN = new RegExp(`^/(${ SUPPORTED_LOCALES.join('|') })/?`);

export const getLocaleFromUrl = () => {
  const pathname = window.location.pathname;
  const match = LOCALE_URL_PATTERN.exec(pathname);
  if (match === null || match.length < 2) return null;

  const locale = match[1];
  return locale as LocaleCode;
};

export const changeLocaleUrl = (locale: LocaleCode = DEFAULT_LOCALE) => {
  const pathname = window.location.pathname;
  let pathnameWithoutLocale = pathname;
  if (getLocaleFromUrl()) {
    pathnameWithoutLocale = pathname.replace(LOCALE_URL_PATTERN, '/');
  }

  const url = `${ locale }${ pathnameWithoutLocale }`;
  window.location.pathname = url;
};
export const detectLocale = () => {
  const locale = getLocaleFromUrl();
  if (locale && SUPPORTED_LOCALES.includes(locale)) return locale;

  const cookieLocale = Cookies.get(COOKIE) as LocaleCode | null;
  if (cookieLocale && SUPPORTED_LOCALES.includes(cookieLocale)) return cookieLocale;

  return DEFAULT_LOCALE;
};

