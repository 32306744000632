import { createReducer } from 'utils';
import { ReferralsStats } from 'types';
import { ActionsPayload } from './types';
import { REFERRAL_TRADE } from 'config';

export type State = Readonly<{
  stats: ReferralsStats | null,
}>;

export const initialState: State = {
  stats: null,
};

const referralReducer = createReducer<State, ActionsPayload>(
  initialState,
  {
    'referral/STATS_SET': (state, { payload }) => ({
      ...state,
      stats: payload,
    }),
    'referral/AMOUNT_TRADED': (state, { payload }) => {
      if (!state.stats || state.stats.tradedAmount === null) return state;

      const tradedAmount = Math.round(+state.stats.tradedAmount + payload);
      const claimed = tradedAmount >= REFERRAL_TRADE ? true : false;

      return {
        ...state,
        stats: {
          ...state.stats,
          tradedAmount,
          claimed,
        },
      };
    },
  },
);


export default referralReducer;
