import classnames from 'classnames';
import { ABOUT, CONTACT, FEES, PRICES, REFERRAL_ANONYMOUS, SUPPORT, TERMS_OF_USE } from 'constants/routes';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  HOW_TO_BUY_BITCOIN,
  HOW_TO_BUY_BITCOIN_CASH,
  HOW_TO_BUY_ETHEREUM,
  HOW_TO_BUY_IOTA,
  HOW_TO_BUY_LITECOIN,
  HOW_TO_BUY_XRP,
} from 'routes/Guides/routes';

import { Subtitle } from 'components/Text';
import { t } from 'components/Translate';
import styles from './AppFooter.module.scss';

const get = (key: string) => t(`common.footer.${ key }`);
const target = '_blank';

type Url = string;
type Entry = [React.ReactChild, Url, React.ReactNode?, Partial<React.AnchorHTMLAttributes<HTMLAnchorElement>>?];
type Column = { title: string, list: Entry[] };
const COLUMNS: Column[] = [
  {
    title: 'common.footer.company',
    list: [
      [get('about'), ABOUT],
      [get('fees'), FEES],
      [get('legalPrivacy'), TERMS_OF_USE],
      [get('referral'), REFERRAL_ANONYMOUS],
    ],
  },
  {
    title: 'common.footer.learn',
    list: [
      [get('prices'), PRICES],
      [t('common.footer.buy', { coin: 'Bitcoin' }), HOW_TO_BUY_BITCOIN],
      [t('common.footer.buy', { coin: 'Bitcoin Cash' }), HOW_TO_BUY_BITCOIN_CASH],
      [t('common.footer.buy', { coin: 'Ethereum' }), HOW_TO_BUY_ETHEREUM],
      [t('common.footer.buy', { coin: 'Litecoin' }), HOW_TO_BUY_LITECOIN],
      [t('common.footer.buy', { coin: 'XRP' }), HOW_TO_BUY_XRP],
      [t('common.footer.buy', { coin: 'IOTA' }), HOW_TO_BUY_IOTA],
    ],
  },
  {
    title: 'common.footer.support',
    list: [
      [get('help'), SUPPORT, null, { target, rel: 'noopener' }],
      [get('contact'), CONTACT],
      ['API', 'https://docs.coinfalcon.com', null, { target, rel: 'noopener' }],
    ],
  },
];

type Props = {
};

const className = classnames('d-block', styles.footerLink);
const LinksComponent: React.FC<Props> = React.memo(function Links() {
  return (
    <>
      { COLUMNS.map((column) => (
        <div key={ column.title } className="col-lg-3 col-6">
          <Subtitle type="normal" weight="medium" className={ styles.footerTitle }>
            { t(column.title) }
          </Subtitle>
          { column.list.map(([text, url, custom = null, attrs = null]) => (
              !attrs
                ? <Link key={ url } to={ url } className={ className }>{ text }{ custom }</Link>
                : <a className={ className } key={ url } href={ url } { ...attrs }>
                  { text }
                  { custom }
                </a>
          ))
          }
        </div>
      )) }
    </>
  );
});

export default LinksComponent;
