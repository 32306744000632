import memoize from 'lodash/memoize';
import { createSelector } from 'reselect';
import { Market, CoinCode } from 'types';
import { unique } from 'utils';
import { getCoinByCode, getAllCoinByCode } from 'store/coins/selectors';
import { sortCoins } from 'helpers/sort';
import { isReversable } from 'helpers/coin';
import { isDefined } from 'helpers/types';
import { createMarketName } from 'helpers/markets';

import { StoreState } from '../index';

const getState = (state: StoreState) => state.marketsStore || {};

export const getMarketsList = createSelector(
  [getState],
  (state) => state.list,
);
export const hasMarketsList = createSelector(
  [getState],
  (state) => !!state.list.length,
);

export const getMarketsListWithCurrencies = createSelector(
  [getMarketsList, getCoinByCode],
  (markets, getCoin): Market[] =>
    markets
      .map((market) => {
        const leftCurrency = getCoin(market.leftCurrencyCode)!;
        const rightCurrency = getCoin(market.rightCurrencyCode)!;

        return {
          ...market,
          leftCurrency,
          rightCurrency,
        };
      })
      .filter(({ leftCurrency, rightCurrency }) => leftCurrency !== null && rightCurrency !== null),
);

export const getMarketByCurrencies = createSelector(
  [getMarketsListWithCurrencies],
  (markets) => ({ left, right }: { left: CoinCode, right: CoinCode }) =>
    markets.find(({ leftCurrencyCode, rightCurrencyCode }) =>
      leftCurrencyCode === left && rightCurrencyCode === right) || null,
);
export const getMarketByName = createSelector(
  [getMarketsListWithCurrencies],
  (markets) => memoize((marketName: Market['name']) =>
    markets.find(({ name }) => name === marketName) || null),
);
export const getMarketById = createSelector(
  [getMarketsList],
  (markets) => (id: Market['id']) => markets.find((market) => market.id === id) || null,
);

export const getMarketsWithLeftCurrency = createSelector(
  [getMarketsListWithCurrencies],
  (markets) => memoize((left: CoinCode) =>
    markets.filter(({ leftCurrencyCode }) => leftCurrencyCode === left)),
);
export const getMarketsWithRightCurrency = createSelector(
  [getMarketsListWithCurrencies],
  (markets) => memoize((right: CoinCode) =>
    markets.filter(({ rightCurrencyCode }) => rightCurrencyCode === right)),
);


export const getMarketsLeftCoinCodes = createSelector(
  [getMarketsListWithCurrencies],
  (markets) => unique(markets.map(({ leftCurrencyCode }) => leftCurrencyCode)),
);
export const getMarketsRightCoins = createSelector(
  [getMarketsListWithCurrencies],
  (markets) => unique(markets.map(({ rightCurrency }) => rightCurrency)),
);


export const getPossibleRightCoinCodes = createSelector(
  [getMarketsWithLeftCurrency],
  (getMarkets) => memoize((code: CoinCode) => getMarkets(code)
    .map(({ rightCurrencyCode }) => rightCurrencyCode)
    .sort(sortCoins)),
);

export const getPossibleRightCurrencies = createSelector(
  [getMarketsWithLeftCurrency],
  (getMarkets) => (left: CoinCode) => getMarkets(left)
    .sort((a, b) => sortCoins(a.rightCurrencyCode, b.rightCurrencyCode))
    .map(({ rightCurrency }) => rightCurrency),
);

export const getDefaultRightCoinCode = createSelector(
  [getPossibleRightCoinCodes],
  (getCodes) => (left: CoinCode) => {
    const codes = getCodes(left);
    if (!codes.length) return null;

    return codes[0];
  },
);


export const getPossibleLeftCoinCodes = createSelector(
  [getMarketsWithRightCurrency],
  (getMarkets) => memoize((code: CoinCode) => getMarkets(code)
    .map(({ leftCurrencyCode }) => leftCurrencyCode)
    .sort(sortCoins)),
);
export const getDefaultLeftCoinCode = createSelector(
  [getPossibleLeftCoinCodes],
  (getCodes) => (right: CoinCode) => {
    const codes = getCodes(right);
    if (!codes.length) return null;

    return codes[0];
  },
);


export const getPossibleMatchingCoinCodes = createSelector(
  [getPossibleRightCoinCodes, getPossibleLeftCoinCodes],
  (getPossibleRight, getPossibleLeft) => memoize((code: CoinCode) => {
    if (!isReversable(code)) {
      return getPossibleRight(code);
    }

    const possibleLeft = getPossibleLeft(code);
    const possibleRight = getPossibleRight(code);
    return unique([...possibleLeft, ...possibleRight]).sort(sortCoins);
  }),
);

export const getPossibleMatchingCoins = createSelector(
  [getPossibleMatchingCoinCodes, getCoinByCode],
  (getPossibleCurrencies, getCoin) =>
    memoize((code: CoinCode) => getPossibleCurrencies(code)
      .map((c) => getCoin(c))
      .filter(isDefined),
    ),
);

export const getDefaultMatchingCoinCode = createSelector(
  [getPossibleMatchingCoinCodes],
  (getPossibleCurrencies) => (code: CoinCode) => {
    const codes = getPossibleCurrencies(code);
    if (!codes.length) return null;

    return codes[0];
  },
);

export const getMarketsNames = createSelector(
  [getMarketsList],
  (markets) => markets.map(({ name }) => name),
);

export const isMarketReversed = createSelector(
  [getMarketsNames],
  (markets) => (left: CoinCode, right: CoinCode) =>
    markets.some((name) => name.toLowerCase() === createMarketName(right, left).toLowerCase()),
);

export const getAllMarketsList = createSelector(
  [getState],
  (state) => [...state.list, ...state.delisted],
);
export const getAllMarketsListWithCurrencies = createSelector(
  [getAllMarketsList, getAllCoinByCode],
  (markets, getCurrency): Market[] =>
    markets.map((market) => {
      const leftCurrency = getCurrency(market.leftCurrencyCode)!;
      const rightCurrency = getCurrency(market.rightCurrencyCode)!;

      return {
        ...market,
        leftCurrency,
        rightCurrency,
      };
    })
      .filter(({ leftCurrency, rightCurrency }) => leftCurrency !== null && rightCurrency !== null),
);
export const getAllMarketByName = createSelector(
  [getAllMarketsListWithCurrencies],
  (markets) => memoize((marketName: Market['name']) => markets.find(({ name }) => name === marketName) || null),
);


export const getLatestMarketPrice = createSelector(
  [getMarketByCurrencies],
  (getMarket) => (left: CoinCode, right: CoinCode) => {
    const market = getMarket({ left, right });
    if (!market) return 0;

    return parseFloat(market.latestTradePrice);
  },
);

