import React from 'react';
import { IconName, Color } from 'types';
import classnames from 'classnames';
import { ICONS } from 'constants/icons';
import styles from './Icon.module.scss';

const icons = ICONS;

type Props = {
  className?: string,
  name: IconName,
  size?: 'xx-small' | 'x-small' | 'small' | 'normal' | 'medium' | 'large' | 'x-large' | 'xx-large',
  color?: Color | 'white' | 'gray' | 'gray-light' | 'dark-light',
  hasShadow?: boolean,
  onClick?: () => void,
};

const InlinedIcon: React.FC<Props> = React.memo(({
  name,
  hasShadow = false,
  size = 'medium',
  color = 'black',
  className = '',
  onClick,
}: FunctionProps<Props>) => {
  const Icon = require(`@svgr/webpack!../../../public/assets/icon/${ icons[name] }`).ReactComponent;
  return (
    <span className={ classnames({ [styles.withShadow]: hasShadow, [styles.withShadowInlined]: hasShadow }) } >
      <Icon
        className={ classnames(styles.icon, className, styles[size], styles[color]) }
        onClick={ onClick && (() => onClick()) }
      />
    </span>
  );
});

export default InlinedIcon;
