import React from 'react';
import Loader from 'components/Loader';

const CenteredLoader: React.FC = () => (
  <div className="align-self-center">
    <Loader className="mx-auto" />
  </div>
);

export default CenteredLoader;
