import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { IconName, Route, User, MediaQueryTypeResponse } from 'types';
import { REFERRAL_COMMISION } from 'config';
import { addClassToBody, removeClassFromBody } from 'helpers/ui';
import * as ROUTES from 'constants/routes';

import { OVERFLOW_CLASS } from 'constants/ui';

import Icon from 'components/Icon';
import ClickOutside from 'components/ClickOutside';
import Label from 'components/Label';
import { t } from 'components/Translate';
import Text, { Subtitle } from 'components/Text';
import StandardLink from 'components/Link/Standard';

import styles from './UserHeader.module.scss';
import headerStyles from '../Header.module.scss';

type Props = {
  user: User | null,
  logout: () => void,
  device: MediaQueryTypeResponse,
};
type State = {
  isShown: boolean,
};

class UserHeader extends React.Component<Props, State> {
  state: State = {
    isShown: false,
  };

  toggle = () => {
    this.setState({ isShown: !this.state.isShown }, () => {
      if (this.state.isShown && this.props.device.isXS) {
        addClassToBody(OVERFLOW_CLASS);
      } else {
        removeClassFromBody(OVERFLOW_CLASS);
      }
    });
  };
  hide = (e?: MouseEvent | TouchEvent) => {
    if (!this.state.isShown) return;

    if (e) e.stopPropagation();
    this.setState({ isShown: false });
    removeClassFromBody(OVERFLOW_CLASS);
  };

  private get canOperate() {
    return this.props.user && this.props.user.canOperate;
  }

  render() {
    const { user } = this.props;

    return (
      <>
        <div className={ styles.wrapper }>
          <ClickOutside onClickOutside={ this.hide }>
            <div>
              <div className={ styles.inner } onClick={ this.toggle }>
                <div className={ styles.icon }>
                  <Icon.Normal name="user" hasShadow={ true } />
                </div>
                <span className={ styles.user }>
                  <Subtitle type="small">
                    { user ? user.fullName : '' }
                  </Subtitle>
                </span>
                <div className={ styles.chevron }>
                  <Icon.Normal name="chevron-down" />
                </div>
              </div>
              { this.renderDropdown() }
            </div>
          </ClickOutside>
        </div>

        { this.state.isShown && <div className="overlay d-sm-none" /> }
      </>
    );
  }
  private renderDropdown() {
    const { device } = this.props;
    const classes = classnames(headerStyles.dropdown, headerStyles.user,
      { [headerStyles.is__active]: this.state.isShown });

    const isAdvancedVisible = device.isDown('md');

    return (
      <div className={ classes }>
        { this.renderLink('user', ROUTES.SETTINGS_ACCOUNT, 'common.link.account', 'spa') }
        { this.renderLink('mail', ROUTES.REFERRAL_AUTHENTICATED, 'common.link.inviteFriends', 'spa',
          <Label theme="success">{ t('common.link.earn', { value: REFERRAL_COMMISION }) }</Label>,
          styles.inviteLink__mob) }
        { this.renderLink('lock', ROUTES.SETTINGS_SECURITY, 'common.link.security', 'spa') }
        { this.renderLink('history', ROUTES.SETTINGS_HISTORY, 'common.link.history', 'spa') }
        { isAdvancedVisible && this.renderLink('advanced', ROUTES.ADVANCED_VIEW, 'common.link.advanced', 'standard') }
        { this.renderLink('support', ROUTES.SUPPORT, 'common.link.support', 'standard') }
        { this.canOperate && <hr className={ styles.line } /> }
        <div className={ headerStyles.btn } onClick={ this.props.logout }>
          <Icon.Normal name="log-out" />
          <Text type="normal" weight="medium">
            { t('common.action.logout') }
          </Text>
        </div>
      </div>
    );
  }
  private renderLink(
    icon: IconName, url: Route, key: string, type: 'standard' | 'spa', additional?: React.ReactNode,
    classes?: string,
  ) {
    if (!this.canOperate) return null;

    const Links = {
      standard: StandardLink,
      spa: Link,
    };

    const Component = Links[type] as typeof StandardLink;

    return (
      <Component to={ url } className={ classes } onClick={ this.hide }>
        <div>
          <Icon.Normal name={ icon } />
          <Text type="normal" weight="medium">
            { t(key) }
          </Text>
        </div>
        { additional }
      </Component>
    );
  }
}

export default UserHeader;

