import * as ROUTES from 'constants/routes';

import { WITHDRAW_ROUTES } from 'routes/Withdraw/routes-group';
import { DEPOSIT_ROUTES } from 'routes/Deposit/routes-group';
import { DASHBOARD } from './routes';
import { BUY_MARKET, SELL_MARKET } from './BuySell/routes';

export const DASHBOARD_ROUTES = [
  DASHBOARD,
  BUY_MARKET, SELL_MARKET,
  ...DEPOSIT_ROUTES,
  ...WITHDRAW_ROUTES,
  ROUTES.VERIFICATION,
  ROUTES.BANK_ADD,
  ROUTES.UPDATE_COUNTRY,
];
