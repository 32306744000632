import { StandardResponse, PaginationResult, HistoryItem, HistoryMonth, HistoryItemType,
  CoinCode, HistoryFilters } from 'types';
import { logger } from 'utils';
import Services from 'services';
import { pad } from 'helpers/text';
import { getMonthName } from 'helpers/time';
import { sortAlphabetically } from 'helpers/sort';

type HistoryFilterParams = {
  type: HistoryItemType | 'order' | 'all',
  coinCode: CoinCode,
  date: string,
  dateFrom: string,
  dateTo: string,
  query: string,
};

const log = (...messages: any[]) => logger('history', ...messages);

class HistoryService {
  async export(filters: Partial<HistoryFilters> = {}, query = '') {
    const params = this.convertToFilterParams(filters, query);
    const result = await Services.get('api').post<StandardResponse>('v2/export', params);
    if (!result.success) throw new Error(result.message);

    return result;
  }

  get = async (page: number, filters: Partial<HistoryFilters> = {}, query = '') => {
    log('[GET]', 'page', page, filters);
    const result = await this.fetch(page, this.convertToFilterParams(filters, query));
    log('[GET]', 'result', result);
    return {
      ...result,
      data: this.groupByMonth(result.data),
    };
  };
  async fetch(page: number, params: Partial<HistoryFilterParams> = {}) {
    type Response = StandardResponse<PaginationResult<HistoryItem>>;
    const result = await Services.get('api').get<Response>('v2/history', {
      page,
      ...params,
    });
    if (!result.success) throw new Error(result.message);

    return result;
  }

  private groupByMonth(items: HistoryItem[]): HistoryMonth[] {
    const grouped = items.reduce((result, item) => {
      const key = this.getKey(item);
      item.createdAt = new Date(item.createdAt).getTime();
      if (!result.hasOwnProperty(key)) {
        result[key] = {
          id: key,
          month: this.getMonthDescription(new Date(item.createdAt)),
          data: [],
        };
      }

      result[key].data.push(item);
      return result;
    }, {} as any as Record<string, HistoryMonth>);

    const sorted = Object.values(grouped).sort((a, b) => sortAlphabetically(a.id, b.id)).reverse();
    sorted.forEach((month) => month.data.sort((a, b) => b.createdAt - a.createdAt));

    log('[GROUP]', grouped, sorted);
    return sorted;
  }
  private getKey(item: HistoryItem) {
    const date = new Date(item.createdAt);
    return `${ date.getFullYear() }_${ pad(date.getMonth() + 1) }`;
  }
  private getMonthDescription(date: Date) {
    const today = new Date();
    if (date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear()) return 'Earlier this month';

    const month = getMonthName(date);
    if (date.getFullYear() === today.getFullYear()) return month;
    return `${ month } ${ date.getFullYear() }`;
  }

  private convertToFilterParams(filters: Partial<HistoryFilters>, query: string): Partial<HistoryFilterParams> {
    return {
      type: filters.type,
      coinCode: filters.currency ? filters.currency : undefined,
      date: filters.date ? filters.date.replace('d', '') : undefined,
      dateFrom: filters.datesRange && filters.datesRange.from ? filters.datesRange.from.toISOString() : undefined,
      dateTo: filters.datesRange && filters.datesRange.to ? filters.datesRange.to.toISOString() : undefined,
      query: !!query.length ? query : undefined,
    };
  }
}

export default HistoryService;
