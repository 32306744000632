import { Card, StandardResponse, CardCreateParams, CardChargeParams, Order, CodeError } from 'types';
import { calcCardAmountWithFee } from 'helpers/card';
import Services from 'services';
import { RedirectData } from './types';
import { handleRedirect, willRedirect } from './redirect';
import { handle as handleCallback } from './callback';

export default class CardService {
  async fetchAll() {
    type Response = StandardResponse<{ cards: Card[] }>;
    const result = await Services.get('api').get<Response>('v2/cards');
    if (!result.success) throw new Error(result.message);

    return result.cards;
  }
  async remove(id: Card['id']) {
    const result = await Services.get('api').del<StandardResponse>(`user/cards/${ id }`);
    if (!result.success) throw new Error(result.message);
  }

  create(data: CardCreateParams) {
    return this.createWithPurpose({ ...data, purpose: 'add', amount: 0 });
  }
  createAndCharge(data: CardChargeParams & CardCreateParams) {
    return this.createWithPurpose(data);
  }
  private async createWithPurpose(data: CardCreateParams & CardChargeParams) {
    type Response = StandardResponse<{ card: Card | null } & RedirectData>;
    const params = {
      cardNumber: data.ccn,
      cvv: data.cvc,
      expiryDate: data.expiration.replace(/ /g, ''),
    };
    let amount: number | undefined;
    if (data.amount && data.amount > 0) amount = calcCardAmountWithFee(data.amount);
    if (data.total) amount = data.total;

    const result = await Services.get('api').post<Response>('user/cards', {
      card: params,
      purpose: `${ data.purpose }${ data.specificPurpose || '' }`,
      amount,
    });
    if (!result.success) {
      if (result.code) throw new CodeError(result.code, result.message);
      throw new Error(result.message);
    }
    await handleRedirect(result);

    return result;
  }

  async charge(data: CardChargeParams & { id: Card['id'], pendingOrderUuid?: string }) {
    type Response = StandardResponse<{ orders?: Order[], amount: string} & RedirectData>;

    const { amount, total, id, purpose, specificPurpose, ...rest } = data;
    if (!amount && !total) throw new Error('Card charge: amount or total has to be provided!');
    const value = total ? total : calcCardAmountWithFee(amount!);

    const result = await Services.get('api').post<Response>(`user/cards/${ id }/charge`, {
      ...rest,
      purpose: `${ purpose }${ specificPurpose || '' }`,
      amount: value,
    });
    if (!result.success) {
      if (result.code) throw new CodeError(result.code, result.amount || result.message);
      throw new Error(result.message);
    }

    // optimistically save wallet data
    if (willRedirect(result)) {
      Services.get('buySell').saveWalletData();
    }
    await handleRedirect(result);

    return result;
  }


  handleCallbackData() {
    return handleCallback();
  }
}
