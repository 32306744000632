import React from 'react';
import ReactScrollbars from 'react-custom-scrollbars';
import styles from './Scrollbar.module.scss';

type Props = {
  scrollBottomOnUpdate?: boolean,
  scrollBottomThreshold?: number,
  style?: {},
  autoHeight?: boolean,
} & GetComponentProps<ReactScrollbars>;

class Scrollbars extends React.PureComponent<Required<Props>> {
  static defaultProps: OptionalProps<Props> = {
    scrollBottomOnUpdate: false,
    style: {},
    autoHeight: false,
    hideTracksWhenNotNeeded: true,
  };

  private scrollbar = React.createRef<ReactScrollbars>();
  private canScrollBottom = false;

  componentDidMount() {
    this.checkScrollBottom();
  }

  componentDidUpdate() {
    this.checkScrollBottom();
  }

  checkScrollBottom() {
    const { scrollBottomThreshold } = this.props;

    if (this.scrollbar && this.scrollbar.current && this.props.scrollBottomOnUpdate) {
      const { clientHeight, scrollHeight, scrollTop } = this.scrollbar.current.getValues();
      const scrolledDiff = scrollHeight - (Math.floor(scrollTop) + clientHeight);
      this.canScrollBottom = scrolledDiff > 0;

      if (scrollBottomThreshold >= 0 && scrolledDiff <= scrollBottomThreshold) {
        this.scrollbar.current.scrollToBottom();
      } else if (!scrollBottomThreshold && this.canScrollBottom) {
        this.scrollbar.current.scrollToBottom();
      }
    }
  }

  render() {
    const { children, scrollBottomOnUpdate, scrollBottomThreshold, ...props } = this.props;

    return (
      <ReactScrollbars
        ref={ this.scrollbar }
        { ...props }
        renderTrackHorizontal={ this.renderHorizontal  }
        renderTrackVertical={ this.renderVertical }
        hideTracksWhenNotNeeded={ this.props.hideTracksWhenNotNeeded }
      >
        { children }
      </ReactScrollbars>
    );
  }
  private renderHorizontal = (props: any) =>
    <div { ...props } className={ styles.horizontal } />;
  private renderVertical = (props: any) =>
    <div { ...props } className={ styles.vertical } />;
}

export default Scrollbars;
