/* eslint-disable max-len */

import React from 'react';

type Props = {
  tag?: keyof React.ReactHTML,
  className?: string,
  width?: number | null,
  title?: string,
};
const Clipper: React.FC<Props> =
({ tag = 'span', className = '', title = '', children, width = 150 }: FunctionProps<Props>) => {
  const classNames = `text-clip ${ className }`;
  return React.createElement(tag, {
    className: classNames,
    title,
    style: { width },
  }, children);
};

export default Clipper;
