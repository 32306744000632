/* eslint-disable no-console */

export const logger = (module: string, ...messages: any[]) => {
  if (!__DEV__ && !__STAGING__) return;

  console.log(`[${ module.toUpperCase() }]`, ...messages);
};

export const warn = (module: string, ...messages: any[]) => {
  if (!__DEV__ && !__STAGING__) return;

  console.warn(`[${ module.toUpperCase() }]`, ...messages);
};

export const valueLogger = (module: string, message: string, value: any) => {
  if (!__DEV__ && !__STAGING__) return;

  console.groupCollapsed(`[${ module.toUpperCase() }]`, message);
  console.log(value);
  console.groupEnd();
};
