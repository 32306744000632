import { BREAKPOINTS_MAP, OVERFLOW_CLASS } from 'constants/ui';

export const isWidthMaxMD = () => window.innerWidth < BREAKPOINTS_MAP.lg;
export const isWidthMD = () => window.innerWidth <= BREAKPOINTS_MAP.md;
export const isWidthXS = () => window.innerWidth < BREAKPOINTS_MAP.sm;

export const ignoreHeightForDuration = (node: HTMLElement, duration: number) => {
  node.style.width = node.offsetWidth + 'px';
  node.style.height = node.offsetHeight + 'px';
  node.style.position = 'absolute';
  window.setTimeout(() => {
    node.style.width = null;
    node.style.height = null;
    node.style.position = null;
  }, duration);
};

const getScrollbarWidth = () => {
  const div = document.createElement('div');
  div.style.width = '200px';
  div.style.height = '200px';
  div.style.position = 'fixed';
  div.style.overflow = 'scroll';
  document.body.appendChild(div);
  const width = div.offsetWidth - div.clientWidth;
  document.body.removeChild(div);

  return width;
};

let scrollbarWidth = -1;
export const hideOverflow = () => {
  const body = document.body;
  if (scrollbarWidth === -1) scrollbarWidth = getScrollbarWidth();
  body.style.paddingRight =`${ scrollbarWidth }px`;
  body.classList.add(OVERFLOW_CLASS);
};

export const showOverflow = () => {
  const body = document.body;
  body.style.paddingRight = null;
  body.classList.remove(OVERFLOW_CLASS);
};

export const setOpacity = (element: HTMLElement, value: number) => {
  element.style.opacity = `${ value }`;
};

export const addClassToBody = (className: string) => {
  if (!document.body) return;

  document.body.classList.add(className);
};
export const removeClassFromBody = (className: string) => {
  if (!document.body) return;

  document.body.classList.remove(className);
};
