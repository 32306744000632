import React from 'react';
import { getAssetUrl } from 'helpers/assets';
import CommonIcon, { CommonIconProps } from './Common';

const getPath = (country: string, isLocale = false) => {
  const path = isLocale ? 'language' : 'flags/1x1';
  return getAssetUrl(`/${ path }/${ country.toLowerCase() }.svg`);
};

type Props = {
  country: string,
  isLocale?: boolean,
  async?: boolean,
} & CommonIconProps;

const CountryIcon: React.FC<Props> =
React.memo(({ country, isLocale, ...props }: FunctionProps<Props>) => (
  <CommonIcon
    src={ getPath(country, isLocale) }
    alt={ country }
    { ...props }
  />
));

export default CountryIcon;
