import { localize } from 'helpers/router';

const PREFIX = localize('/buy-');
export const HOW_TO_BUY_GUIDE = `${ PREFIX }:name`;
export const HOW_TO_BUY_BITCOIN = `${ PREFIX }bitcoin`;
export const HOW_TO_BUY_BITCOIN_CASH = `${ PREFIX }bitcoin-cash`;
export const HOW_TO_BUY_ETHEREUM = `${ PREFIX }ethereum`;
export const HOW_TO_BUY_LITECOIN = `${ PREFIX }litecoin`;
export const HOW_TO_BUY_XRP = `${ PREFIX }xrp`;
export const HOW_TO_BUY_IOTA = `${ PREFIX }iota`;
