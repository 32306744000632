import { ColorTheme } from 'types';
import React from 'react';
import classNames from 'classnames';
import { Subtitle } from 'components/Text';
import Icon from 'components/Icon';
import styles from './Alert.module.scss';

type Props = {
  theme?: Exclude<ColorTheme, 'ghost'>,
  heading?: string,
  showIcon?: boolean,
  isCloseable?: boolean,
  onClose?: () => void,
  className?: string,
};

class Alert extends React.PureComponent<Required<Props>> {
  static defaultProps: OptionalProps<Props> = {
    theme: 'primary',
    showIcon: false,
    isCloseable: true,
  };

  render() {
    const { theme, heading, onClose, className, showIcon, isCloseable, children } = this.props;
    const classes = classNames(
      'text',
      'regular',
      styles.notification,
      styles[theme],
      className,
      {
        [styles.no__close]: !isCloseable,
      },
    );

    if (children === null) return null;

    return (
      <div className={ classes }>
        { showIcon && <Icon.Inlined color={ theme } name="alert-triangle" className={ styles.alert__icon } /> }
        <span>
          { heading &&
            <Subtitle type="small" weight="medium" color={ theme } className="my-0">{ heading }</Subtitle>
          }
          { children }
          { isCloseable &&
            <Icon.Inlined
              name="x"
              color={ theme }
              onClick={ onClose && onClose  }
            />
          }
        </span>
      </div>
    );
  }
}

export default Alert;
