import { PriceAlert, PriceAlertType, Services as ServicesType, CoinCode } from 'types';
import { getTranslation } from 'components/Translate';
import { getCoinByCode } from 'store/coins';

import Services from 'services';

const isUp = (type: PriceAlertType) => type === 'increased_by' || type === 'more_than';
const isDown = (type: PriceAlertType) => type === 'decreased_by' || type === 'less_than';
const isAbsolute = (type: PriceAlertType) => type === 'more_than' || type === 'less_than';

class PriceAlertNotificationService {
  handle(data: PriceAlert) {
    Services.get('notifications').show({
      id: data.id.toString(),
      type: 'local',
      icon: this.getIcon(data),
      title: this.getTitle(data),
      description: getTranslation('notification.alert.description'),
      onClick: () => window.location.pathname = '/settings/alerts',
    });
  }

  private getTitle(data: PriceAlert) {
    let value = `${ data.trigger }%`;
    if (isAbsolute(data.alertType)) {
      value = `${ data.trigger } ${ data.priceCurrencyCode.toUpperCase() }`;
    }

    const titleKey = `notification.alert.type.${ data.alertType }`;
    const coin = Services.get('store').select(getCoinByCode)(data.currencyCode.toLowerCase() as CoinCode);
    return getTranslation(titleKey, {
      code: data.currencyCode.toUpperCase(),
      name: coin ? coin.name : data.currencyCode.toUpperCase(),
      value,
    });
  }
  private getIcon(data: PriceAlert): Args<ServicesType['notifications']['show']>[0]['icon'] {
    if (isUp(data.alertType)) {
      return {
        color: 'success',
        name: 'trending-up',
      };
    } else if (isDown(data.alertType)) {
      return {
        color: 'error',
        name: 'trending-down',
      };
    }

    return {
      color: 'primary',
      name: 'percent',
    };
  }
}

export default PriceAlertNotificationService;
