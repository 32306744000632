import { ChannelName, MarketPlain, Orderbook, ChannelHandler } from 'types';
import { parse } from 'helpers/orderbook';
import { bidsAdd, asksAdd, orderbookInit, orderbookReset, orderbookInitEmpty } from 'store/orderbook';
import Services from 'services';
import { delay } from 'utils';

const channel: ChannelName = 'OrderbookChannel';
type Channel = typeof channel;
type RawOrderbookEntry = { price: string, size: string };

class OrderbookService {
  async subscribe(market: MarketPlain['name'], callbacks = {}) {
    this.unsubscribe();
    Services.get('store').dispatch(orderbookReset());

    Services.get('cable').subscribe<Channel>({
      channel,
      parameters: { market },
      handler: this.handler,
      callbacks,
    });
    await delay(1000);
    // removes loading state
    Services.get('store').dispatch(orderbookInitEmpty());
  }
  private handler: ChannelHandler<Channel> = (message) => {
    if (!message || !message.data) return;

    const { command, data } = message;
    if (command === 'init') {
      const orderbookData = data as unknown as Pick<Orderbook, 'pricePrecision' | 'sizePrecision'> & {
        bids: RawOrderbookEntry[],
        asks: RawOrderbookEntry[],
      };
      const orderbook: Orderbook = {
        ...orderbookData,
        bids: orderbookData.bids.map(parse),
        asks: orderbookData.asks.map(parse),
      };
      Services.get('store').dispatch(orderbookInit(orderbook));
      return;
    }

    const messageData = (data as unknown as RawOrderbookEntry[]).map(parse);
    if (command === 'bids') {
      Services.get('store').dispatch(bidsAdd(messageData));
    }

    if (command === 'asks') {
      Services.get('store').dispatch(asksAdd(messageData));
    }
  };

  unsubscribe() {
    Services.get('cable').unsubscribe({ channel });
  }
}

export default OrderbookService;
