import { CoinCode, Market } from 'types';

const SEPARATOR = '-';

export const createMarketName = (left: CoinCode, right: CoinCode, isReversed = false) => {
  let codes = [left.toUpperCase(), right.toUpperCase()];
  if (isReversed) {
    codes = codes.reverse();
  }

  return codes.join(SEPARATOR);
};

export const parseMarketName = (marketName: Market['name']): { left: CoinCode, right: CoinCode } => {
  const [left = CoinCode.EMPTY, right = CoinCode.EMPTY] = marketName.toLowerCase().split(SEPARATOR);
  return { left: left as CoinCode, right: right as CoinCode };
};
