import React from 'react';
import classnames from 'classnames';
import { FiatCurrency } from 'types';
import Select from 'components/Select';
import { get } from 'helpers/translate';

import Icon from 'components/Icon';
import Text, { Caption } from 'components/Text';

import styles from './FiatCurrencyPicker.module.scss';

type SelectProps = GCP<Select<FiatCurrency>>;
type Props = {
  currencies: FiatCurrency[],
  current: FiatCurrency['code'],
  onChange: (code: FiatCurrency['code']) => void,
  fetch: () => void,
};

class FiatCurrencySelect extends React.Component<Required<Props>> {
  componentDidMount() {
    if (this.props.currencies.length) return;

    this.props.fetch();
  }

  private onChange: SelectProps['onChange'] = (item) => this.props.onChange(item.code);

  render() {
    const { current, currencies } = this.props;
    if (!currencies.length) return null;

    return (
      <Select<FiatCurrency>
        className={ classnames('col fiat__picker btn__shadow', styles.scrollbar) }
        title={ get('common.label.currency') }
        items={ currencies }
        selected={ current }
        search={ {
          enabled: true,
          filterBy: ({ code, name }) => [code, name].join(''),
          placeholder: get('common.label.search'),
        } }
        keyParam="code"
        onChange={ this.onChange }
        renderSelected={ this.renderSelected }
        renderItem={ this.renderItem }
      />
    );
  }
  private renderSelected: SelectProps['renderSelected'] = (item) => {
    if (!item) return null;

    return (
      <div className={ styles.inner }>
        <div className={ styles.icon }>
          <Icon.FiatFlag name={ item.code } />
        </div>
        <Text weight="medium" className="text-clip">
          { item.name }
        </Text>
        <div className={ styles.chevron }>
          <Icon.Normal name="chevron-down" size="x-small" />
        </div>
      </div>
    );
  };
  private renderItem: SelectProps['renderItem'] = ({ isVisible, item, key, onClick }) => {
    if (!isVisible) return null;

    return (
      <li key={ key } onClick={ () => onClick() }>
        <Icon.FiatFlag name={ item.code } />
        <Text weight="medium" type="small">
          { item.code.toUpperCase() }
        </Text>
        <Caption color="dark-light" className="ml-2">
          { item.name }
        </Caption>
      </li>
    );
  };
}

export default FiatCurrencySelect;
