export enum CoinCode {
  EMPTY = '',

  // fiat
  EUR = 'eur',

  // coins
  ADA = 'ada',
  BCH = 'bch',
  BTC = 'btc',
  EOS = 'eos',
  ETH = 'eth',
  IOTA = 'iota',
  LTC = 'ltc',
  XRP = 'xrp',
  GRNOLD = 'grnold',
  GRN = 'grn',
  IOT = 'iot',
  USDT = 'usdt',
  XD = 'xd',
  TRX = 'trx',
  DOGE = 'doge',
  ECA = 'eca'
}
