import React from 'react';
import { CardProvider, IconName } from 'types';
import NormalIcon from './Normal';
import InlinedIcon from './Inlined';

type Props = {
  provider: CardProvider | null,
} & Omit<GetComponentProps<typeof NormalIcon>, 'name'>;

const PROVIDER_ICON_MAP: Record<CardProvider, IconName> = {
  mastercard: 'mastercard',
  mastercarddebit: 'mastercard',
  visa: 'visa',
  vpay: 'visa',
  delta: 'visa',
};

const CardIcon: React.FC<Props> = ({ provider, ...props }: FunctionProps<Props>) => {
  if (!provider || !PROVIDER_ICON_MAP.hasOwnProperty(provider)) {
    return <InlinedIcon name="credit-card" color="dark-light" { ...props } />;
  }

  return (
    <NormalIcon
      { ...props }
      name={ PROVIDER_ICON_MAP[provider] }
    />
  );
};

export default CardIcon;
