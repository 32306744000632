import { createTyped } from 'utils';
import { AsyncAction, Coin, StandardResponse, CoinCode } from 'types';
import Services from 'services';
import { ActionsPayload } from './types';
import { getCoinsList, getDelistedCoinsList } from './selectors';

const createTypedAction = createTyped<ActionsPayload>();

export const coinsSet = createTypedAction('coins/SET');
export const coinsDelistedSet = createTypedAction('coins/DELISTED_SET');

const coinsFetch = (): AsyncAction<Promise<Coin[]>> =>
  async (dispatch) => {
    const data = await Services.get('api').get<Coin[]>('v2/coins');
    dispatch(coinsSet(data));
    return data;
  };

export const coinsGet = (): AsyncAction<void> =>
  (dispatch, getState) => {
    const coins = getCoinsList(getState());
    if (coins && coins.length) return;

    dispatch(coinsFetch());
  };

export const coinUpdate = createTypedAction('coin/UPDATE');
export const coinSetIsStarred = (code: CoinCode, isStarred: boolean): AsyncAction<Promise<void>> =>
  async (dispatch) => {
    dispatch(coinUpdate({ code, isStarred }));
    await Services.get('api').post<StandardResponse>('user/toggle_fav_currency', { currencyCode: code });
  };


const coinsDelistedFetch = (): AsyncAction<Promise<Coin[]>> =>
  async (dispatch) => {
    const data = await Services.get('api').get<Coin[]>('v2/coins/delisted');
    dispatch(coinsDelistedSet(data));
    return data;
  };

export const coinsDelistedGet = (): AsyncAction<void> =>
  (dispatch, getState) => {
    const coins = getDelistedCoinsList(getState());
    if (coins && coins.length) return;

    dispatch(coinsDelistedFetch());
  };
