import { createSelector } from 'reselect';
import { StoreState } from '../index';

const getState = (state: StoreState) => state.userStore || {};

export const getUser = createSelector(
  [getState],
  (state) => state.user,
);

export const isUserChecked = createSelector(
  [getState],
  (state) => state.isChecked,
);

export const isLoggedIn = createSelector(
  [getUser],
  (user) => user !== null,
);
export const isUserVerified = createSelector(
  [getUser],
  (user) => user && user.ifVerified,
);
export const canUserOperate = createSelector(
  [getUser],
  (user) => user && user.canOperate,
);
export const hasUserCountryStateUpdated = createSelector(
  [getUser],
  (user) => user !== null
    && typeof user.country !== undefined
    && typeof user.state !== undefined
    && user.country !== null,
);
export const is2faEnabled = createSelector(
  [getUser],
  (user) => Boolean(user && user.otpRequiredForLogin),
);
export const isUserAdmin = createSelector(
  [getState],
  (state) => state.admin !== null && state.admin,
);

export const getUserFee = createSelector(
  [getState],
  (state) => state.fee,
);
export const getUserReference = createSelector(
  [getUser],
  (user) => user ? user.reference : '',
);
export const getUserEmail = createSelector(
  [getUser],
  (user) => user ? user.email : '',
);
export const getUsername = createSelector(
  [getUser],
  (user) => {
    if (!user) return '';

    return user.fullName.length ? user.fullName : user.email;
  },
);

export const hasUserEmailConfirmed = createSelector(
  [getState],
  (state) => state.hasVerifiedEmail,
);
export const hasUserTrades = createSelector(
  [getState],
  (state) => state.hasTrades,
);
export const hasPaymentsSetup = createSelector(
  [getState],
  (state) => state.hasPaymentMethod,
);
export const hasCardsEnabled = createSelector(
  [getState],
  (state) => state.hasCardsEnabled,
);
export const getChatUsername = createSelector(
  [getState],
  (state) => state.chatUsername,
);
