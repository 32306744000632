import { AsyncAction, User } from 'types';
import { createTyped, pick, delay } from 'utils';
import { fiatCurrencySet } from 'store/fiat';
import Services from 'services';
import { ActionsPayload } from './types';

const createTypedAction = createTyped<ActionsPayload>();

export const userFeeSet = createTypedAction('user/FEE_SET');
export const userInfoSet = createTypedAction('user/INFO_SET');
export const userChecked = createTypedAction('user/CHECKED');


export const userUpdated = createTypedAction('user/UPDATED');
export const userFetch = (): AsyncAction<Promise<User | null>> =>
  async (dispatch) => {
    try {
      const data = await Services.get('user').fetch();
      dispatch(fiatCurrencySet({
        rate: data.fiatRate,
        code: data.user ? data.user.currencyCode : 'eur',
      }));
      dispatch(userFeeSet(data.fee));
      dispatch(userInfoSet(pick(data,
        ['chatUsername', 'admin', 'hasTrades', 'hasPaymentMethod', 'hasVerifiedEmail', 'hasCardsEnabled'])));

      if (!data.user) return null;
      dispatch(userUpdated(data.user));
      Services.get('events').emit('LOGGED_IN', data.user);

      return data.user;
    } catch (e) {
      return null;
    } finally {
      dispatch(userChecked(true));
    }
  };


export const userLogoutSuccess = createTypedAction('user/LOGGED_OUT');
export const userLogout = (): AsyncAction<Promise<void>> =>
  async (dispatch) => {
    await Services.get('auth').logout();
    Services.get('events').emit('LOGGED_OUT');
    await delay(200);
    dispatch(userLogoutSuccess());

    window.location.pathname = '/';
  };
