import { StandardResponse, ApiAccess, PaginationResult } from 'types';
import Services from 'services';

class ApiAccessService {
  async fetch(page = 1) {
    type Response = StandardResponse<PaginationResult<ApiAccess>>;
    const result = await Services.get('api').get<Response>('settings/api-access/data', {
      page,
    });
    if (!result.success) throw new Error(result.message);

    return result;
  }

  async create(otpAttempt?: string) {
    type Response = StandardResponse<{ currentStep: '2factor-required', data: ApiAccess }>;
    const result = await Services.get('api').post<Response>('user/api_accesses', { user: { otpAttempt } });
    if (!result.success) throw new Error(result.message);
    if (result.currentStep === '2factor-required') throw new Error('2FA_REQUIRED');

    return result.data;
  }

  async update(id: ApiAccess['apiKey'], data: Partial<ApiAccess>) {
    type Response = StandardResponse<{ data: ApiAccess }>;
    const result = await Services.get('api').put<Response>(`user/api_accesses/${ id }`, data);
    if (!result.success) throw new Error(result.message);

    return result.data;
  }

  async remove(key: ApiAccess['apiKey']) {
    const result = await Services.get('api').del<StandardResponse>(`user/api_accesses/${ key }`);
    if (!result.success) throw new Error(result.message);
  }
}

export default ApiAccessService;
