import memoize from 'lodash/memoize';
import { FIAT_SORT_ORDER } from 'constants/coins';
import { createSelector } from 'reselect';
import { Coin, CoinCode } from 'types';
import { toNumber, floorWithPrecision } from 'helpers/number';
import { getCoinByCode } from 'store/coins/selectors';
import { getLatestMarketPrice } from 'store/markets/selectors';
import { sortAlphabetically } from 'helpers/sort';
import { StoreState } from '../index';

const fiatSortOrderReversed = FIAT_SORT_ORDER.slice(0).reverse();
const getState = (state: StoreState) => state.fiatStore || {};

export const getFiatCurrencies = createSelector(
  [getState],
  (state) => state.list,
);
export const getFiatCurrenciesSorted = createSelector(
  [getFiatCurrencies],
  (list) => list
    .sort((a, b) => sortAlphabetically(a.code, b.code))
    .sort((a, b) => (
      fiatSortOrderReversed.indexOf(b.code) - fiatSortOrderReversed.indexOf(a.code)
    )),
);

export const getCurrentFiat = createSelector(
  [getState],
  (state) => state.current,
);
export const getCurrentFiatRate = createSelector(
  [getState],
  (state) => state.rate ? parseFloat(state.rate) : 1,
);


export const getCoinEuroPrice = createSelector(
  [getCoinByCode],
  (byCode) => memoize((code: CoinCode) => {
    const coin = byCode(code);
    if (!coin) return 0;

    return parseFloat(coin.euroPrice);
  }),
);
export const getLatestCoinEuroPrice = createSelector(
  [getLatestMarketPrice, getCoinEuroPrice],
  (getLatestPrice, getEuroPrice) => memoize((code: CoinCode) => {
    const eurPrice = getLatestPrice(code, CoinCode.EUR);
    if (eurPrice !== 0) return eurPrice;

    const btcPrice = getLatestPrice(code, CoinCode.BTC);
    if (btcPrice !== 0) {
      const btcEuroPrice = getLatestPrice(CoinCode.BTC, CoinCode.EUR);
      if (btcEuroPrice !== 0) return btcPrice * btcEuroPrice;
    }

    return getEuroPrice(code);
  }),
);


export const getCoinEuroValue = createSelector(
  [getLatestCoinEuroPrice],
  (byCode) => (code: CoinCode, value: number) => floorWithPrecision(byCode(code) * value),
);



export const getValueInFiatCurrency = createSelector(
  [getCurrentFiatRate],
  (rate) => (value: number | string) => toNumber(value) * rate,
);
export const getFiatValueInCoin = createSelector(
  [getCurrentFiatRate, getLatestCoinEuroPrice],
  (rate, getEuroPrice) => (code: CoinCode, value: number | string) => {
    const coinEuroPrice = getEuroPrice(code);
    if (rate === 0 || coinEuroPrice === 0) return 0;

    const euroValue = toNumber(value) / rate;
    return euroValue / coinEuroPrice;
  },
);

export const getCurrentFiatCoin = createSelector(
  [getCurrentFiat],
  (code) => {
    const coin = {
      code,
      precision: 2,
      displayDecimals: 2,
      displayUnit: code,
    };

    return coin as Coin;
  },
);

export const getCoinFiatPrice = createSelector(
  [getLatestCoinEuroPrice, getValueInFiatCurrency],
  (getPrice, getFiat) => memoize((code: CoinCode) => {
    const price = getPrice(code);
    return getFiat(price);
  }),
);

export const getFiatValueInEuro = createSelector(
  [getCurrentFiatRate],
  (rate) => (value: number) => value / rate,
);
