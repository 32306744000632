import { sortAlphabetically } from 'helpers/sort';
import Services from 'services';
import { Continent, Country, CountryDetails, Location } from 'types';

class GeoService {
  getRegions() {
    return Services.get('api').get<Continent[]>('v2/regions');
  }

  async getCountry(country: Country) {
    const url = `v2/regions/${ country.parentCode }/${ country.code }`;
    const details = await Services.get('api').get<CountryDetails>(url);
    details.subRegions = details.subRegions.sort((a, b) => sortAlphabetically(a.name, b.name));

    return details;
  }
  async getCountries() {
    const continents = await this.getRegions();
    const countries = continents.reduce((result, continent) => {
      if (!continent.subRegions) return result;

      return result.concat(continent.subRegions);
    }, [] as Country[]);

    const COUNTRIES = {
      at: 'Austria',
      be: 'Belgium',
      bg: 'Bulgaria',
      cy: 'Cyprus',
      cz: 'Czech Republic',
      dk: 'Denmark',
      ee: 'Estonia',
      fi: 'Finland',
      fr: 'France',
      hu: 'Hungary',
      is: 'Iceland',
      it: 'Italy',
      lv: 'Latvia',
      li: 'Liechtenstein',
      lu: 'Luxembourg',
      nl: 'Netherlands',
      no: 'Norway',
      pt: 'Portugal',
      ro: 'Romania',
      sk: 'Slovakia',
      si: 'Slovenia',
      es: 'Spain',
      se: 'Sweden',
      ch: 'Switzerland'
    };

    const countryCodes = Object.keys(COUNTRIES);
    const filteredCountries = countries.filter((country) => countryCodes.includes(country.code.toLowerCase()));

    filteredCountries.sort((a, b) => sortAlphabetically(a.name, b.name));
    return filteredCountries;
  }

  getLocation() {
    return Services.get('api').get<Location>('v2/location');
  }
}

export default GeoService;
