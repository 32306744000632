import { createReducer, updateAtIndex } from 'utils';
import { Coin } from 'types';
import { ActionsPayload } from './types';

export type State = Readonly<{
  list: Coin[],
  delisted: Coin[],
}>;

export const initialState: State = {
  list: [],
  delisted: [],
};


const coinsReducer = createReducer<State, ActionsPayload>(
  initialState,
  {
    'coins/SET': (state, { payload }) => ({
      ...state,
      list: payload,
    }),
    'coins/DELISTED_SET': (state, { payload }) => ({
      ...state,
      delisted: payload,
    }),
    'coin/UPDATE': (state, { payload }) => {
      const index = state.list.findIndex(({ code }) => code === payload.code);
      if (index === -1) return state;

      const list = [...state.list];
      updateAtIndex(list, index, payload);

      return {
        ...state,
        list,
      };
    },
  },
);

export default coinsReducer;
