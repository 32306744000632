import { compose, createStore, applyMiddleware, combineReducers, Store } from 'redux';
import thunkMiddleware from 'redux-thunk';

export default <S>(reducers: any, initialState: S): Store<S> => {
  const reducer = combineReducers(reducers);
  const middlewares = [
    thunkMiddleware,
  ];

  if (__DEV__ || __STAGING__) {
    middlewares.push(require('utils/redux-logger').reduxLogger);
  }

  const composedStore = compose(
    applyMiddleware(...middlewares),
  );

  if (__DEV__ || __STAGING__) {
    // eslint-disable-next-line no-console
    console.log('[REDUX]', 'initialState', require('utils/redux-logger').stateTransformer(initialState));
  }

  // eslint-disable-next-line
  // @ts-ignore
  return composedStore(createStore)(
    reducer,
    initialState,
  );
};

