import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUser, userLogout } from 'store/user';

import HeaderAuthenticated from './Authenticated';

type Props = {
};

const HeaderAuthenticatedContainer: React.FC<Props> = (_props: FunctionProps<Props>) => {
  const user = useSelector(getUser);
  const dispatch = useDispatch();
  const logout = useCallback(() => dispatch(userLogout()), [dispatch]);
  return <HeaderAuthenticated { ...{ user, logout } } />;
};

export default HeaderAuthenticatedContainer;
