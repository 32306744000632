import { History } from 'history';
import Services from 'services';
import { DEPOSIT_CARD } from 'constants/routes';
import { getTranslation } from 'components/Translate';
import { modalShow } from 'store/ui';

import { CardDepositCallback } from '../types';

export const handleDeposit = (result: CardDepositCallback, history: History) => {
  const isSuccess = result.status && result.deposit;
  if (isSuccess) onSuccess(result);
  else onFail(history);
};

function onSuccess(result: CardDepositCallback) {
  Services.get('transfers').deposits.showNotification(result.deposit);
}
function onFail(history: History) {
  Services.get('store').dispatch(
    modalShow('deposit-card', {
      custom: {
        error: getTranslation('card.error.deposit'),
      },
    }));
  history.push(DEPOSIT_CARD);
}
