import React from 'react';
import { LoadingComponentProps } from 'react-loadable';
import Alert from 'components/Alert';
import Button from 'components/Button';
import CenteredLoader from '../Centered';

const LoadableLoader: React.FC<LoadingComponentProps> =
  ({ error, pastDelay }) => {
    if (error) {
      const HASH = '#retry';
      // try to reload once (if didnt help then show error)
      if (window.location.hash !== HASH) {
        window.location.hash = HASH;
        window.location.reload(true);
        return null;
      }

      const handle = () => window.location.reload(true);
      return (
        <div>
          <Alert theme="error" isCloseable={ false }>
            Please refresh the page.
          </Alert>
          <Button
            className="mt-4"
            theme="primary"
            onClick={ handle }
          >
            Refresh
          </Button>
        </div>
      );
    } else if (pastDelay) {
      return <CenteredLoader />;
    } else {
      return null;
    }
  };

export default LoadableLoader;
