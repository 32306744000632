import { createSelector } from 'reselect';
import { PendingOrder } from 'types';
import { StoreState } from '../index';
import { getCoinByCode } from 'store/coins';
import { getCoinFiatPrice } from 'store/fiat';
import { getLatestMarketPrice, isMarketReversed } from 'store/markets';

const getState = (state: StoreState) => state.ordersStore || {};

export const getPendingOrders = createSelector(
  [getState],
  (state) => state.pending,
);

export const getPendingOrderData = createSelector(
  [getCoinByCode, getCoinFiatPrice, getLatestMarketPrice, isMarketReversed],
  (getCoin, getFiatPrice, getLatestPrice, checkIfReversed) => (order: PendingOrder) => {
    const leftCoin = getCoin(order.leftCurrency.code);
    const rightCoin = getCoin(order.rightCurrency.code);
    if (!leftCoin || !rightCoin) return null;

    let leftValue = parseFloat(order.amount);
    if (order.amountType === 'fiat') {
      const coinFiatPrice = getFiatPrice(leftCoin.code);
      if (coinFiatPrice !== 0) {
        leftValue /= coinFiatPrice;
      }
    }

    const isReversed = checkIfReversed(leftCoin.code, rightCoin.code);
    let rightValue = 0.0;
    if (!isReversed) {
      const marketPrice = getLatestPrice(leftCoin.code, rightCoin.code);
      rightValue = leftValue * marketPrice;
    } else {
      const marketPrice = getLatestPrice(rightCoin.code, leftCoin.code);
      rightValue = leftValue / marketPrice;
    }

    return {
      leftCoin,
      rightCoin,
      leftValue,
      rightValue,
    };
  },
);
