import { AsyncAction, Withdrawal, Deposit } from 'types';
import { createTyped } from 'utils';
import Services from 'services';
import { ActionsPayload } from './types';

const createTypedAction = createTyped<ActionsPayload>();

export const depositsSet = createTypedAction('transfers/DEPOSITS_SET');
export const depositsFetch = (): AsyncAction<Promise<Deposit[]>> =>
  async (dispatch) => {
    const data = await Services.get('transfers').deposits.fetch();
    dispatch(depositsSet(data));
    return data;
  };

export const withdrawalsSet = createTypedAction('transfers/WITHDRAWALS_SET');
export const withdrawalsFetch = (): AsyncAction<Promise<Withdrawal[]>> =>
  async (dispatch) => {
    const data = await Services.get('transfers').withdrawals.fetchAll();
    dispatch(withdrawalsSet(data));
    return data;
  };

export const withdrawalCancelled = createTypedAction('transfers/WITHDRAWAL_CANCEL');
export const withdrawalCancel = (id: Withdrawal['id']): AsyncAction<Promise<void>> =>
  async (dispatch) => {
    await Services.get('transfers').withdrawals.cancel(id);
    dispatch(withdrawalCancelled({ id }));
  };

export const withdrawalUpdated = createTypedAction('transfers/WITHDRAWAL_UPDATED');
export const depositUpdated = createTypedAction('transfers/DEPOSIT_UPDATED');
