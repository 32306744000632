import get from 'lodash/get';
import { SortOrder, CoinCode } from 'types';
import { CURRENCIES_ORDER } from 'config';
import { isFunction } from 'helpers/types';
import { isNaN, toNumber } from 'helpers/number';

export const sortAlphabetically = (a: string, b: string): number => {
  const first = a.toLowerCase();
  const second = b.toLowerCase();

  if (first < second) return -1;
  if (first > second) return 1;
  return 0;
};

const shouldSortAlphabetically = (value: string | number): value is string =>
  typeof value === 'string' && isNaN(value);

export const sort = <T>(
  list: T[],
  sortBy: keyof T | string | ((item: T) => string | number),
  order: SortOrder,
): T[] => {
  const result = [...list].sort((a, b) => {
    let valueA: string | number;
    let valueB: string | number;
    if (isFunction(sortBy)) {
      valueA = sortBy(a);
      valueB = sortBy(b);
    } else {
      valueA = get(a, sortBy as any);
      valueB = get(b, sortBy as any);
    }

    if (shouldSortAlphabetically(valueA) && shouldSortAlphabetically(valueB)) {
      return sortAlphabetically(valueA, valueB);
    }

    return toNumber(valueA) - toNumber(valueB);
  });

  if (order === SortOrder.DESC) {
    return result.reverse();
  }

  return result;
};


export const sortCoins = (a: CoinCode, b: CoinCode) => {
  const indexA = CURRENCIES_ORDER.indexOf(a);
  const indexB = CURRENCIES_ORDER.indexOf(b);

  if (indexA === -1 && indexB === -1) return sortAlphabetically(a, b);
  if (indexA === -1) return 1;
  if (indexB === -1) return -1;

  return indexA - indexB;
};
