import React from 'react';
import { isFunction } from 'helpers/types';

type Props = {
  onClickOutside: (e: MouseEvent | TouchEvent) => void,
  children: React.ReactChild | ((args: { containerRef: React.Ref<HTMLDivElement> }) => React.ReactNode),
};

class ClickOutside extends React.Component<Required<Props>> {
  containerRef = React.createRef<HTMLDivElement>();
  startScrollY = 0;

  componentDidMount() {
    document.addEventListener('touchstart', this.handleTouchStart, true);
    document.addEventListener('touchend', this.handle, true);
    document.addEventListener('click', this.handle, true);
  }
  componentWillUnmount() {
    document.removeEventListener('touchend', this.handle, true);
    document.removeEventListener('click', this.handle, true);
  }

  private handleTouchStart = () => {
    this.startScrollY = window.scrollY;
  };

  private handle = (event: MouseEvent | TouchEvent) => {
    if (this.containerRef.current && this.containerRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    // Prevent click outside when scrolling on touch screen
    if (event.type === 'touchend' && window.scrollY !== this.startScrollY) {
      return;
    }

    this.props.onClickOutside(event);
  };

  render() {
    const { children } = this.props;

    if (isFunction(children)) {
      return children({ containerRef: this.containerRef });
    }

    return <div ref={ this.containerRef }>{ children }</div>;
  }
}

export default ClickOutside;
