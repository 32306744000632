import React from 'react';
import { NavLink } from 'react-router-dom';
import { User } from 'types';
import { REFERRAL_COMMISION } from 'config';
import { DASHBOARD, REFERRAL_AUTHENTICATED, ADVANCED_VIEW } from 'constants/routes';
import { DASHBOARD_ROUTES } from 'routes/Dashboard/routes-group';

import Button from 'components/Button';
import Label from 'components/Label';
import { t } from 'components/Translate';
import MediaQuery from 'components/MediaQuery';
import AsyncRenderer from 'components/AsyncRenderer';

import styles from '../Header.module.scss';
import UserHeader from './User';

type Props = {
  user: User | null,
  logout: () => void,
};

const HeaderAuthenticated: React.FC<Props> = (props: FunctionProps<Props>) => (
  <>
    { props.user && props.user.canOperate &&
      <div className={ styles.menu }>
        <NavLink
          to={ DASHBOARD }
          isActive={ (_match, { pathname }) =>
            DASHBOARD_ROUTES.some((route) => pathname.includes(route.split('/')[1])) }
          activeClassName="is-link-active"
        >
          <Button theme="link">
            { t('common.link.trade') }
          </Button>
        </NavLink>
        <a href={ ADVANCED_VIEW }>
          <Button theme="link">
            { t('common.link.advanced') }
          </Button>
        </a>
        <NavLink to={ REFERRAL_AUTHENTICATED } activeClassName="is-link-active">
          <Button theme="link">
            { t('common.link.inviteFriends') }
            <Label theme="success">
              { t('common.link.earn', { value: REFERRAL_COMMISION }) }
            </Label>
          </Button>
        </NavLink>
      </div>
    }

    <AsyncRenderer>
      <MediaQuery>
        { (device) => <UserHeader { ...props } device={ device } /> }
      </MediaQuery>
    </AsyncRenderer>
  </>
);

export default HeaderAuthenticated;
