export const MODALS_ROOT_ID = 'js-modals-root';
export const APP_ROOT_ID = 'js-router-root';

export const MODAL_BLUR_CLASS = 'js-modal-blur';
export const OVERFLOW_CLASS = 'js-overflow-hidden';
export const MOBILE_MENU_OPEN_CLASS = 'js-menuopen-overflow';
export const HEADER_STICKY_CLASS = 'js-header-sticky';

export const BACKGROUND_GRAY_CLASS = 'js-background--gray-lightest';
export const BREAKPOINTS_MAP = {
  xl: 1256,
  lg: 992,
  md: 768,
  sm: 576,
  xs: 0,
};
export const BACKGROUND_WHITE_CLASS = 'js-background--white';
