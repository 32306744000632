import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import * as ROUTES from 'constants/routes';
import { BLOG } from 'constants/social';

import Text, {  } from 'components/Text';
import { t } from 'components/Translate';
import StandardLink from 'components/Link/Standard';

import styles from '../Header.module.scss';

type Props = {
  isActive: boolean,
  onHide: () => void,
};
const HeaderAnonymousCompany: React.FC<Props> = ({ isActive, onHide }) => (
  <div className={ classnames(styles.dropdown, { [styles.is__active]: isActive }) }>
    <Link to={ ROUTES.ABOUT } onClick={ onHide }>
      <Text type="normal" weight="medium">
        { t('common.link.aboutUs') }
      </Text>
    </Link>
    <StandardLink to={ BLOG } onClick={ onHide } openInNewTab={ true }>
      <Text type="normal" weight="medium">
        { t('common.link.blog') }
      </Text>
    </StandardLink>
    <StandardLink to={ ROUTES.SUPPORT } onClick={ onHide } openInNewTab={ true }>
      <Text type="normal" weight="medium">
        { t('common.link.support') }
      </Text>
    </StandardLink>
  </div>
);

export default HeaderAnonymousCompany;
