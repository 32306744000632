import React from 'react';
import classnames from 'classnames';
import styles from './Icon.module.scss';

export type CommonIconProps = {
  className?: string,
  size?: 'xx-small' | 'x-small' | 'small' | 'medium' | 'large' | 'x-large' | 'xx-large',
  hasShadow?: boolean,
  onClick?: () => void,
};

type Props = {
  src: string,
  srcSet?: string,
  alt: string,
  async?: boolean,
  style?: React.CSSProperties,
} & CommonIconProps;

const CommonIcon: React.FC<Props> = React.memo(({
  src, srcSet, alt, size = 'medium', hasShadow = false, className = '', async = false, onClick, style = {},
}: FunctionProps<Props>) => (
  <img
    className={ classnames(styles.icon, className, styles[size], { [styles.withShadow]: hasShadow }) }
    src={ src }
    srcSet={ srcSet }
    alt={ alt }
    onClick={ onClick && (() => onClick()) }
    decoding={ async ? 'async' : 'auto' }
    style={ style }
  />
));

export default CommonIcon;
