type UserColorType = { [key: string]: string } ;

// Assigning Color Names to Alphabates and numbers
const USER_COLORS = ['brown', 'blue', 'lavender', 'malibu', 'turquoise', 'geraldine'];
const rangeTo36 = Array(36).fill('').map((_, index) => index.toString(36));

export const USERNAME_COLOR_MAP: UserColorType = rangeTo36.reduce((
  accumulator: UserColorType,
  alphabateKey,
  index,
) => {
  const colorIndex = index % USER_COLORS.length; // Get Color index based on Alphabate/Number index
  // Assign color to alphabate
  accumulator[alphabateKey] = USER_COLORS[colorIndex];
  return accumulator;
}, {});
