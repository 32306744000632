import { Store } from 'store';
import Services from 'services';
import { configFetch } from 'store/config';

export default (store: Store) => {
  Services.get('store').setStore(store);
  Services.get('translations').init(store);
  Services.get('buySell').init();
  Services.get('tracking').init();
  Services.get('crisp').init();

  Services.get('store').dispatch(configFetch());
};
