import React from 'react';
import { connect } from 'react-redux';
import { StoreState, MapActionsObject } from 'types';
import { isLoggedIn, getFiatCurrenciesSorted, getCurrentFiat, isLocaleLoaded, getLocales,
  getCurrentLocale } from 'store/selectors';
import { fiatListGet, fiatCurrencyChange, localeChange, localesFetch } from 'store/actions';
import Footer from './Footer';

const select = (state: StoreState) => ({
  isAuthenticated: isLoggedIn(state),

  currencies: getFiatCurrenciesSorted(state),
  currencyCurrent: getCurrentFiat(state),

  isLocaleLoaded: isLocaleLoaded(state),
  locales: getLocales(state),
  localeCurrent: getCurrentLocale(state),
});

const mapActions = {
  currenciesFetch: fiatListGet,
  currencySet: fiatCurrencyChange,
  localeSet: localeChange,
  localesFetch,
};

type MappedProps = ReturnType<typeof select>;
type MappedActions = MapActionsObject<typeof mapActions>;

class FooterContainer extends React.Component<MappedProps & MappedActions> {
  render() {
    return <Footer { ...this.props } />;
  }
}

export default connect<MappedProps, typeof mapActions, {}, StoreState>(
  select,
  mapActions,
)(FooterContainer);

