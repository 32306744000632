import TwoFactorService from './two-factor';
import ApiAccessService from './api';
import NotificationsSettingsService from './notifications';
import SessionsService from './sessions';
import AccountActivityService from './account-activity';
import HistoryService from './history';

class SettingsService {
  twoFactor = new TwoFactorService();
  api = new ApiAccessService();
  notifications = new NotificationsSettingsService();
  sessions = new SessionsService();
  activity = new AccountActivityService();
  history = new HistoryService();
}

export default SettingsService;
