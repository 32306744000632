import React from 'react';
import classnames from 'classnames';
import { filter } from 'helpers/filter';
import { OVERFLOW_CLASS } from 'constants/ui';
import { isWidthXS, addClassToBody, removeClassFromBody } from 'helpers/ui';

import ClickOutside from 'components/ClickOutside';
import Scrollbars from 'components/Scrollbar';
import Text from 'components/Text';
import Icon from 'components/Icon';
import SearchInput from 'components/Input/Search';

import styles from './Select.module.scss';
import { delay } from 'utils';

export type Props<Item = { [key: string]: any }> = {
  className?: string,
  keyParam?: keyof Item,
  search?: {
    enabled: boolean,
    placeholder?: string,
    filterBy?: (item: Item) => string,
  },

  title?: React.ReactNode,
  bottomAddon?: React.ReactNode,

  items: Item[],
  selected: string | null,
  onChange: (item: Item, hide: () => void) => void,

  renderItem: (data: {
    key: string,
    item: Item,
    isVisible: boolean,
    index: number,
    onClick: () => void,
  }) => React.ReactNode,
  renderSelected: (item: Item | null) => React.ReactNode,
  classes?: {
    selector?: string,
    inner?: string,
  },
  direction?: 'top' | 'bottom',
  minHeight?: number,
};

type State = {
  isActive: boolean,
  isHidding: boolean,
  isVisible: boolean,
  query: string,
};

class Select<Item = { [key: string]: any }> extends React.PureComponent<Required<Props<Item>>, State> {
  static defaultProps: OptionalProps<Props> = {
    title: null,
    bottomAddon: null,
    className: '',
    search: {
      enabled: false,
    },
    keyParam: 'id',
    classes: {
      selector: '',
      inner: '',
    },
    direction: 'top',
  };
  state: State = {
    isActive: false,
    isHidding: false,
    isVisible: false,
    query: '',
  };

  show = () => {
    if (this.state.isActive || this.state.isHidding) return;
    if (isWidthXS()) {
      addClassToBody(OVERFLOW_CLASS);
    }
    this.setState({ isActive: true, isVisible: true });
  };
  private toggle = (e: React.MouseEvent<HTMLElement>) => {
    if (this.state.isActive) {
      this.hide();
    } else {
      this.show();
    }

    e.stopPropagation();
  };
  private contentClickHandler = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
  };
  hide = async () => {
    if (!this.state.isActive || this.state.isHidding) return;
    removeClassFromBody(OVERFLOW_CLASS);
    this.setState({ isActive: false, isHidding: true, query: '' });
    await delay(300);
    this.setState({ isVisible: false, isHidding: false });
  };
  private get isMobileViewVisible() {
    return this.state.isActive && isWidthXS();
  }

  private onChange = (item: Item) => {
    this.props.onChange(item, this.hide);
    this.hide();
  };

  private getItems(): Item[] {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    const filterMethod = (item: Item) => item[this.props.keyParam].toString();
    const filterBy = this.props.search.filterBy || filterMethod;
    return filter(this.props.items, this.state.query, filterBy);
  }

  render() {
    const {
      className, keyParam,
      renderSelected, selected, classes,
    } = this.props;
    const selectedItem = this.props.items.find((item) => (item[keyParam] as unknown) === selected) || null;
    const items = this.getItems();

    return (
      <>
        <ClickOutside
          onClickOutside={ this.hide }
        >
          <div
            className={ classnames(styles.select, styles.mobile, className) }
            onClick={ this.toggle }
          >
            <div className={ classnames(styles.selector, classes.selector) }>
              { renderSelected(selectedItem) }
            </div>
            { this.renderContent(items) }
          </div>
        </ClickOutside>
        { this.isMobileViewVisible && <div className={ styles.overlay } /> }
      </>
    );
  }
  private renderContent(items: Item[]) {
    const { isActive, isVisible } = this.state;
    const { keyParam, title, bottomAddon, renderItem, direction, classes, minHeight = 210 } = this.props;

    const innerClasses = classnames(
      styles.inner,
      classes.inner,
      {
        [styles.is__active]: isActive,
        [styles.bottom]: direction === 'bottom',
      },
    );

    return (
      <div
        className={ innerClasses }
        onClick={ this.contentClickHandler }
      >
        { title &&
          <Text type="normal" weight="medium">
            { title }
          </Text>
        }
        { this.renderSearch() }
        <Scrollbars style={ { height: minHeight } } className="custom-scrollbar">
          <ul className={ styles.list }>
            {
              items.map((item, index) => renderItem({
                key: item[keyParam] as unknown as string,
                onClick: () => this.onChange(item),
                isVisible,
                index,
                item,
              }))
            }
          </ul>
        </Scrollbars>
        { bottomAddon }
        { this.isMobileViewVisible &&
          <span
            className={ styles.close }
            onClick={ this.hide }
          >
            <Icon.Inlined
              name="x"
              color="dark-light"
              size="small"
            />
          </span>
        }
      </div>
    );
  }
  private renderSearch() {
    const { enabled, placeholder } = this.props.search;
    if (!enabled) return null;

    return (
      <div className={ styles.search }>
        <SearchInput
          query={ this.state.query }
          placeholder={ placeholder }
          onChange={ (query) => this.setState({ query }) }
        />
      </div>
    );
  }
}

export default Select;
