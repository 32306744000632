/* eslint-disable @typescript-eslint/unified-signatures */
import { EventName, EventsWithPayload, EventsWithoutPayload, CustomEvents } from 'types';
import debounce from 'lodash/debounce';
import EE from 'event-emitter';
import allOff from 'event-emitter/all-off';
import hasListeners from 'event-emitter/has-listeners';
import { KEY_ESC } from 'constants/keys';

type Handler = (...args: any[]) => void;

const emitter = EE();
class EventsService {
  constructor() {
    this.initGlobalEvents();
  }
  private initGlobalEvents() {
    window.addEventListener('beforeunload', () => this.emit('UNLOAD'), false);

    const resized = debounce(() => this.emit('RESIZE'), 200);
    window.addEventListener('resize', resized, false);

    document.addEventListener('keydown', (e) => {
      if (e.keyCode === KEY_ESC) this.emit('KEY_ESC_PRESSED');
    });
  }

  on<K extends EventsWithoutPayload>(event: K, handler: () => void): void;
  on<K extends EventsWithPayload>(event: K, handler: (arg: CustomEvents[K]) => void): void;
  on<K extends EventName>(event: K, handler: (arg: any) => void) {
    emitter.on(event, handler);
  }
  once<K extends EventsWithoutPayload>(event: K, handler: () => void): void;
  once<K extends EventsWithPayload>(event: K, handler: (arg: CustomEvents[K]) => void): void;
  once<K extends EventName>(event: K, handler: (arg: any) => void) {
    emitter.once(event, handler);
  }
  then<K extends EventName>(event: K): Promise<CustomEvents[K]> {
    return new Promise((resolve) => emitter.once(event, resolve));
  }

  off(event: EventName, handler: Handler | null = null) {
    if (handler === null) {
      (allOff as any)(emitter, event);
      return;
    }

    emitter.off(event, handler);
  }

  emit<K extends EventsWithoutPayload>(event: K): void;
  emit<K extends EventsWithPayload>(event: K, args: CustomEvents[K]): void;
  emit<K extends keyof CustomEvents>(event: K, ...args: any[]): void {
    emitter.emit(event, ...args);
  }


  hasListeners(event: EventName): boolean {
    return hasListeners(emitter, event);
  }
}

export default EventsService;
