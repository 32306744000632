import { Announcement, ChannelName, ChannelHandler } from 'types';
import Services from 'services';
import { announcementShow } from 'store/announcements';

const CHANNEL: ChannelName = 'AnnouncementsChannel';
type Channel = typeof CHANNEL;

class AnnouncementsService {
  fetch() {
    return Services.get('api').get<Announcement[]>('v2/notifications');
  }
  read(id: Announcement['id']) {
    return Services.get('api').get(`notification/read/${ id }`);
  }

  subscribe() {
    this.unsubscribe();
    return Services.get('cable').subscribe<Channel>({
      channel: CHANNEL,
      handler: this.handle,
    });
  }
  private handle: ChannelHandler<Channel> = (message) => {
    if (!message) return;

    const { data } = message;
    if (!data) return;
    Services.get('store').dispatch(announcementShow(data));
  };
  unsubscribe() {
    Services.get('cable').unsubscribe({ channel: CHANNEL });
  }
}

export default AnnouncementsService;
