import { IconName } from 'types';
import React from 'react';
import { ICONS } from 'constants/icons';
import { getAssetUrl } from 'helpers/assets';
import CommonIcon, { CommonIconProps } from './Common';

const getPath = (name: IconName) => getAssetUrl(`/icon/${ ICONS[name] }`);

type Props = {
  name: IconName,
} & CommonIconProps;

const NormalIcon: React.FC<Props> =
React.memo(({ name, ...props }: FunctionProps<Props>) => (
  <CommonIcon
    src={ getPath(name) }
    alt={ name }
    { ...props }
  />
));

export default NormalIcon;
