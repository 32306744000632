import { LocaleCode, AsyncAction, Locale } from 'types';
import { createTyped } from 'utils';
import Services from 'services';
import { ActionsPayload } from './types';

const createTypedAction = createTyped<ActionsPayload>();

export const localesSet = createTypedAction('locale/LIST_SET');
export const localesFetch = (): AsyncAction<Promise<Locale[]>> =>
  async (dispatch) => {
    const data = await Services.get('api').get<Locale[]>('v2/locales');
    dispatch(localesSet(data));
    return data;
  };

export const localeLoaded = createTypedAction('locale/UPDATED');
export const localeChanged = createTypedAction('locale/CHANGE');
export const localeChange = (data: LocaleCode): AsyncAction<Promise<void>> =>
  async (dispatch) => {
    await Services.get('translations').changeLocale(data);
    dispatch(localeChanged(data));
  };
