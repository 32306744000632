import { OrderType, OrderSide, OrderValueType, PaymentMethod } from 'types';

export const isBuy = (orderType: OrderType) => orderType === 'buy';
export const isOrderType = (orderType: string): orderType is OrderType => orderType === 'buy' || orderType === 'sell';
export const isLeftSide = (side: OrderSide) => side === 'left';
export const isTypeFiat = (type: OrderValueType) => type === 'fiat';
export const reverseValueType = (type: OrderValueType): OrderValueType =>
  isTypeFiat(type) ? 'current' : 'fiat';

export const isCardPayment = (method: PaymentMethod) => method.type === 'card';
