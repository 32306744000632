import { Card } from 'types';
import { createSelector } from 'reselect';
import { StoreState } from '../index';

const getState = (state: StoreState) => state.paymentsStore || {};

export const getBankAccounts = createSelector(
  [getState],
  (state) => state.bankAccounts,
);

export const getCards = createSelector(
  [getState],
  (state) => state.cards,
);
export const getCardsList = createSelector(
  [getCards],
  (cards) => cards || [],
);

export const getCardById = createSelector(
  [getCardsList],
  (cards) => (id: Card['id']) => cards.find((card) => card.id === id) || null,
);
export const hasCardById = createSelector(
  [getCardById],
  (getCard) => (id: Card['id']) => getCard(id) !== null,
);
