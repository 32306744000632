import { Deferred } from 'types';

export const delay = (duration = 150) =>
  new Promise<void>((resolve) => window.setTimeout(resolve, duration));

export const waitForRunning = <T>(request: () => Promise<T>): () => Promise<T> => {
  let running: Promise<T> | null = null;
  return () => {
    if (running === null) {
      running = request().finally(() => {
        running = null;
      });
    }

    return running;
  };
};

export const createDeferred = <T>(): { promise: Promise<T>, deferred: Deferred<T> } => {
  const deferred: Deferred<T> = {
    resolve: () => null,
    reject: () => null,
  };
  const promise = new Promise<T>((resolve, reject) => {
    deferred.resolve = resolve;
    deferred.reject = reject;
  });

  return {
    promise,
    deferred,
  };
};

export const pollAsync = <T>(
  poller: () => Promise<T>,
  shouldKeepRunning: (value: T, deferred: Deferred<T>) => boolean,
  delayDuration = 1000,
): Promise<T> => {
  const { promise, deferred } = createDeferred<T>();
  const poll = async () => {
    const result = await poller();
    if (!shouldKeepRunning(result, deferred)) return;

    await delay(delayDuration);
    poll();
  };

  poll();
  return promise;
};

export const repeatUntil = (
  run: () => any,
  delayDuration = 1000,
  initialDelay = 0,
): (() => void) => {
  let timerId: number | null = null;
  const handle = () => {
    run();
    timerId = window.setTimeout(handle, delayDuration);
  };

  if (initialDelay > 0) {
    timerId = window.setTimeout(handle, initialDelay);
  } else {
    handle();
  }

  return () => {
    if (timerId === null) return;
    window.clearTimeout(timerId);
    timerId = null;
  };
};
