import Normal from './Normal';
import Inlined from './Inlined';
import Coin from './Coin';
import Country from './Country';
import Flag from './Flag';
import FiatFlag from './FiatFlag';
import LocaleFlag from './LocaleFlag';
import Circled from './Circled';
import FiatCurrency from './FiatCurrency';
import Card from './Card';

export default {
  Normal,
  Inlined,
  Coin,
  Country,
  Circled,
  FiatCurrency,
  Card,
  Flag,
  FiatFlag,
  LocaleFlag,
};
