import { Orderbook } from 'types';
import { createReducer } from 'utils';
import { updateOrderbookItems } from 'helpers/orderbook';
import { ActionsPayload as UserActions } from 'store/user';
import { ActionsPayload } from './types';

export type State = Readonly<Omit<Orderbook, 'bids' | 'asks'> & {
  bids: Orderbook['bids'] | null,
  asks: Orderbook['asks'] | null,
  timestamp: number,
}>;

export const initialState: State = {
  bids: null,
  asks: null,
  sizePrecision: 2,
  pricePrecision: 2,
  timestamp: Date.now(),
};

type Actions = ActionsPayload & Pick<UserActions, 'user/LOGGED_OUT'>;
const orderbookReducer = createReducer<State, Actions>(
  initialState,
  {
    'orderbook/INIT': (_state, { payload }) => ({
      ...payload,
      timestamp: Date.now(),
    }),
    'orderbook/ASKS_ADD': (state, { payload }) => ({
      ...state,
      timestamp: Date.now(),
      asks: updateOrderbookItems(state.asks || [], payload, 'asks'),
    }),
    'orderbook/BIDS_ADD': (state, { payload }) => ({
      ...state,
      timestamp: Date.now(),
      bids: updateOrderbookItems(state.bids || [], payload, 'bids'),
    }),
    'orderbook/INIT_EMPTY': (state) => {
      if (state.bids !== null || state.asks !== null) return state;

      return {
        ...state,
        bids: [],
        asks: [],
        timestamp: Date.now(),
      };
    },
    'orderbook/RESET': () => ({
      ...initialState,
    }),
    'user/LOGGED_OUT': () => ({
      ...initialState,
    }),
  },
);

export default orderbookReducer;
