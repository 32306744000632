import { createReducer } from 'utils';
import { ActionsPayload } from './types';
import { PaymentMethodConfig } from 'types';

export type State = Readonly<{
  orderMinAmount: number,

  card: PaymentMethodConfig,
}>;

export const initialState: State = {
  orderMinAmount: 1,

  card: {
    name: 'Card',
    enabled: true,
    minAmount: 5,
    feePercent: 1.99,
    feeFixed: 0,
    feeMin: 0,
  },
};

const referralReducer = createReducer<State, ActionsPayload>(
  initialState,
  {
    'config/SET': (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
);


export default referralReducer;
