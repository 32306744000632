import React from 'react';
import { connect } from 'react-redux';
import Loadable from 'react-loadable';

import { MapActionsObject, StoreState } from 'types';
import { history } from 'helpers/router';
import { APP_ROOT_ID } from 'constants/ui';
import { UPDATE_COUNTRY } from 'constants/routes';
import { isLoggedIn, canUserOperate, hasUserCountryStateUpdated } from 'store/selectors';

import { MediaQueryRenderer } from 'components/MediaQuery';
import AsyncRenderer from 'components/AsyncRenderer';
import Header from './Header';
import Footer from './Footer';
import NotificationsContainer from './Notifications';

const ChatboxContainer = Loadable({
  loader: () => import(/* webpackChunkName: "chatbox" */ './Chatbox'),
  loading: () => null,
});

const select = (state: StoreState) => ({
  isAuthenticated: isLoggedIn(state),
  canOperate: canUserOperate(state),
  hasCountryStateUpdated: hasUserCountryStateUpdated(state),
});

const mapActions = {
};

type MappedProps = ReturnType<typeof select>;
type MappedActions = MapActionsObject<typeof mapActions>;

class App extends React.Component<MappedProps & MappedActions> {
  componentDidMount() {
    if (
      this.props.isAuthenticated &&
      !this.props.hasCountryStateUpdated &&
      window.location.pathname !== UPDATE_COUNTRY
    ) {
      history.push(UPDATE_COUNTRY);
    }
  }
  render() {
    const { isAuthenticated, children, canOperate } = this.props;

    return (
      <>
        <div id={ APP_ROOT_ID } className="app">
          <Header />
          <div className="app__content">
            { children }
          </div>
          <Footer />
        </div>
        { isAuthenticated && canOperate &&
          <>
            <NotificationsContainer />
            <MediaQueryRenderer up="sm">
              { /* naive way of defering Chatbox rendering
               TODO: use more complex solution based on other components rendering state */ }
              <AsyncRenderer delay={ 3000 }>
                <ChatboxContainer />
              </AsyncRenderer>
            </MediaQueryRenderer>
          </>
        }
      </>
    );
  }
}

export default connect<MappedProps, typeof mapActions, {}, StoreState>(
  select,
  mapActions,
)(App);
