/* eslint-disable max-len */

import React from 'react';
import { TextWeight, TextColor } from 'types';

type Props = {
  tag?: keyof React.ReactHTML,
  type?: 'normal' | 'small',
  weight?: Exclude<TextWeight, 'bold'>,
  color?: TextColor,
  className?: string,
  title?: string,
  onClick?: () => void,
};
const Text: React.FC<Props> =
({ tag = 'span', type = 'normal', weight = 'regular', color = 'black', className = '', title = '', onClick, children }: FunctionProps<Props>) => {
  const classNames = `text text--${ type } text-weight--${ weight } text-color--${ color } ${ className }`;
  return React.createElement(tag, {
    className: classNames,
    title,
    onClick: onClick && (() => onClick()),
  }, children);
};

export default Text;
