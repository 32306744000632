import { ModalName, ModalConfig } from 'types';
import { createReducer } from 'utils';
import { ActionsPayload } from './types';

export type State = Readonly<{
  modalsActive: ModalName[],
  modalsData: Partial<Record<ModalName, ModalConfig>>,
  isOverlayActive: boolean,
}>;

export const initialState: State = {
  modalsActive: [],
  modalsData: {},
  isOverlayActive: false,
};


const uiReducer = createReducer<State, ActionsPayload>(
  initialState,
  {
    'ui/MODAL_SHOW': (state, { payload }) => ({
      ...state,
      modalsActive: [...state.modalsActive, payload.name],
      modalsData: { ...state.modalsData, [payload.name]: payload.config },
    }),
    'ui/MODAL_HIDE': (state, { payload }) => {
      const { [payload]: value, ...modalsData } = state.modalsData;
      const hasAnyActive = !!state.modalsActive.length || !!Object.keys(state.modalsData).length;
      if (!hasAnyActive) return state;

      return {
        ...state,
        modalsActive: state.modalsActive.filter((name) => name !== payload),
        modalsData,
      };
    },
    'ui/OVERLAY_ACTIVE': (state, { payload }) => ({
      ...state,
      isOverlayActive: payload,
    }),
  },
);

export default uiReducer;
