import { Locale } from 'types';
import { createReducer } from 'utils';
import { ActionsPayload } from './types';
import { detectLocale } from 'helpers/locale';

export type State = Readonly<{
  locales: Locale[],
  locale: Locale['code'],
  localeTimestamp: number,
}>;

export const initialState: State = {
  locales: [],
  locale: detectLocale(),
  localeTimestamp: -1,
};


const localeReducer = createReducer<State, ActionsPayload>(
  initialState,
  {
    'locale/CHANGE': (state, { payload }) => ({
      ...state,
      locale: payload,
    }),
    'locale/UPDATED': (state) => ({
      ...state,
      localeTimestamp: Date.now(),
    }),
    'locale/LIST_SET': (state, { payload }) => ({
      ...state,
      locales: payload,
    }),
  },
);

export default localeReducer;
