import { BankAccount, StandardResponse } from 'types';
import Services from 'services';

type AccountCreateParams = Pick<BankAccount, 'name' | 'iban' | 'bic'> & { type?: string };

export default class BankAccountService {
  async fetchAll(): Promise<BankAccount[]> {
    type Response = StandardResponse<{ accounts: BankAccount[] }>;
    const result = await Services.get('api').get<Response>('v2/bank_accounts');
    if (!result.success) throw new Error(result.message);

    return result.accounts;
  }
  async create(account: AccountCreateParams) {
    account.type = 'WithdrawAddress::Eur';
    type Response = StandardResponse<{ account: BankAccount }>;
    const result = await Services.get('api').post<Response>(
      'withdraw_addresses',
      // TODO: check with camelCase
      // eslint-disable-next-line @typescript-eslint/camelcase
      { withdraw_address_base: account });
    if (!result.success) throw new Error(result.message);

    return result.account;
  }
  async remove(id: BankAccount['id']): Promise<BankAccount[]> {
    type Response = StandardResponse<{ accounts: BankAccount[] }>;
    const result = await Services.get('api').del<Response>(`withdraw_addresses/${ id }?recaptcha_code=remove`);
    if (!result.success) throw new Error(result.message);

    return result.accounts;
  }
}
