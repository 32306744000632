/* eslint-disable @typescript-eslint/no-use-before-define */
import { CoinCode } from 'types';
import { History } from 'history';
import { isRoute } from 'helpers/router';
import Services from 'services';
import { delay } from 'utils';
import { SETTINGS_PAYMENTS, DEPOSIT_CARD, DASHBOARD, PAYMENT_METHOD } from 'constants/routes';
import { getTranslation } from 'components/Translate';
import { areBalancesFetched } from 'store/balances';
import { getCards } from 'store/payments';
import { buysellRightCodeChange, buysellPaymentMethodSet } from 'store/buysell';
import { modalShow } from 'store/ui';

import { CardAddCallback } from '../types';

export const handleAdd = (result: CardAddCallback, history: History) => {
  if (isSettings(history)) handleSettings(result);
  if (isDeposit(history)) handleDeposit(result, history);
  if (isDashboard(history)) handleDashboard(result, history);
};


const isSettings = (history: History) => isRoute(history.location.pathname, SETTINGS_PAYMENTS);
function handleSettings(result: CardAddCallback) {
  if (result.status) {
    Services.get('notifications').showSuccess({
      title: getTranslation('card.added.title'),
    });
  } else {
    Services.get('store').dispatch(modalShow('card-add', {
      custom: {
        error: getTranslation('card.error.3dcheck'),
      },
    }));
  }
}


const isDeposit = (history: History) => isRoute(history.location.pathname, DEPOSIT_CARD);
function handleDeposit(result: CardAddCallback, history: History) {
  let params = {};
  if (result.status) {
    if (result.card) params = { cardId: result.card.id };
  } else {
    params = { errorOnAddNew: getTranslation('card.error.3dcheck') };
  }

  history.replace(DEPOSIT_CARD, params);
}


const isDashboard = (history: History) => isRoute(history.location.pathname, DASHBOARD);
async function handleDashboard(result: CardAddCallback, history: History) {
  if (result.status) {
    await Services.get('store').when((state) => areBalancesFetched(state) && getCards(state) !== null);
    await delay(250);
    Services.get('store').dispatch(buysellRightCodeChange(CoinCode.EUR));
    if (result.card) {
      Services.get('store').dispatch(buysellPaymentMethodSet({ type: 'card', id: result.card.id }));
    }
  } else {
    history.push(PAYMENT_METHOD, { error: getTranslation('card.error.3dcheck') });
  }
}
