const YEAR = 365 * 24 * 60 * 60;

export const set = (key: string, value: string, path = '/') => {
  const cookie = `${ key }=${ value }; max-age=${ YEAR }; path=${ path };`;
  document.cookie = cookie;
};
export const remove = (key: string) => {
  document.cookie = `${ key }=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};
export const get = (key: string): string | null => {
  const match = new RegExp(`${ key }=([^;]+)`).exec(document.cookie);
  if (!match) return null;

  return match[1];
};
