import memoize from 'lodash/memoize';
import { RawBalance, Balance, TotalBalance, CoinCode } from 'types';
import { createSelector } from 'reselect';
import { getCoinByCode } from 'store/coins/selectors';
import { isSameDay } from 'helpers/time';
import { sortCoins } from 'helpers/sort';
import { isDefined } from 'helpers/types';
import { StoreState } from '../index';

const getState = (state: StoreState) => state.balancesStore || {};

const getBalancesImmutable = createSelector(
  [getState],
  (state) => state.balances,
);
export const areBalancesFetched = createSelector(
  [getBalancesImmutable],
  (balances) => balances !== null,
);
export const getBalances = createSelector(
  [getBalancesImmutable],
  (balances): RawBalance[] => (balances || []).map((balance) => {
    const result = {
      ...balance,
      code: balance.currencyCode,
      name: balance.currencyName,
    };

    delete result.currencyCode;
    delete result.currencyName;

    return result;
  }),
);
export const getBalancesWithCoin = createSelector(
  [getBalances, getCoinByCode],
  (balances, getCoin) => balances
    .map((balance) => {
      const coin = getCoin(balance.code);
      if (!coin) return null;

      return {
        balance,
        coin,
      };
    })
    .filter(isDefined),
);
export const getAvailableBalancesWithCoin = createSelector(
  [getBalancesWithCoin],
  (balances) => balances.filter(({ balance }) => parseFloat(balance.availableBalance) > 0),
);
export const getBalancesWithEurBtc = createSelector(
  [getBalances, getCoinByCode],
  (balances, getCoin) =>
    balances.map((balance): Balance => {
      const coin = getCoin(balance.code);
      const euroPrice = coin ? parseFloat(coin.euroPrice) : 0;
      const euroTotal = parseFloat(balance.balance) * euroPrice;
      const btcPrice = coin ? parseFloat(coin.btcPrice) : 0;
      const btcTotal = parseFloat(balance.balance) * btcPrice;

      return {
        ...balance,
        eur: euroTotal,
        btc: btcTotal,
        precision: coin ? coin.displayDecimals : 2,
      };
    }),
);

export const getAvailableBalanceCoinCodes = createSelector(
  [getBalances],
  (balances) => balances
    .filter((balance) => parseFloat(balance.availableBalance) > 0)
    .map(({ code }) => code)
    .sort(sortCoins),
);
export const getAvailableBalanceCoins = createSelector(
  [getAvailableBalanceCoinCodes, getCoinByCode],
  (codes, getCoin) => codes.map(getCoin).filter(isDefined),
);

export const getTotalBalance = createSelector(
  [getBalancesWithEurBtc],
  (balances) => {
    const initial: TotalBalance = {
      eur: 0,
      btc: 0,
    };
    return balances.reduce((result, { eur, btc }) => {
      result.eur += eur;
      result.btc += btc;

      return result;
    }, initial);
  },
);

export const getTotalEurBalance = createSelector(
  [getTotalBalance],
  (balance) => balance.eur || 0,
);

export const hasZeroBalance = createSelector(
  [getTotalEurBalance],
  (total) => total === 0,
);

export const getBalanceByCode = createSelector(
  [getBalancesWithEurBtc],
  (balances) => memoize((coinCode: CoinCode) =>
    balances.find(({ code }) => code === coinCode) || null),
);
export const getAvailableBalanceByCode = createSelector(
  [getBalanceByCode],
  (getBalance) => (code: CoinCode) => {
    const balance = getBalance(code);
    if (!balance) return 0;

    return parseFloat(balance.availableBalance);
  },
);

const getFundsChartRaw = createSelector(
  [getState],
  (state) => state.fundsChart,
);
export const getFundsChart = createSelector(
  [getFundsChartRaw, getTotalEurBalance],
  (raw, totalEur) => {
    if (!raw.length) return [];

    const series = [...raw];
    const now = Date.now();
    const lastDay = new Date(series[series.length - 1][0]);
    if (isSameDay(new Date(), lastDay)) {
      series[series.length - 1][1] = totalEur;
    } else {
      series.push([now, totalEur]);
    }

    return series;
  },
);
export const getTotalBalanceChange = createSelector(
  [getFundsChart, getTotalEurBalance],
  (walletWorth, totalBalance) => {
    if (walletWorth.length < 2) return 0;

    const current = totalBalance;
    const last = walletWorth[walletWorth.length - 2][1];

    if (last === 0) return 0;

    return ((current / last) * 100) - 100;
  },
);

export const hasAvailableBalance = createSelector(
  [getBalances],
  (balances) => balances.some(({ availableBalance }) => parseFloat(availableBalance) > 0),
);
