import { AsyncAction, StateBalance } from 'types';
import { createTyped, waitForRunning } from 'utils';
import Services from 'services';
import { ActionsPayload } from './types';
import { areBalancesFetched } from './selectors';

const createTypedAction = createTyped<ActionsPayload>();

export const balancesUpdated = createTypedAction('balances/UPDATED');
export const balancesSet = createTypedAction('balances/SET');

const fetch = waitForRunning(() => Services.get('api').get<StateBalance[]>('v2/balances'));
const balancesFetch = (): AsyncAction<Promise<void>> =>
  async (dispatch) => {
    const data = await fetch();
    dispatch(balancesSet(data));
  };

export const balancesGet = (): AsyncAction<void> =>
  (dispatch, getState) => {
    if (areBalancesFetched(getState())) return;

    dispatch(balancesFetch());
  };

export const balancesFundsChartSet = createTypedAction('balances/FUNDS_CHART_SET');
type BalancesFundsChartSet = (days: number) => AsyncAction<Promise<number[][]>>;
export const balancesFundsChartFetch: BalancesFundsChartSet = (days) =>
  async (dispatch) => {
    const data = await Services.get('balances').getFundsChartData(days);
    dispatch(balancesFundsChartSet(data));
    return data;
  };
