import { AsyncAction, Chat } from 'types';
import Services from 'services';
import { createTyped } from 'utils';
import { ActionsPayload } from './types';
import { getChatUsername, isUserAdmin } from 'store/selectors';

const createTypedAction = createTyped<ActionsPayload>();

export const chatboxInit = createTypedAction('chatbox/INIT');
export const addChatMessage = createTypedAction('chatbox/ADD_MESSAGE');
export const chatboxOnlineCount = createTypedAction('chatbox/ONLINE_COUNT');
export const deleteChatMessage = createTypedAction('chatbox/DELETE_MESSAGE');

export const sendChatMessage = (chatMessage: Chat['body']): AsyncAction<Promise<void>> =>
  async (dispatch) => {

    // Adding message to Chat for instant display
    const randomUniqId = Date.now().toString();
    dispatch(addChatMessage({
      id: randomUniqId,
      body: chatMessage,
      deleted: false,
      admin: Services.get('store').select(isUserAdmin) || false,
      chatUsername: Services.get('store').select(getChatUsername),
    }));

    try {
      await Services.get('chatbox').sendMessage(chatMessage);
    } catch (e) {
      dispatch(deleteChatMessage(randomUniqId));
      throw e;
    }
  };
