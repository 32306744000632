import React, { SyntheticEvent } from 'react';
import classnames from 'classnames';
import { Notification as NotificationType } from 'types';
import Text, { Subtitle } from 'components/Text';

import Icon from 'components/Icon';
import styles from './Notification.module.scss';
import { delay } from 'utils';

type Props = NotificationType & {
  close: () => void,
};
type State = {
  isVisible: boolean,
};

class Notification extends React.PureComponent<Required<Props>, State> {
  static defaultProps: OptionalProps<Props> = {
    coinCode: undefined,
    isProgressVisible: true,
    duration: 4 * 1000,
  };

  state: State = {
    isVisible: false,
  };

  async componentDidMount() {
    await delay(15);
    this.setState({ isVisible: true });
  }

  private hide = async (event?: SyntheticEvent) => {
    if (event) event.stopPropagation();
    this.setState({ isVisible: false });
    await delay(600);
    this.close();
  };
  private close = () => {
    this.props.close();
    if (this.props.onClose) this.props.onClose();
  };
  private onAction = () => {
    if (this.props.onClick) this.props.onClick();
    this.hide();
  };

  render() {
    const { icon, coinCode, title, description, isProgressVisible } = this.props;
    const classes = classnames(styles.notification, {
      [styles.isVisible]: this.state.isVisible,
    });

    return (
      <div
        className={ classes }
        onClick={ this.onAction }
      >
        <div className={ styles.content }>
          <div className={ classnames(styles.icon, 'cursor--pointer') }>
            <Icon.Circled color={ icon.color } name={ icon.name } size="normal" />
            { coinCode && <Icon.Coin code={ coinCode } size="x-small" /> }
          </div>
          <div className="align-self-center">
            <Subtitle type="small" weight="medium">
              { title }
            </Subtitle>
            { description && description.length &&
              <Text color="dark-light">
                { description }
              </Text>
            }
          </div>
        </div>
        <div onClick={ this.hide } className={ classnames(styles.close, 'cursor--pointer') }>
          <Icon.Inlined color="dark-light" size="x-small" name="x" />
        </div>
        <div
          className={ classnames(styles.progress, { [styles.hidden]: !isProgressVisible }) }
          style={ { animationDuration: `${ this.props.duration / 1000 }s` } }
          onAnimationEnd={ this.hide }
        />
      </div>
    );
  }
}

export default Notification;
