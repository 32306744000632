import { BankAccount, Card } from 'types';
import { createReducer } from 'utils';
import { ActionsPayload as UserActions } from 'store/user';
import { ActionsPayload } from './types';

export type State = Readonly<{
  cards: Card[] | null,
  bankAccounts: BankAccount[] | null,
}>;

export const initialState: State = {
  cards: null,
  bankAccounts: null,
};


const paymentsReducer = createReducer<State, ActionsPayload & Pick<UserActions, 'user/LOGGED_OUT'>>(
  initialState,
  {
    'payments/BANK_ACCOUNTS_SET': (state, { payload }) => ({
      ...state,
      bankAccounts: payload,
    }),
    'payments/BANK_ACCOUNT_ADD': (state, { payload }) => ({
      ...state,
      bankAccounts: [
        ...(state.bankAccounts || []),
        payload,
      ],
    }),
    'payments/BANK_ACCOUNT_REMOVE': (state, { payload }) => ({
      ...state,
      bankAccounts: (state.bankAccounts || []).filter(({ id }) => id !== payload),
    }),
    'payments/CARDS_SET': (state, { payload }) => ({
      ...state,
      cards: payload,
    }),
    'payments/CARD_ADD': (state, { payload }) => {
      const current = state.cards || [];
      // prevent adding same card multiple times
      if (current.some(({ id }) => id === payload.id)) return state;

      const cards = [...current, payload];
      return {
        ...state,
        cards,
      };
    },
    'payments/CARD_REMOVE': (state, { payload }) => ({
      ...state,
      cards: (state.cards || []).filter(({ id }) => id !== payload),
    }),
    'user/LOGGED_OUT': () => ({
      ...initialState,
    }),
  },
);

export default paymentsReducer;
