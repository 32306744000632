export const pick = <T, K extends keyof T>(obj: T, keys: K[]): Pick<T, K> =>
  Object.entries(obj).reduce((result, [key, value]) => {
    if (!keys.includes(key as K)) return result;

    // eslint-disable-next-line
    // @ts-ignore
    result[key] = value;
    return result;
  }, {}) as Pick<T, K>;

export const areEqualObjects = (a: object, b: object) => {
  const aKeys = Object.getOwnPropertyNames(a);
  const bKeys = Object.getOwnPropertyNames(b);

  if (aKeys.length !== bKeys.length) return false;

  // eslint-disable-next-line
  // @ts-ignore
  return aKeys.every((key) => a[key] === b[key]);
};
