import { createSelector } from 'reselect';
import { StoreState } from '../index';

const getState = (state: StoreState) => state.configStore || {};

export const getConfigObject = createSelector(
  [getState],
  (state) => state,
);
export const getConfig = createSelector(
  [getConfigObject],
  (config) => <T extends keyof StoreState['configStore']>(key: T)  => config[key],
);
