import Services from 'services';
import { User, Order } from 'types';

import MixpanelTracker from './mixpanel';
import GoogleAnalyticsTracker from './google-analytics';

export interface ITracker {
  onLogin(user: User): void;
  onOrderSuccess(order: Order): void;
  onLogout(): void;
}

class TrackingService {
  trackers: ITracker[] = [
    new GoogleAnalyticsTracker(),
    new MixpanelTracker(),
  ];

  init() {
    this.logRelease();

    Services.get('events').on('LOGGED_IN', this.handle('onLogin'));
    Services.get('events').on('LOGGED_OUT', this.handle('onLogout'));
    Services.get('events').on('BUY_SELL_ORDER_SUCCESS', this.handle('onOrderSuccess'));
  }

  private logRelease() {
    if (__DEV__) return;

    // eslint-disable-next-line no-console
    console.log(`[CoinFalcon] release hash: %c${ __COMMIT_HASH__ }`, 'font-weight: bold;');
  }
  private handle(name: keyof ITracker) {
    return (...data: any[]) =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      this.trackers.forEach((tracker) => tracker[name](...data));
  }
}

export default TrackingService;
