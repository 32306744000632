import { OrderType, StandardResponse, CoinCode, Market } from 'types';
import Services from 'services';

type ScaledOrderData = {
  orderType: OrderType,
  marketName: Market['name'],
  leftCurrencyCode: CoinCode,
};
type Entry = {
  price: number,
  size: number,
};

class ScaledOrder {
  async perform(
    data: Entry[],
    { orderType, marketName, leftCurrencyCode }: ScaledOrderData,
  ) {
    const orders = data.map(({ size, price }) => ({
      volume: size,
      price,
    }));

    const order = {
      orders,
      orderType,
      marketName,
      leftCurrencyCode,
      operationType: 'limit_order',
      sourceType: 'coinfalcon_trading',
      priceType: 'by_volume',
    };

    const result = await Services.get('api').post<StandardResponse>(
      'orders/create_order',
      { order },
    );

    if (!result.success) throw new Error(result.message);
    return result.success;
  }
}

export default ScaledOrder;
