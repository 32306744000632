import Text from './Text';

export { default as Heading } from './Heading';
export { default as Subtitle } from './Subtitle';
export { default as Caption } from './Caption';
export { default as Clipper } from './Clipper';

export {
  Text,
};
export default Text;
