import React, { SyntheticEvent, KeyboardEvent } from 'react';
import classNames from 'classnames';
import styles from './Input.module.scss';
import { isFunction } from 'helpers/types';
import { KEY_ENTER } from 'constants/keys';

type Props = {
  value?: string,
  onChange?: (value: string) => void,
  onEnter?: () => void,
  placeholder?: string,
  leftAddon?: React.ReactNode,
  rightAddon?: React.ReactNode,
  fullWidth?: boolean,
  disabled?: boolean,
  attrs?: Partial<React.InputHTMLAttributes<HTMLInputElement>>,
  size?: 'normal' | 'large',
  className?: string,
  theme?: 'normal' | 'dimmed',

  onFocus?: (e: SyntheticEvent<HTMLInputElement>) => void,
  onBlur?: (e: SyntheticEvent<HTMLInputElement>) => void,
  onMouseDown?: (e: SyntheticEvent<HTMLInputElement>) => void,
  onPaste?: (e: SyntheticEvent<HTMLInputElement>) => void,
};
export type InputProps = Props;

type State = {
  isFocused: boolean,
};

class Input extends React.Component<Required<Props>, State> {
  static defaultProps: OptionalProps<Props> = {
    placeholder: '',
    leftAddon: null,
    rightAddon: null,
    fullWidth: false,
    disabled: false,
    attrs: {},
    size: 'normal',
    theme: 'normal',
    className: '',
  };
  state: State = {
    isFocused: false,
  };
  input = React.createRef<HTMLInputElement>();

  private onFocus = (e: SyntheticEvent<HTMLInputElement>) => {
    this.setState({ isFocused: true });
    if (this.props.onFocus) this.props.onFocus(e);
  };
  private onBlur = (e: SyntheticEvent<HTMLInputElement>) => {
    this.setState({ isFocused: false });
    if (this.props.onBlur) this.props.onBlur(e);
  };
  private onChange = ({ target }: SyntheticEvent<HTMLInputElement>) => {
    if (!this.props.onChange) return;

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    this.props.onChange((target as HTMLInputElement).value);
  };
  private onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    const attrs = this.props.attrs;
    if (attrs.hasOwnProperty('onKeyDown') && isFunction(attrs.onKeyDown)) {
      attrs.onKeyDown(e);
    }

    if (e.keyCode === KEY_ENTER && isFunction(this.props.onEnter)) {
      this.props.onEnter();
    }
  };

  focus = () => {
    if (this.input.current) this.input.current.focus();
  };
  blur = () => {
    if (this.input.current) this.input.current.blur();
  };
  select = () => {
    if (this.input.current) this.input.current.select();
  };

  render() {
    const {
      placeholder,
      leftAddon,
      rightAddon,
      size,
      fullWidth,
      disabled,
      value,
      attrs,
      theme,
    } = this.props;

    const hasLeftAddon = leftAddon !== null;
    const hasRightAddon = rightAddon !== null;

    const className = classNames(
      styles.input,
      styles[size], {
        [styles.block]: fullWidth,
      },
    );

    return(
      <div
        className={ classNames(styles.wrapper, this.props.className, {
          [styles.withAddon]: hasLeftAddon || hasRightAddon,
          [styles.dimmed]: theme === 'dimmed',
        }) }
      >
        { hasLeftAddon &&
          <div className={ styles.leftAddon }>
            { leftAddon }
          </div>
        }
        <input
          ref={ this.input }
          type="text"
          className={ className }
          placeholder={ placeholder }
          value={ value }
          disabled={ disabled }
          onChange={ this.onChange }
          onFocus={ this.onFocus }
          onBlur={ this.onBlur }
          onKeyDown={ this.onKeyDown }
          onMouseDown={ this.props.onMouseDown }
          onPaste={ this.props.onPaste }
          { ...attrs }
        />
        { hasRightAddon &&
          <div className={ styles.rightAddon }>
            { rightAddon }
          </div>
        }
      </div>
    );
  }
}

export default Input;
