import { ModalName } from 'types';
import { createSelector } from 'reselect';
import { StoreState } from '../index';

const getState = (state: StoreState) => state.uiStore || {};

export const isModalActive = createSelector(
  [getState],
  (state) => (name: ModalName) => state.modalsActive.includes(name),
);
export const getModalData = createSelector(
  [getState],
  (state) => (name: ModalName) => state.modalsData[name]!,
);

export const hasModalsActive = createSelector(
  [getState],
  (state) => !!state.modalsActive.length,
);

export const getActiveModals = createSelector(
  [getState],
  (state) => state.modalsActive,
);

export const isOverlayActive = createSelector(
  [getState],
  (state) => state.isOverlayActive,
);
