import React from 'react';
import classnames from 'classnames';
import './Flag.module.scss';

type Props = {
  country: string,
  className?: string,
};

const FlagIcon: React.FC<Props> =
React.memo(({ country, className }: FunctionProps<Props>) => {
  const name = country.toLowerCase();
  return <span className={ classnames('flag', `flag--${ name }`, className) } />;
});

export default FlagIcon;
