import { StandardResponse, BackupCode } from 'types';
import Services from 'services';

class TwoFactorService {
  async fetchSecret() {
    type Response = StandardResponse<{ code: string }>;
    const result = await Services.get('api').get<Response>('user/get_otp_secret');
    if (!result.success) throw new Error(result.message);

    return result.code;
  }
  async fetchBackupCodes() {
    type Response = StandardResponse<{ backupCodes: BackupCode[] | null }>;
    const result = await Services.get('api').get<Response>('settings/security/backup_codes');
    if (!result.success) throw new Error(result.message);

    return result.backupCodes;
  }

  async disable(otpAttempt?: string) {
    type Response = StandardResponse<{ currentStep: string }>;
    const result = await Services.get('api').post<Response>(
      'user/disable_otp',
      { user: { otpAttempt } });

    if (!result.success) throw new Error(result.message);
    if (result.currentStep === '2factor') throw new Error('2FA_REQUIRED');
  }
  async enable(otpAttempt: string) {
    type Response = StandardResponse;
    const result = await Services.get('api').post<Response>(
      'user/sent_enable_otp_email',
      { user: { otpAttempt } });

    if (!result.success) throw new Error(result.message);
  }
}

export default TwoFactorService;
