import { createSelector } from 'reselect';
import { StoreState } from '../index';

const getState = (state: StoreState) => state.localeStore || {};

export const getLocales = createSelector(
  [getState],
  (state) => state.locales,
);
export const getCurrentLocale = createSelector(
  [getState],
  (state) => state.locale,
);
export const isLocaleLoaded = createSelector(
  [getState],
  (state) => state.localeTimestamp !== -1,
);

export const localeUpdatedTimestamp = createSelector(
  [getState],
  (state) => state.localeTimestamp,
);
