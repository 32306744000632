import { createTyped } from 'utils';
import { ActionsPayload } from './types';
import { AsyncAction, FiatCurrency } from 'types';
import Services from 'services';
import { getFiatCurrencies } from './selectors';
import { isLoggedIn } from 'store/user';

const createTypedAction = createTyped<ActionsPayload>();

type ExtendedFiatCurrency = FiatCurrency & {
  current?: true,
  rate?: string,
};
export const fiatSetList = createTypedAction('fiat/SET_LIST');
const fiatListFetch = (): AsyncAction<Promise<ExtendedFiatCurrency | undefined>> =>
  async (dispatch) => {
    const data = await Services.get('api').get<ExtendedFiatCurrency[]>('v2/fiat_currencies');
    let currentFiat: ExtendedFiatCurrency | undefined;
    const parsed = data.map(({ code, current = false, ...rest }) => {
      if (current) {
        currentFiat = { code, ...rest };
      }
      return { code: code.toLowerCase(), ...rest };
    });
    dispatch(fiatSetList(parsed));
    return currentFiat;
  };

export const fiatCurrencySet = createTypedAction('fiat/SET_CURRENT');
export const fiatCurrencyChange = (code: FiatCurrency['code']): AsyncAction<Promise<void>> =>
  async (dispatch) => {
    dispatch(fiatCurrencySet({ code, rate: '0' }));
    const data = await Services.get('user').setFiatCurrency(code);
    dispatch(fiatCurrencySet(data));
  };

export const fiatListGet = (): AsyncAction<Promise<void>> =>
  async (dispatch, getState) => {
    if (getFiatCurrencies(getState()).length) return;

    const defaultFiat  = await dispatch<Promise<ExtendedFiatCurrency | undefined>>(fiatListFetch());
    if (!!defaultFiat && !isLoggedIn(getState())) {
      dispatch(fiatCurrencySet({
        code: defaultFiat.code.toLowerCase(),
        rate: `${ defaultFiat.rate }`,
      }));
    }
  };
