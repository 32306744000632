import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './error-reporter';
import loadPolyfills from './polyfills';

import initialize from './main';

// eslint-disable-next-line import/no-internal-modules
import './styles/main.scss';
import App from './App';
import store from './store';
import * as serviceWorker from './serviceWorker';

const IS_PERF_TESTING = false;
if (!__IS_PROD__) {
  if (IS_PERF_TESTING) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const whyDidYouRender = require('@welldone-software/why-did-you-render');
    whyDidYouRender(React, {
      logOnDifferentValues: true,
      include: [],
    });
  }
}

const init = () => {
  initialize(store);

  ReactDOM.render((
    <Provider store={ store }>
      <App />
    </Provider>
  ), document.getElementById('root'));


  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: http://bit.ly/CRA-PWA
  serviceWorker.unregister();
};

loadPolyfills(init);

