import { Notification } from 'types';
import { createReducer } from 'utils';
import { ActionsPayload } from './types';

export type State = Readonly<{
  list: Notification[],
}>;

export const initialState: State = {
  list: [],
};


const notificationsReducer = createReducer<State, ActionsPayload>(
  initialState,
  {
    'notifications/SHOW': (state, { payload }) => {
      // do not show duplicate notifications
      if (state.list.some(({ id }) => id === payload.id)) return state;

      return {
        ...state,
        list: [...state.list, payload],
      };
    },
    'notifications/HIDE': (state, { payload }) => ({
      ...state,
      list: state.list.filter(({ id }) => id !== payload),
    }),
  },
);

export default notificationsReducer;
