/* eslint-disable @typescript-eslint/no-use-before-define */

import debounce from 'lodash/debounce';
import { createTyped, areEqualObjects } from 'utils';
import { AsyncAction, CoinCode, OrderType, StoreState, Wallet, PaymentMethod, Deferred, OrderValueType } from 'types';
import Services from 'services';
import { reverseValueType, isBuy } from 'helpers/order';
import { roundWithPrecision } from 'helpers/number';
import { isEuro } from 'helpers/coin';

import { hasAvailableBalance, getAvailableBalanceByCode, getAvailableBalanceCoinCodes } from 'store/balances/selectors';
import { getDefaultLeftCoinCode, hasMarketsList } from 'store/markets/selectors';
import { getFiatValueInEuro, getValueInFiatCurrency } from 'store/fiat/selectors';
import { orderPendingCreate } from 'store/orders/actions';

import { ActionsPayload } from './types';
import { getBSLeftCoinCode, getBSValueType, getBSOrderType,
  getBSRightCoinCode, getBSPossibleLeftCoins, getBSPossibleRightWallets, getBSDefaultRightWallet,
  getBSAvailableRightWallets, getBSState, getBSPossibleRightCoins, getBSConversionFundsNeeded,
  getBSValue, getBSReversedValue, getBSReceiptValues, getBSPaymentMethod, getBSLeftCoin,
  getBSMarket,
  isBSConversionNeeded,
  getBSReversedFiatValue} from './selectors';
import { State } from './reducer';


const createTypedAction = createTyped<ActionsPayload>();

const updateUrl = (state: StoreState) => {
  const payload = {
    type: getBSOrderType(state),
    left: getBSLeftCoinCode(state),
    right: getBSRightCoinCode(state),
  };

  Services.get('events').emit('BUY_SELL_CHANGE', payload);
};

export const buysellOrderTypeSet = createTypedAction('buysell/ORDER_TYPE_SET');
export const buysellOrderTypeChange = (type: OrderType, canAcceptZeroBalance = false): AsyncAction<void> =>
  (dispatch, getState) => {
    let state = getState();
    dispatch(buysellOrderTypeSet(type));
    dispatch(buysellQuoteSet(null));
    dispatch(buysellReverseChange(+getBSValue(state)));

    state = getState();
    // check if currently selected left coin can be sold
    if (type === 'sell' && hasAvailableBalance(state)) {
      const leftCoinCode = getBSLeftCoinCode(state);
      const currentBalance = getAvailableBalanceByCode(state)(leftCoinCode);
      if (!canAcceptZeroBalance && currentBalance === 0) {
        const possible = getAvailableBalanceCoinCodes(state);
        if (!!possible.length) {
          dispatch(buysellLeftCodeChange(possible[0]));
        }
      }
    }

    if (type === 'sell') {
      dispatch(buysellPaymentMethodChange({ type: 'coin', id: null }));
    }

    state = getState();
    const left = getBSLeftCoinCode(state);
    const right = getBSRightCoinCode(state);
    if (isBuy(type)) {
      const possibleRightWallets = getBSPossibleRightWallets(state)(type, left);

      let wallet: Wallet | null = null;
      const last = Services.get('buySell').lastWallet;
      if (last !== null && possibleRightWallets.some((w) => areEqualObjects(w, last))) {
        wallet = last;
      }
      if (!wallet) {
        wallet = possibleRightWallets.find(({ code }) => right === code) || null;
      }
      if (!wallet) {
        wallet = getBSDefaultRightWallet(state)(type, left)!;
      }

      dispatch(buysellRightCodeSet(wallet.code));
      dispatch(buysellPaymentMethodChange({ type: wallet.type, id: wallet.id }));
    } else {
      const possibleRight = getBSPossibleRightCoins(state)(left).map(({ code }) => code);
      if (!!possibleRight.length && !possibleRight.includes(right)) {
        dispatch(buysellRightCodeSet(possibleRight[0]));
      }
    }

    updateUrl(getState());
    dispatch(buysellQuoteFetch());
    dispatch(buysellReverseFetch());
  };


export const buysellValueTypeSet = createTypedAction('buysell/VALUE_TYPE_SET');
export const buysellValueTypeChange = (): AsyncAction<void> =>
  (dispatch, getState) => {
    const state = getState();
    const type = reverseValueType(getBSValueType(state));
    let value: number | undefined;
    if (type === 'fiat') {
      const reversedFiat = getBSReversedFiatValue(state);
      if (reversedFiat > 0) {
        value = reversedFiat;
      } else if (isEuro(getBSRightCoinCode(state))) {
        const values = getBSReceiptValues(state);
        if (values.calculated) {
          value = roundWithPrecision(getValueInFiatCurrency(state)(values.total));
        }
      }
    } else {
      const reversed = getBSReversedValue(state);
      if (reversed > 0) {
        value = reversed;
      } else {
        const values = getBSReceiptValues(state);
        const coin = getBSLeftCoin(state);
        if (values.calculated) value = roundWithPrecision(values.amount, coin!.displayDecimals);
      }
    }

    if (value) dispatch(buysellValueSet(value));

    dispatch(buysellValueTypeSet(type));
    dispatch(buysellQuoteFetch());
    dispatch(buysellReverseFetch());
  };

export const buysellLeftCodeSet = createTypedAction('buysell/LEFT_CODE_SET');
export const buysellLeftCodeChange = (left: CoinCode): AsyncAction<void> =>
  async (dispatch, getState) => {
    await Services.get('store').when(hasMarketsList);
    const right = getBSDefaultRightWallet(getState())(getBSOrderType(getState()), left);
    if (!right || !right.code) {
      throw new Error(`No market for '${ left.toUpperCase() }' found!`);
    }

    dispatch(buysellLeftCodeSet(left));
    dispatch(buysellRightCodeSet(right.code));
    dispatch(buysellPaymentMethodChange({ type: right.type, id: right.id }));

    updateUrl(getState());
    dispatch(buysellQuoteFetch());
    dispatch(buysellReverseFetch());
    dispatch(buysellQuoteErrorSet(null));
  };

export const buysellInitFromUrl = (left: CoinCode, urlRight: CoinCode, type: OrderType): AsyncAction<void> =>
  (dispatch, getState) => {
    const state = getState();
    const possibleLeft = getBSPossibleLeftCoins(state).map(({ code }) => code);
    const possibleRightWallets = getBSPossibleRightWallets(state)(type, left);

    // if does not match them change order type and use default coins
    if (!possibleLeft.length || !possibleRightWallets.length) {
      dispatch(buysellOrderTypeChange(type));
      return;
    }

    let wallet = possibleRightWallets.find(({ code }) => urlRight === code) || null;
    if (!wallet) {
      wallet = getBSDefaultRightWallet(state)(type, left)!;
    }

    dispatch(buysellLeftCodeSet(left));
    dispatch(buysellRightCodeSet(wallet.code));
    dispatch(buysellPaymentMethodSet({ type: wallet.type, id: wallet.id }));

    dispatch(buysellOrderTypeChange(type));
  };

export const buysellRightCodeSet = createTypedAction('buysell/RIGHT_CODE_SET');
export const buysellRightCodeChange = (right: CoinCode): AsyncAction<void> =>
  (dispatch, getState) => {
    dispatch(buysellRightCodeSet(right));
    updateUrl(getState());
    dispatch(buysellQuoteFetch());
    dispatch(buysellReverseFetch());
    dispatch(buysellQuoteErrorSet(null));
  };


export const buysellPaymentMethodSet = createTypedAction('buysell/PAYMENT_METHOD_SET');
export const buysellPaymentMethodChange = (method: PaymentMethod): AsyncAction<void> =>
  (dispatch, getState) => {
    dispatch(buysellPaymentMethodSet(method));
    updateUrl(getState());
  };

// check available payment methods and change if possible
export const buysellInitDefault = (): AsyncAction<void> =>
  (dispatch, getState) => {
    const state = getState();
    // by default its run only when type === 'buy'
    // set default market based on user balances (so that user can immediately buy sth)
    // if no balance then leave BTC-EUR
    const availableWallets = getBSAvailableRightWallets(state);
    if (!availableWallets.length) return;

    let wallet: Wallet | null = null;
    const last = Services.get('buySell').lastWallet;
    if (last !== null && availableWallets.some((w) => areEqualObjects(w, last))) {
      wallet = last;
    }
    if (!wallet) {
      wallet = availableWallets.find(({ code }) => code === CoinCode.EUR) || availableWallets[0];
    }

    const right = wallet.code;
    const left = getDefaultLeftCoinCode(state)(right);
    if (!left) return;

    dispatch(buysellLeftCodeSet(left));
    dispatch(buysellRightCodeSet(right));
    dispatch(buysellPaymentMethodSet({ type: wallet.type, id: wallet.id }));
  };


export const buysellQuoteErrorSet = createTypedAction('buysell/QUOTE_ERROR_SET');
export const buysellQuoteSet = createTypedAction('buysell/QUOTE_SET');
const quoteFetch: AsyncAction<Promise<void>> = async (dispatch, getState) => {
  const state = getState();
  const volume = +getBSValue(state);
  if (volume <= 0 || isBSConversionNeeded(state)) return;

  const valueType = getBSValueType(state);
  const orderType = getBSOrderType(state);
  try {
    const result = await Services.get('orders').getQuote({
      valueType,
      left: getBSLeftCoinCode(state),
      right: getBSRightCoinCode(state),
      orderType: getBSOrderType(state),
      volume: valueType === 'current' ? volume : getFiatValueInEuro(state)(volume),
      paymentMethod: getBSPaymentMethod(state),
      market: getBSMarket(state),
      hasAnyFunds: hasAvailableBalance(state),
    });

    // if in meantime order type changed then discard
    if (orderType !== getBSOrderType(getState())) return;
    dispatch(buysellQuoteSet(result));
  } catch (e) {
    dispatch(buysellQuoteErrorSet(e.message));
  }
};
const debouncedQuoteFetch = debounce<typeof quoteFetch>(quoteFetch, 300, { leading: false, trailing: true });
export const buysellQuoteFetch = (): AsyncAction<void> => (...args) => debouncedQuoteFetch(...args);
export const buysellQuoteFetchImmediate = (): AsyncAction<Promise<void>> => (...args) => quoteFetch(...args);

export const buysellValueSet = createTypedAction('buysell/VALUE_SET');
export const buysellValueChange = (value: State['value'] | number): AsyncAction<void> =>
  (dispatch) => {
    dispatch(buysellValueSet(value));
    dispatch(buysellQuoteFetch());

    const isEmpty = !`${ value }`.length;
    if (isEmpty) {
      dispatch(buysellQuoteSet(null));
      dispatch(buysellReverseChange(0));
    } else {
      dispatch(buysellReverseFetch());
      dispatch(buysellReverseChange(+value));
    }
  };

export const buysellUpdate = createTypedAction('buysell/UPDATE');
export const buysellChange = (data: Partial<State>): AsyncAction<void> =>
  (dispatch, getState) => {
    dispatch(buysellUpdate(data));
    updateUrl(getState());
    dispatch(buysellQuoteFetch());
  };

export const buysellReset = createTypedAction('buysell/RESET');
export const buysellResetToDefaults = (): AsyncAction<void> =>
  (dispatch) => {
    dispatch(buysellUpdate({
      value: '',
      quote: null,
    }));
  };

export const buysellValueSetMax = (balance: number): AsyncAction<void> =>
  async (dispatch, getState) => {
    const state = getState();
    const left = getBSLeftCoinCode(state);
    let value: number;
    if (isBuy(getBSOrderType(state))) {
      try {
        const quote = await Services.get('orders').standard.fetchQuote({
          orderType: 'buy',
          from: getBSRightCoinCode(state),
          to: left,
          quantity: balance,
          quantityType: 'from',
          cardId: null,
          isCard: false,
        });
        value = quote.price;
      } catch (e) {
        dispatch(buysellQuoteErrorSet(e.message));
        return;
      }
    } else {
      value = balance;
    }

    // convert to EUR
    if (getBSValueType(getState()) === 'fiat') {
      value = await Services.get('markets').convertEuro(left, CoinCode.EUR, value);
    }

    dispatch(buysellValueChange(value));
  };


// TODO: simplify
export const buysellConvert = (quoteFetched: Deferred<void>, orderExecuted: Promise<void>): AsyncAction<void> =>
  async (dispatch, getState) => {
    const state = getState();
    const previous = getBSState(state);
    const rightNeeded = getBSConversionFundsNeeded(state);

    dispatch(buysellUpdate({
      type: 'sell',
      left: previous.right,
      right: CoinCode.BTC,
      paymentMethod: {
        type: 'coin',
        id: null,
      },
      value: rightNeeded.toString(),
      valueType: 'current',
    }));

    await dispatch(buysellQuoteFetchImmediate());
    quoteFetched.resolve();

    try {
      await orderExecuted;
      const current: StoreState['buysellStore'] = {
        ...previous,
        type: 'buy',
        right: CoinCode.BTC,
        paymentMethod: {
          type: 'coin',
          id: null,
        },
      };
      dispatch(buysellUpdate(current));
      dispatch(orderPendingCreate(current));
      dispatch(buysellQuoteFetchImmediate());
    } catch (e) {
      dispatch(buysellUpdate(previous));
    }
  };

const buysellReverseSetInternal = createTypedAction('buysell/REVERSE_SET');
export const buysellReverseSet = (type: OrderValueType, value: number): AsyncAction<void> =>
  (dispatch) => dispatch(buysellReverseSetInternal({ type, value }));

export const buysellReverseChange = (value: number): AsyncAction<void> =>
  (dispatch, getState) => {
    if (value <= 0) {
      dispatch(buysellReverseSet('fiat', 0));
      dispatch(buysellReverseSet('current', 0));
    } else {
      const type = getBSValueType(getState());
      dispatch(buysellReverseSet(type, +value));
      dispatch(buysellReverseSet(reverseValueType(type), 0));
    }
  };


const debouncedReverseFetch = debounce<AsyncAction<void>>(async (dispatch, getState) => {
  const state = getState();
  const volume = +getBSValue(state);
  const isFiat = getBSValueType(state) === 'fiat';
  const hasReverseFiat = getBSReversedFiatValue(state) > 0;
  const isEuroMarket = isEuro(getBSRightCoinCode(state));
  if (volume <= 0 || isFiat || hasReverseFiat || isEuroMarket) return;

  try {
    const result = await Services.get('markets').convertEuro(
      getBSLeftCoinCode(state),
      CoinCode.EUR,
      volume,
    );
    dispatch(buysellReverseSet('fiat', result));
  } catch (e) {
    dispatch(buysellReverseSet('fiat', 0));
  }
}, 400, { leading: false, trailing: true });
export const buysellReverseFetch = (): AsyncAction<void> => (...args) => debouncedReverseFetch(...args);
