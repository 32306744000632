import { StandardResponse, PaginationResult, UserActivity } from 'types';
import Services from 'services';

class AccountActivityService {
  async fetch(page = 1) {
    type Response = StandardResponse<PaginationResult<UserActivity>>;
    const result = await Services.get('api').get<Response>('settings/security/activity_history', {
      page,
    });
    if (!result.success) throw new Error(result.message);

    return result;
  }
}

export default AccountActivityService;
