import React from 'react';
import { connect } from 'react-redux';
import { StoreState, MapActionsObject } from 'types';
import { getNotifications } from 'store/selectors';
import { notificationHide } from 'store/actions';
import Notifications from './Notifications';

const select = (state: StoreState) => ({
  notifications: getNotifications(state),
});

const mapActions = {
  close: notificationHide,
};

type MappedProps = ReturnType<typeof select>;
type MappedActions = MapActionsObject<typeof mapActions>;

class NotificationsContainer extends React.Component<MappedProps & MappedActions> {
  render() {
    return <Notifications { ...this.props } />;
  }
}

export default connect<MappedProps, typeof mapActions, {}, StoreState>(
  select,
  mapActions,
)(NotificationsContainer);
