/* eslint-disable no-underscore-dangle */
import debounce from 'lodash/debounce';
import { API_URL } from 'config';
import { toQueryString } from 'utils';
import { serialize, deserialize } from 'helpers/api';
import * as storage from 'helpers/storage';
import { get } from 'helpers/cookies';

const TOKEN_KEY = 'api-tokens';
export const createUrl = (path: string) => {
  if (/^(http|https)/.test(path)) return path;

  return `${ API_URL }/${ path }`;
};

class ApiServiceClass {
  tokensLoaded: Promise<void>;
  private _token: string | null = null;
  private _deviceToken: string | null = null;

  constructor() {
    this.saveTokens = debounce(this.saveTokens, 250, { leading: false, trailing: true });
    this.tokensLoaded = this.loadTokens();
  }

  async get<T = void>(path: string, query = {}, { formatResponse = true } = {}): Promise<T> {
    const queryString = toQueryString(query);
    const url = `${ createUrl(path) }${ queryString }`;

    await this.tokensLoaded;
    const headers: Record<string, string> = {
      'X-Requested-With': 'XMLHttpRequest',
    };
    if (this.token) {
      headers['X-User-Token'] = this.token;
    }
    if (this.deviceToken) {
      headers['X-Device-Token'] = this.deviceToken;
    }

    const response = await window.fetch(url, {
      credentials: 'same-origin',
      headers,
    });

    return this.handleResponse(response, { format: formatResponse });
  }

  async post<T = void>(path: string, data = {}, query = {}): Promise<T> {
    const queryString = toQueryString(query);
    const url = `${ createUrl(path) }${ queryString }`;
    const body = serialize(data);

    await this.tokensLoaded;
    const response = await window.fetch(url, {
      method: 'POST',
      headers: this.prepareHeaders(body),
      credentials: 'same-origin',
      body,
    });

    return this.handleResponse(response);
  }

  async put<T = void>(path: string, data = {}): Promise<T> {
    const url = createUrl(path);
    const body = serialize(data);

    await this.tokensLoaded;
    const response = await window.fetch(url, {
      method: 'PUT',
      headers: this.prepareHeaders(body),
      credentials: 'same-origin',
      body,
    });

    return this.handleResponse(response);
  }

  async patch<T = void>(path: string, data = {}): Promise<T> {
    const url = createUrl(path);
    const body = serialize(data);

    await this.tokensLoaded;
    const response = await window.fetch(url, {
      method: 'PATCH',
      headers: this.prepareHeaders(body),
      credentials: 'same-origin',
      body,
    });

    return this.handleResponse(response);
  }

  async del<T = void>(path: string, query = {}, data = {}): Promise<T> {
    const queryString = toQueryString(query);
    const url = `${ createUrl(path) }${ queryString }`;
    const body = serialize(data);

    await this.tokensLoaded;
    const response = await window.fetch(url, {
      method: 'DELETE',
      headers: this.prepareHeaders(),
      credentials: 'same-origin',
      body,
    });

    return this.handleResponse(response);
  }


  private async handleResponse(response: Response, config = {}) {
    if (response.status === 401) {
      const error = await response.json();
      throw new Error(error.hasOwnProperty('error') && error.error ? error.error : 'Unauthorized!');
    }
    if (response.status === 403) {
      throw new Error('Wrong request sent!');
    }
    if (response.status === 404) {
      throw new Error(__DEV__ ? `Endpoint not found! (${ response.url })` : 'Unknown error occured!');
    }
    if (response.status === 500) {
      throw new Error('Unknown error occured on server. Please try again.');
    }

    if (response.headers.has('x-user-token')) {
      this._token = response.headers.get('x-user-token');
      this.saveTokens();
    }

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message);
    }

    if (response.status === 204) {
      return {};
    }

    const serializedResponse = await response.json();

    if (serializedResponse && serializedResponse.device_token) {
      this._deviceToken = serializedResponse.device_token;
      this.saveTokens();
    }

    return deserialize(serializedResponse, config);
  }

  private getContentType(data = {}): string | undefined {
    if (data instanceof FormData) {
      // so that browser will decide
      // eslint-disable-next-line id-blacklist
      return undefined;
    }

    return 'application/json';
  }
  private prepareHeaders(data = {}) {
    const result: Record<string, string> = {};

    result['X-Requested-With'] = 'XMLHttpRequest';
    const contentType = this.getContentType(data);
    if (contentType) {
      result['Content-Type'] = contentType;
      result.Accept = contentType;
    }

    if (this.token !== null) {
      result['X-User-Token'] = this.token;
    }

    if (this.deviceToken !== null) {
      result['X-Device-Token'] = this.deviceToken;
    }

    const csrf = this.CSRFToken;
    if (csrf) {
      result['X-CSRF-Token'] = csrf;
    }

    return result;
  }
  private get CSRFToken() {
    const csrf = get('X-CSRF-Token');
    if (!csrf) return null;

    return decodeURIComponent(csrf);
  }

  private async loadTokens() {
    const result = await storage.load(TOKEN_KEY);
    if (result === null) return;

    this._token = result.token;
    this._deviceToken = result.deviceToken || null;
  }
  saveTokens = () => storage.save(TOKEN_KEY, {
    token: this.token,
    deviceToken: this.deviceToken,
  });
  get token() {
    return this._token;
  }
  get deviceToken() {
    return this._deviceToken;
  }
}

export default ApiServiceClass;
