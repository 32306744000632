import { createReducer } from 'utils';
import { ActionsPayload as UserActions } from 'store/user';
import { ActionsPayload } from './types';
import { Chat, OnlineCount } from 'types';
import { addChatMessage, deleteChatMessage } from 'helpers/chatbox';

export type State = {
  chats: Chat[],
  onlineCount: OnlineCount,
};

export const initialState: State = {
  chats: [],
  onlineCount: 0,
};

// TODO: should be moved to component as its used only by chat

type Actions = ActionsPayload & Pick<UserActions, 'user/LOGGED_OUT'>;
const chatboxReducer = createReducer<State, Actions>(
  initialState,
  {
    'chatbox/INIT': (_state, { payload }) => ({
      ..._state,
      // TODO: if dangrousHtml will be used for display then each message should be sanitized
      chats: payload,
    }),
    'chatbox/ADD_MESSAGE': (state, { payload }) => ({
      ...state,
      chats: addChatMessage(state.chats, payload),
    }),
    'chatbox/DELETE_MESSAGE': (state, { payload }) => ({
      ...state,
      chats: deleteChatMessage(state.chats, payload),
    }),
    'chatbox/ONLINE_COUNT': (state, { payload }) => ({
      ...state,
      onlineCount: payload,
    }),
    'user/LOGGED_OUT': () => ({
      ...initialState,
    }),
  },
);

export default chatboxReducer;
