import { MarketPlain } from 'types';
import { createReducer, updateAtIndex } from 'utils';
import { ActionsPayload } from './types';

export type State = Readonly<{
  list: MarketPlain[],
  delisted: MarketPlain[],
}>;

export const initialState: State = {
  list: [],
  delisted: [],
};


const marketsReducer = createReducer<State, ActionsPayload>(
  initialState,
  {
    'markets/SET_LIST': (state, { payload }) => ({
      ...state,
      list: payload,
    }),
    'markets/SET_DELISTED': (state, { payload }) => ({
      ...state,
      delisted: payload,
    }),

    'markets/UPDATED': (state, { payload }) => {
      const index = state.list.findIndex(({ id }) => id === payload.id);
      if (index === -1) return state;

      const list = [...state.list];
      updateAtIndex(list, index, payload);

      return {
        ...state,
        list,
      };
    },
  },
);

export default marketsReducer;
