import { Services, ServiceName, ServicesDefinition, ServicesParams } from 'types';

// very naive dependency injection implementation
class Provider {
  // eslint-disable-next-line
  // @ts-ignore - need to remove Partial as it produces union too hard for TS to handle
  private list: ServicesDefinition = {};
  private params: Partial<ServicesParams> = {};
  // eslint-disable-next-line
  // @ts-ignore - need to remove Partial as it produces union too hard for TS to handle
  instances: Services = {};

  constructor() {
    if (__DEV__ || __STAGING__) {
      // eslint-disable-next-line no-underscore-dangle
      window.__SERVICES = this;
    }
  }

  add<S extends ServiceName>(name: S, service: ServicesDefinition[S], params: ServicesParams[S] = []) {
    this.list[name] = service;
    this.params[name] = params;
  }
  get<S extends ServiceName>(name: S): Services[S] {
    if (!this.instances[name]) {
      if (!this.list[name]) throw new Error(`Provider: '${ name }' service definition is missing!`);

      const definition = this.list[name] as ServicesDefinition['priceAlerts'];
      const params = this.params[name] || [];
      // eslint-disable-next-line
      // @ts-ignore - params are not correctly handled by TS
      this.instances[name] = new definition(...params);
    }

    return this.instances[name]!;
  }

  set<S extends ServiceName>(name: S, service: Services[S]) {
    if (this.instances[name]) {
      // to make sure its destroyed by garbage collector
      // eslint-disable-next-line
      // @ts-ignore
      this.instances[name] = null;
    }

    this.instances[name] = service;
  }
  clear() {
    // eslint-disable-next-line
    // @ts-ignore
    this.instances = {};
  }
}

export default new Provider();
