import Services from 'services';
import { User } from 'types';

type CrispData = {
  email: string,
  name: string,
  crispEmailSignature: string,
};

class CrispService {
  init() {
    if (!__IS_PROD__) return;

    Services.get('events').on('LOGGED_IN', this.onLoggedIn);
    window.addEventListener('load', this.load);
  }

  load = () => {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = '5a7e4683-483a-449e-a1af-d32d3bb9d9ea';
    (function() {
      const d = document;
      const s = d.createElement('script');
      s.src = 'https://client.crisp.chat/l.js';
      s.async = true;
      d.getElementsByTagName('head')[0].appendChild(s);
    })();
  };

  private onLoggedIn = (user: User | null) => {
    if (!user) return;

    this.setData({
      email: user.email,
      name: user.fullName.length ? user.fullName.split(' ')[0] : user.email,
      crispEmailSignature: user.crispEmailSignature || '',
    });
  };
  private setData({ email, crispEmailSignature, name }: CrispData) {
    if (!window.$crisp) return;

    window.$crisp.push(['set', 'user:email', [email, crispEmailSignature]]);
    window.$crisp.push(['set', 'user:nickname', [name]]);
  }
}

export default CrispService;
