import { ChartEntries } from 'types';
import Services from 'services';

class BalancesService {
  constructor() {
    let running: Promise<Array<[number, number]>> | null = null;
    const getSingleFundsChartData = this.getSingleFundsChartData;
    this.getSingleFundsChartData = (days: number) => {
      if (running === null) {
        running = getSingleFundsChartData(days).finally(() => {
          running = null;
        });
      }
      return running;
    };
  }

  getFundsChartData(days: number) {
    return this.getSingleFundsChartData(days);
  }
  private getSingleFundsChartData = async (days: number) => {
    const result = await Services.get('api').get<{ balances: Record<string, string> }>(
      'user/past_balances',
      { days },
      { formatResponse: false });
    const { balances } = result;

    return Object.entries(balances)
      // reverse to get ascending time values
      .reverse()
      .map(([date, worth]) => [new Date(date).getTime(), parseFloat(worth)])
      .sort(([a], [b]) => a - b) as ChartEntries;
  };
}

export default BalancesService;
