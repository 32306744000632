import { createTyped } from 'utils';
import { ActionsPayload } from './types';

const createTypedAction = createTyped<ActionsPayload>();

export const orderbookInit = createTypedAction('orderbook/INIT');
export const orderbookReset = createTypedAction('orderbook/RESET');

export const orderbookInitEmpty = createTypedAction('orderbook/INIT_EMPTY');

export const bidsAdd = createTypedAction('orderbook/BIDS_ADD');
export const asksAdd = createTypedAction('orderbook/ASKS_ADD');
