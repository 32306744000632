import { StandardResponse } from 'types';
import { getCoinsList } from 'store/selectors';
import Services from 'services';
import { history } from 'helpers/router';
import { CardAddCallback, CardDepositCallback, CardOrderCallback } from '../types';

import { handleAdd } from './add';
import { handleDeposit } from './deposit';
import { handleOrder } from './order';

export async function handle() {
  const result = await fetch();
  // no callback data
  if (result === null) return;

  if (result.type.startsWith('add')) {
    handleAdd(result as CardAddCallback, history);
  }

  // wait until coins are fetched for deposit and order
  await Services.get('store').when((state) => !!getCoinsList(state).length);

  if (result.type === 'deposit') {
    handleDeposit(result as CardDepositCallback, history);
  }
  if (result.type.includes('order')) {
    handleOrder(result as CardOrderCallback);
  }
}
async function fetch() {
  type Response = StandardResponse<{ data: CardAddCallback | CardDepositCallback | CardOrderCallback }>;
  const result = await Services.get('api').get<Response>('v2/card_callback');

  if (!result.success) return null;
  return result.data;
}
