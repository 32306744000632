import ActionCable from 'actioncable';
import { ChannelName, EventName, ChannelSubscriptionData } from 'types';
import { objectToCamelCase, logger } from 'utils';
import { WS_URL } from 'config';
import Services from 'services';
import { CHANNELS } from './channels';
import CableConnectionService from './connection';

const log = (...messages: any[]) => logger('cable', ...messages);

class CableService {
  cable: ActionCable.Cable;
  connectionChecker: CableConnectionService;

  constructor() {
    if (__DEV__) {
      // eslint-disable-next-line no-underscore-dangle
      window.__CABLE = this;
      // eslint-disable-next-line no-underscore-dangle
      window.__CABLE_MESSAGE = (channelName: string, data: any) => {
        const subscription: any = this.getSubscription(channelName as any);
        if (!subscription) {
          throw new Error('No channel with given name found!');
        }

        subscription.received(data);
      };
    }

    this.cable = ActionCable.createConsumer(WS_URL);
    this.connectionChecker = new CableConnectionService(this.cable);
  }

  subscribe<Channel extends ChannelName>(
    { channel, parameters = {}, handler, callbacks = {} }: ChannelSubscriptionData<Channel>,
  ) {
    if (handler === null) throw new Error('[CABLE] Handler function is required!');
    if (!this.channelNames.includes(channel)) throw new Error(`[CABLE] Unknown channel name '${ channel }'!`);

    Services.get('events').on(this.getEventName(channel) as any, handler as any);

    if (this.hasSubscription(channel)) return Promise.resolve();
    return this.createSubscription({ channel, parameters, callbacks });
  }
  private createSubscription(args: Required<Omit<ChannelSubscriptionData<any>, 'handler'>>): Promise<void> {
    const { channel, parameters, callbacks } = args;
    const definition = this.channels[channel];
    if (!definition) throw new Error(`[CABLE] No definition for ${ channel }!`);

    const onConnected = this.onConnected.bind(this);
    log(`[${ channel.toUpperCase() }] subscribe`);

    return new Promise((resolve) => {
      this.cable.subscriptions.create(
        {
          channel,
          ...parameters,
        },
        {
          connected() {
            if (typeof definition.connected === 'function') {
              definition.connected((this as any as ActionCable.Channel));
            }

            if (typeof callbacks.connected === 'function') {
              callbacks.connected((this as any as ActionCable.Channel));
            }

            onConnected(channel);
            resolve();

            log(`[${ channel.toUpperCase() }] connected`);
          },
          received: (raw) => {
            const data = objectToCamelCase(raw);
            const commandData = definition.received(data);
            log(`[${ channel.toUpperCase() }] received`, data, commandData);
            Services.get('events').emit(this.getEventName(channel) as any, commandData);
          },
          disconnected: () => {
            if (typeof callbacks.disconnected === 'function') {
              callbacks.disconnected();
            }

            this.onDisconnected(channel);
            log(`[${ channel.toUpperCase() }] disconnected`);
          },
          rejected: () => {
            if (typeof callbacks.rejected === 'function') {
              callbacks.rejected();
            }

            this.onRejected(channel);
            log(`[${ channel.toUpperCase() }] rejected`);
          },
        },
      );
    });
  }
  private onConnected(channel: ChannelName) {
    Services.get('events').emit('CHANNEL_CONNECTED', channel);
    this.connectionChecker.check('connected');
  }
  private onDisconnected(channel: ChannelName) {
    Services.get('events').emit('CHANNEL_DISCONNECTED', channel);
    this.connectionChecker.check('disconnected');
  }
  private onRejected(channel: ChannelName) {
    Services.get('events').emit('CHANNEL_REJECTED', channel);
  }

  unsubscribe({ channel, handler }: { channel: ChannelName, handler?: (data: any) => void }) {
    if (!this.cable) return;

    const subscription = this.getSubscription(channel);
    if (!subscription) return;

    const event = this.getEventName(channel);
    const handle = handler || null;
    Services.get('events').off(event, handle);
    log(`[${ channel.toUpperCase() }] unsubscribe`);

    if (Services.get('events').hasListeners(event)) return;

    // unsubscribe only if there are no event handlers left
    subscription.unsubscribe();
  }
  unsubscribeAll() {
    if (!this.cable) return;

    (this.cable.subscriptions as any).subscriptions.forEach(({ identifier }: { identifier: string }) => {
      const { channel = '' } = JSON.parse(identifier);
      this.unsubscribe({ channel });
    });
  }

  hasSubscription(channel: ChannelName) {
    return this.getSubscription(channel) !== null;
  }
  getSubscription(channel: ChannelName) {
    return (this.cable.subscriptions as any).subscriptions
      .find(({ identifier }: { identifier: string }) => JSON.parse(identifier).channel === channel) || null;
  }

  get channels() {
    return CHANNELS;
  }
  get channelNames() {
    return Object.keys(this.channels) as unknown as ChannelName[];
  }

  private getEventName(channel: ChannelName): EventName {
    return `CABLE_MESSAGE_${ channel }` as unknown as EventName;
  }
}

export default CableService;
