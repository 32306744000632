import { createTyped } from 'utils';
import { ActionsPayload } from './types';
import { AsyncAction } from 'types';
import Services from 'services';

const createTypedAction = createTyped<ActionsPayload>();

export const configSet = createTypedAction('config/SET');
export const configFetch = (): AsyncAction<Promise<void>> =>
  async (dispatch) => {
    type Response = any;
    try {
      const data = await Services.get('api').get<Response>('v2/config');
      dispatch(configSet(data));
    } catch (e) {
      // pass as we have default values in place
    }
  };
