import { StandardResponse } from 'types';
import Services from 'services';
import { fromCamelCase } from 'utils';

class NotificationsSettingsService {
  async fetch() {
    type Response = StandardResponse<{ data: Record<string, boolean> }>;
    const result = await Services.get('api').get<Response>('settings/notifications/data');
    if (!result.success) throw new Error(result.message);

    return result.data;
  }

  update(data: { name: string, value: boolean }) {
    return Services.get('api').post('user/notification_settings', {
      ...data,
      name: fromCamelCase(data.name),
    });
  }
}

export default NotificationsSettingsService;
