export const load = <T = any>(key: string): T | null => {
  if (!key) return null;

  try {
    const data = localStorage.getItem(key);
    if (!data) return null;

    const result = JSON.parse(data);
    return result;
  } catch (e) {
    return null;
  }
};

export const save = (key: string, data: any) => {
  if (!key) return;

  try {
    localStorage.setItem(key, JSON.stringify(data));
  } catch (e) {
    // eslint-disable-next-line
    console.error(e);
  }
};
