import { PriceAlert, CoinCode, Alert, PaginationResult, StandardResponse } from 'types';
import Services from 'services';
import { getCurrentFiat } from 'store/fiat';

import PriceAlertNotificationService from './notification';
import PriceAlertAutoAddingService from './auto';

class PriceAlertsService {
  notification = new PriceAlertNotificationService();
  auto = new PriceAlertAutoAddingService();

  constructor() {
    if (__DEV__ || __STAGING__) {
      // eslint-disable-next-line no-underscore-dangle
      window.__ALERT_SHOW = (data: Partial<PriceAlert> = {}) => this.notification.handle({
        id: 1,
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        currencyCode: 'BTC',
        priceCurrencyCode: 'USD',
        alertType: 'changed_by',
        trigger: '10',
        ...data,
      });
    }
  }

  async add(data: Omit<Alert, 'priceCurrencyCode'>) {
    const priceAlert: Alert = {
      priceCurrencyCode: Services.get('store').select(getCurrentFiat).toUpperCase(),
      ...data,
    };

    type Response = StandardResponse<{ priceAlert: PriceAlert }>;
    const result = await Services.get('api').post<Response>(
      __IS_MOCK__ ? 'user/price_alert_add' : 'user/price_alerts',
      { priceAlert },
    );
    if (!result.success) throw new Error(result.message);

    return this.parse(result.priceAlert);
  }
  fetch = async (page = 1) => {
    type Response = StandardResponse<PaginationResult<PriceAlert>>;
    const result = await Services.get('api').get<Response>('user/price_alerts', {
      page,
    });
    if (!result.success) throw new Error(result.message);

    result.data = result.data.map(this.parse);
    return result;
  };

  fetchByCode = async (code: CoinCode) => {
    type Response = StandardResponse<PaginationResult<PriceAlert>>;
    const result = await Services.get('api').get<Response>(`user/price_alerts/${ code }`);
    if (!result.success) throw new Error(result.message);

    result.data = result.data.map(this.parse);
    return result.data;
  };
  async remove(id: PriceAlert['id']) {
    const result = await Services.get('api').del<StandardResponse>(`user/price_alerts/${ id }`);
    if (!result.success) throw new Error(result.message);
  }


  private parse = (alert: PriceAlert): PriceAlert => ({
    ...alert,
    currencyCode: alert.currencyCode.toLowerCase() as CoinCode,
    priceCurrencyCode: alert.priceCurrencyCode.toLowerCase() as CoinCode,
  });
}

export default PriceAlertsService;
