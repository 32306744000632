import { delay } from 'utils';
import { RedirectData, Secure3DFormData } from './types';

export async function handleRedirect(data: RedirectData) {
  if (hasStandardRedirect(data)) {
    window.location.href = data.redirectUrl!;

    // when redirect is started, suspend promise in order not to change UI before location change
    await delay(15 * 1000);
  } else if (hasFormRedirect(data)) {
    performPostRedirect(data.formData!);

    // when post submission is started, suspend promise in order not to change UI before location change
    await delay(15 * 1000);
  }
}
export const willRedirect = (data: RedirectData) => hasStandardRedirect(data) || hasFormRedirect(data);

function hasStandardRedirect({ redirectUrl }: RedirectData) {
  return typeof redirectUrl === 'string' && redirectUrl !== null;
}
function hasFormRedirect({ formData }: RedirectData) {
  return typeof formData !== 'undefined' && formData !== null && formData.url !== null;
}
function performPostRedirect(data: Secure3DFormData) {
  const form = document.createElement('form');
  form.method = 'POST';
  form.action = data.url;

  data.payload.forEach(({ key, value }) => {
    const element = document.createElement('input');
    element.type = 'hidden';
    element.name = key;
    element.value = value;
    form.appendChild(element);
  });

  document.body.appendChild(form);

  form.submit();
}
