// file autogenerated by 'yarn run autogenerate' command
/* eslint-disable */

export const ICONS = {
  'add-white': 'add-white.svg',
  'add': 'add.svg',
  'advanced': 'advanced.svg',
  'alert-octagon': 'alert-octagon.svg',
  'alert-triangle': 'alert-triangle.svg',
  'alerts': 'alerts.svg',
  'arrow-bi': 'arrow-bi.svg',
  'arrow-down-left': 'arrow-down-left.svg',
  'arrow-down': 'arrow-down.svg',
  'arrow-left': 'arrow-left.svg',
  'arrow-right': 'arrow-right.svg',
  'arrow-up-right': 'arrow-up-right.svg',
  'arrow-up': 'arrow-up.svg',
  'bank': 'bank.svg',
  'bell': 'bell.svg',
  'bookmark': 'bookmark.svg',
  'browser-chrome': 'browser-chrome.svg',
  'browser-ff': 'browser-ff.svg',
  'browser-ie': 'browser-ie.svg',
  'browser-opera': 'browser-opera.svg',
  'browser-safari': 'browser-safari.svg',
  'browser-unknown': 'browser-unknown.svg',
  'calendar': 'calendar.svg',
  'check-white': 'check-white.svg',
  'check': 'check.svg',
  'chevron-down': 'chevron-down.svg',
  'chevron-dropdown': 'chevron-dropdown.svg',
  'chevron-dropup': 'chevron-dropup.svg',
  'chevron-left': 'chevron-left.svg',
  'chevron-right': 'chevron-right.svg',
  'chevron-up': 'chevron-up.svg',
  'clipboard': 'clipboard.svg',
  'clock': 'clock.svg',
  'copy': 'copy.svg',
  'credit-card': 'credit-card.svg',
  'device-desktop': 'device-desktop.svg',
  'download': 'download.svg',
  'earn-money': 'earn-money.svg',
  'edit': 'edit.svg',
  'eur': 'eur.svg',
  'exchange': 'exchange.svg',
  'eye': 'eye.svg',
  'file-plus': 'file-plus.svg',
  'file-text': 'file-text.svg',
  'file': 'file.svg',
  'filter': 'filter.svg',
  'flag': 'flag.svg',
  'folder': 'folder.svg',
  'globe': 'globe.svg',
  'heart': 'heart.svg',
  'history': 'history.svg',
  'home': 'home.svg',
  'ic-fb': 'ic-fb.svg',
  'ic-messenger': 'ic-messenger.svg',
  'ic-twitter': 'ic-twitter.svg',
  'info': 'info.svg',
  'invite-friend': 'invite-friend.svg',
  'layout': 'layout.svg',
  'link': 'link.svg',
  'loader-oval': 'loader-oval.svg',
  'loader': 'loader.svg',
  'lock': 'lock.svg',
  'log-out': 'log-out.svg',
  'mail': 'mail.svg',
  'map-pin': 'map-pin.svg',
  'mastercard': 'mastercard.svg',
  'menu': 'menu.svg',
  'message': 'message.svg',
  'percent': 'percent.svg',
  'play': 'play.svg',
  'plug': 'plug.svg',
  'plus': 'plus.svg',
  'plus-fat': 'plus-fat.svg',
  'pointing': 'pointing.svg',
  'popout': 'popout.svg',
  'printer': 'printer.svg',
  'qr-code': 'qr-code.svg',
  'qr': 'qr.svg',
  'question-mark': 'question-mark.svg',
  'refresh': 'refresh.svg',
  'repeat': 'repeat.svg',
  'save': 'save.svg',
  'scroll': 'scroll.svg',
  'search': 'search.svg',
  'send-rotated': 'send-rotated.svg',
  'send': 'send.svg',
  'settings': 'settings.svg',
  'share': 'share.svg',
  'shield': 'shield.svg',
  'shop': 'shop.svg',
  'spend': 'spend.svg',
  'star-filled': 'star-filled.svg',
  'star': 'star.svg',
  'support': 'support.svg',
  'trash': 'trash.svg',
  'trending-down': 'trending-down.svg',
  'trending-up': 'trending-up.svg',
  'unlock': 'unlock.svg',
  'user-check': 'user-check.svg',
  'user-plus': 'user-plus.svg',
  'user-unverified': 'user-unverified.svg',
  'user': 'user.svg',
  'users': 'users.svg',
  'visa': 'visa.svg',
  'x': 'x.svg',
  'zap': 'zap.svg',
};
