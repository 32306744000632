import { StandardResponse, PendingOrder, PendingOrderRequest } from 'types';
import Services from 'services';
import { ordersPendingRemove } from 'store/orders';

class OrderPending {
  async fetch() {
    type Response = StandardResponse<{ pendingOrder: PendingOrder | null }>;
    const result = await Services.get('api').get<Response>('instant/pending_order');
    if (!result.success) {
      throw new Error(result.message);
    }

    // make sure its returning array
    return result.pendingOrder ? [result.pendingOrder] : [];
  }

  async create(data: PendingOrderRequest) {
    type Response = StandardResponse<{ pendingOrder: PendingOrder }>;
    const result = await Services.get('api').post<Response>('instant/pending_order', { pendingOrder: data });
    if (!result.success) {
      throw new Error(result.message);
    }

    return result.pendingOrder;
  }

  cancel(id: PendingOrder['id']) {
    return Services.get('api').del(`instant/pending_order/${ id }`);
  }
  clear() {
    Services.get('store').dispatch(ordersPendingRemove());
  }
}

export default OrderPending;
