import { OrderType, CoinCode, OrderValueType, PaymentMethod, Quote } from 'types';
import { createReducer } from 'utils';
import isEqual from 'lodash/isEqual';
import { ActionsPayload } from './types';
import { isScientificNotation, removeScientificNotation } from 'helpers/number';

export type State = Readonly<{
  type: OrderType,

  left: CoinCode,
  right: CoinCode,
  paymentMethod: PaymentMethod,

  valueType: OrderValueType,
  value: string,

  quote: Quote | null,
  quoteError: string | null,

  reverse: number,
  reverseFiat: number,
}>;

const DEFAULT_LEFT_CODE = CoinCode.BTC;
const DEFAULT_RIGHT_CODE = CoinCode.EUR;

export const initialState: State = {
  type: 'buy',

  left: DEFAULT_LEFT_CODE,
  right: DEFAULT_RIGHT_CODE,
  paymentMethod: {
    type: 'coin',
    id: null,
  },

  valueType: 'fiat',
  value: '',

  quote: null,
  quoteError: null,

  reverse: 0,
  reverseFiat: 0,
};


const buysellReducer = createReducer<State, ActionsPayload>(
  initialState,
  {
    'buysell/ORDER_TYPE_SET': (state, { payload }) => ({
      ...state,
      type: payload,
    }),
    'buysell/VALUE_TYPE_SET': (state, { payload }) => ({
      ...state,
      valueType: payload,
    }),
    'buysell/LEFT_CODE_SET': (state, { payload }) => ({
      ...state,
      left: payload,
    }),
    'buysell/RIGHT_CODE_SET': (state, { payload }) => ({
      ...state,
      right: payload,
    }),
    'buysell/UPDATE': (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    'buysell/VALUE_SET': (state, { payload }) => {
      let value = `${ payload }`;
      if (isScientificNotation(value)) {
        value = removeScientificNotation(value);
      }

      return {
        ...state,
        value,
      };
    },
    'buysell/PAYMENT_METHOD_SET': (state, { payload }) => ({
      ...state,
      paymentMethod: payload,
    }),
    'buysell/QUOTE_SET': (state, { payload }) => {
      if (isEqual(payload, state.quote)) {
        return {
          ...state,
          quoteError: null,
        };
      }

      return {
        ...state,
        quote: payload,
        quoteError: null,
      };
    },
    'buysell/QUOTE_ERROR_SET': (state, { payload }) => ({
      ...state,
      quote: null,
      quoteError: payload,
    }),
    'buysell/REVERSE_SET': (state, { payload: { type, value } }) => ({
      ...state,
      [type === 'fiat' ? 'reverseFiat' : 'reverse']: value,
    }),
    'buysell/RESET': () => ({
      ...initialState,
    }),
  },
);

export default buysellReducer;
