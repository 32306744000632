import React from 'react';
import { getAssetUrl } from 'helpers/assets';

type Props = {
  className?: string,
  width?: number,
  height?: number,
  src: string,
  alt?: string,
  isResponsive?: boolean,
  hasWebp?: boolean,
};

const getSrcSet = (name: string, ext: string) => `
  ${ getAssetUrl(`/other/${ name }.${ ext }`) } 1x,
  ${ getAssetUrl(`/other/${ name }@2x.${ ext }`) } 2x,
  ${ getAssetUrl(`/other/${ name }@3x.${ ext }`) } 3x
`;

const Image: React.FC<Props> = React.memo(({
  className,
  src,
  width,
  height,
  alt = '',
  isResponsive = true,
  hasWebp = true,
}: FunctionProps<Props>) => {
  const [filename, extension] = src.split('.');

  return (
    <picture>
      { hasWebp &&
        <source
          className={ className }
          srcSet={ isResponsive ? getSrcSet(filename, 'webp') : undefined }
          type="image/webp"
        />
      }
      <img
        className={ className }
        width={ width }
        height={ height }
        alt={ alt }
        src={ getAssetUrl(`/other/${ filename }.${ extension }`) }
        srcSet={ isResponsive ? getSrcSet(filename, extension) : undefined }
      />
    </picture>
  );
});

export default Image;
