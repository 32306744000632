import { StandardResponse, CoinCode, OrderType, Order, ReceiptValues, Coin, Market } from 'types';
import Services from 'services';

import { last } from 'utils';
import { isEuro } from 'helpers/coin';

import BaseOrder from './base';
import { IOrderKind, ApiQuote, QuoteParams } from './types';

export type OrderParams = {
  receiptValues: ReceiptValues,
  leftCoin: Coin,
  type: OrderType,
  market: Market | null,
};

type Params = {
  orderType: OrderType,
  currencyCode: CoinCode,
  funds?: number | undefined,
  volume?: number | undefined,
};

const isQuoteParams = (params: any): params is QuoteParams =>
  typeof params.left !== 'undefined' && typeof params.right !== 'undefined';

class CrossMarketOrder extends BaseOrder implements IOrderKind {
  async order({ receiptValues, leftCoin, type }: OrderParams): Promise<Order | null> {
    if (receiptValues.quote === null || !receiptValues.quote.crossOrderParams) {
      throw new Error('Order params not available!');
    }

    const params = receiptValues.quote.crossOrderParams;

    this.log('params', { leftCoin, type, ...params });
    const orders = await this.perform({
      currencyCode: leftCoin.code,
      orderType: type,
      ...params,
    });

    this.log('result', orders);
    return orders.length ? last(orders) : null;
  }
  async perform(order: Params) {
    type Response = StandardResponse<{ orders: Order[] }>;
    const result = await Services.get('api').post<Response>('orders/create_euro_order', { order });

    if (!result.success) throw new Error(result.message);
    return result.orders;
  }

  protected parseQuote(raw: ApiQuote & { success?: boolean }) {
    const quote = super.parseQuote(raw);
    if (!quote.crossOrderParams) return quote;

    quote.orderType = quote.crossOrderParams.hasOwnProperty('funds') ? 'buy' : 'sell';
    if (quote.orderType === 'buy') {
      quote.total = quote.volume;
    }
    return quote;
  }

  shouldHandle(params: { market: Market | null, rightCoin: Coin } | QuoteParams) {
    if (isQuoteParams(params)) {
      return isEuro(params.right) && params.market === null;
    }

    return isEuro(params.rightCoin.code) && params.market === null;
  }

  protected log(...messages: any[]) {
    super.log('[CROSS_MARKET]', ...messages);
  }
}

export default CrossMarketOrder;
