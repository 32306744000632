import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import * as ROUTES from 'constants/routes';
import { BLOG } from 'constants/social';

import Button from 'components/Button';
import Text, { Subtitle } from 'components/Text';
import { t } from 'components/Translate';
import Icon from 'components/Icon';
import StandardLink from 'components/Link/Standard';

import styles from '../Header.module.scss';

type Props = {
  logo: React.ReactNode,
  isActive: boolean,
  toggle: () => void,
};
const HeaderAnonymousMobile: React.FC<Props> = ({ logo, isActive, toggle }: FunctionProps<Props>) => (
  <div className={ classnames(styles.more__menu, { [styles.is__active]: isActive }) }>
    <div className={ styles.moreMenu__header }>
      { logo }
      <span className={ classnames(styles.moreMenu__close, 'cursor--pointer') }>
        <Icon.Normal name="x" onClick={ toggle } />
      </span>
    </div>

    <div className="mx-3 mt-2 mb-1">
      <Link to={ ROUTES.SIGNUP }>
        <Button fullWidth={ true } className="mb-2">
          { t('common.action.signup') }
        </Button>
      </Link>
      <Link to={ ROUTES.LOGIN } className="is-link-active">
        <Button theme="link" fullWidth={ true }>
          { t('common.action.login') }
        </Button>
      </Link>
    </div>

    <div className={ styles.moreMenu__section }>
      <Text color="dark-light" className={ styles.moreMenu__heading }>
        { t('common.link.products') }
      </Text>

      <Link to={ ROUTES.DASHBOARD } className={ styles.text__withIcon }>
        <Icon.Circled color="primary" name="zap"/>
        <Subtitle weight="medium" type="normal">
          { t('common.link.instant') }
        </Subtitle>
      </Link>

      <StandardLink to={ ROUTES.ADVANCED_VIEW } className={ styles.text__withIcon }>
        <Icon.Circled color="black" name="trending-up"/>
        <Subtitle weight="medium" type="normal">
          { t('common.link.advanced') }
        </Subtitle>
      </StandardLink>
    </div>

    <div className={ styles.moreMenu__section }>
      <Text color="dark-light" className={ styles.moreMenu__heading }>
        CoinFalcon
      </Text>

      <Link to={ ROUTES.PRICES }>
        <Subtitle type="small" weight="medium" className="mt-4">
          { t('common.link.prices') }
        </Subtitle>
      </Link>
      <Link to={ ROUTES.ABOUT }>
        <Subtitle type="small" weight="medium" className="mt-4">
          { t('common.link.aboutUs') }
        </Subtitle>
      </Link>
      <StandardLink to={ BLOG } openInNewTab={ true }>
        <Subtitle type="small" weight="medium" className="mt-4">
          { t('common.link.blog') }
        </Subtitle>
      </StandardLink>
      <StandardLink to={ ROUTES.SUPPORT } openInNewTab={ true }>
        <Subtitle type="small" weight="medium" className="mt-4">
          { t('common.link.support') }
        </Subtitle>
      </StandardLink>
    </div>
  </div>
);

export default HeaderAnonymousMobile;
