import loadScript from 'load-script';

export default function loadPolyfills(callback: () => void) {
  if (__DEV__) return callback();

  const checkAfterPromiseAvailable = () => {
    const promises: Array<Promise<any>> = [];
    if (!('fetch' in window)) promises.push(import(/* webpackChunkName: "fetch" */ 'whatwg-fetch'));
    if (typeof Promise.prototype.finally !== 'function') {
      promises.push(import(/* webpackChunkName: "promise-finally" */ 'core-js/features/promise/finally'));
    }

    // eslint-disable-next-line id-blacklist
    const supportsESFeatures = 'startsWith' in String.prototype &&
      'isArray' in Array &&
      'from' in Array &&
      'includes' in Array.prototype &&
      'find' in Array.prototype &&
      'assign' in Object &&
      'values' in Object &&
      'entries' in Object &&
      'keys' in Object;

    if (!supportsESFeatures) promises.push(/* webpackChunkName: "core-js" */ import('core-js/es'));

    return Promise.all(promises).then(callback);
  };

  // if Promise is not available then load polyfill using loadScript
  // as webpacks require.ensure() and import() expect Promise to be available
  // eslint-disable-next-line @typescript-eslint/prefer-includes
  if (typeof Promise === 'undefined' || Promise.toString().indexOf('[native code]') === -1) {
    const PROMISE_POLYFILL_URL = 'https://polyfill.io/v3/polyfill.min.js?features=Promise&flags=always';
    loadScript(PROMISE_POLYFILL_URL, checkAfterPromiseAvailable);
  } else {
    checkAfterPromiseAvailable();
  }
}
