import { Order, StandardResponse } from 'types';
import Services from 'services';
import { pollAsync } from 'utils';
import { getTranslation } from 'components/Translate';

import PendingOrders from './pending';
import OrderFetcher from './fetcher';
import ScaledOrder from './scaled';

import { OrderParams, QuoteParams } from './kind/types';
import CrossMarketOrder from './kind/cross-market';
import StandardOrder from './kind/standard';
import CardOrder from './kind/card';

class OrdersService {
  pending = new PendingOrders();
  fetcher = new OrderFetcher();
  scaled = new ScaledOrder();

  card = new CardOrder();
  crossMarket = new CrossMarketOrder();
  standard = new StandardOrder();

  order = async (params: OrderParams) => {
    const handler = this.handlers.find((h) => h.shouldHandle(params));
    if (!handler) throw new Error('No handler for this order type!');

    let order = await handler.order(params);
    if (!order) throw new Error('Unknown error occured while executing order!');
    order = await Services.get('orders').awaitExecution(order.id);

    this.pending.clear();

    return order;
  };
  async getQuote(params: QuoteParams) {
    const handler = this.handlers.find((h) => h.shouldHandle(params));
    if (!handler) throw new Error('No handler for this order type!');

    return handler.getQuote(params);
  }

  async cancel(ids: string[]) {
    const result = await Services.get('api').del<StandardResponse>('orders/cancel', {}, { id: ids });
    if (!result.success) {
      return false;
    }

    return result.success;
  }

  awaitExecution(id: Order['id']) {
    return pollAsync(
      () => this.fetcher.fetchSingle(id),
      (order, deferred) => {
        if (order.status === 'fulfilled') {
          deferred.resolve(order);
          return false;
        }
        if (order.status === 'canceled') {
          deferred.reject(new Error(getTranslation('buySell.canceled')));
          return false;
        }

        return true;
      });
  }
  private get handlers() {
    return [
      this.card,
      this.crossMarket,
      this.standard,
    ];
  }
}

export default OrdersService;
