import { CoinCode, LocaleCode } from 'types';

export const API_URL = process.env.REACT_APP_API_URL!;
export const WS_URL = (__DEV__ && !__IS_MOCK__) ? 'ws://localhost:3334/cable' : process.env.REACT_APP_WS_URL!;

export const DEFAULT_LOCALE = 'en';
// needed for initial locale check
export const SUPPORTED_LOCALES: LocaleCode[] = [
  'en',
  'de',
  'pl',
];

export const CURRENCIES_ORDER = [CoinCode.BTC, CoinCode.EUR, CoinCode.ETH];

export const REFERRAL_COMMISION = 5;
export const REFERRAL_TRADE = 200;

export const PASSWORD_MIN_LENGTH = 8;

export const RECAPTCHA_ENABLED = __STAGING__ || __IS_PROD__;
export const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY!;

export const CARDS_ARE_ENABLED = __DEV__ || false;

export const STORE_URL_IOS = 'https://go.coinfalcon.com/ios';
export const STORE_URL_ANDROID = 'https://go.coinfalcon.com/android';

export const SUPPORT_EMAIL = 'support@coinfalcon.com';

export const IS_SENTRY_ENABLED = false;

export const HOW_TO_GUIDE_COINS = [
  CoinCode.ADA,
  CoinCode.BCH,
  CoinCode.BTC,
  CoinCode.EOS,
  CoinCode.ETH,
  CoinCode.IOTA,
  CoinCode.LTC,
  CoinCode.XRP,
  CoinCode.GRNOLD,
  CoinCode.IOT,
  CoinCode.USDT,
  CoinCode.XD,
  CoinCode.TRX,
  CoinCode.DOGE,
  CoinCode.ECA,
];

export const NO_FEES_COINS = [CoinCode.XD, CoinCode.IOT, CoinCode.USDT];
