import Services from './provider';

import PriceAlerts from './alerts.service';
import Announcements from './announcements.service';
import Api from './api.service';
import Auth from './auth.service';
import Balances from './balances.service';
import BuySell from './buy-sell.service';
import Cable from './cable.service';
import Charts from './charts.service';
import Crisp from './crisp.service';
import Events from './events.service';
import Markets from './markets.service';
import Notifications from './notifications.service';
import Orderbook from './orderbook.service';
import Orders from './orders.service';
import Payments from './payments.service';
import Settings from './settings.service';
import Store from './store.service';
import Transfers from './transfers.service';
import Translations from './translations.service';
import User from './user.service';
import Verification from './verification.service';
import Chatbox from './chatbox.service';
import Tracking from './tracking.service';
import Geo from './geo.service';


export const SERVICES = {
  priceAlerts: PriceAlerts,
  announcements: Announcements,
  api: Api,
  auth: Auth,
  balances: Balances,
  buySell: BuySell,
  cable: Cable,
  charts: Charts,
  crisp: Crisp,
  events: Events,
  markets: Markets,
  notifications: Notifications,
  orderbook: Orderbook,
  orders: Orders,
  payments: Payments,
  settings: Settings,
  store: Store,
  transfers: Transfers,
  translations: Translations,
  user: User,
  verification: Verification,
  chatbox: Chatbox,
  tracking: Tracking,
  geo: Geo,
};

Object.entries(SERVICES).forEach(([name, service]) => Services.add(name as any, service));

export default Services;
