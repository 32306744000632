import { LocaleCode } from 'types';
import { Store } from 'store';
import { DEFAULT_LOCALE, SUPPORTED_LOCALES } from 'config';
import { getCurrentLocale, isLoggedIn } from 'store/selectors';
import { localeLoaded } from 'store/actions';
import Services from 'services';
import { getLocaleFromUrl, detectLocale, changeLocaleUrl } from 'helpers/locale';
import { translateSingle } from 'helpers/translate';
import META from 'assets/locales/meta';

type TranslationsData = { [key: string]: TranslationsData | string };
type TranslationsFetcher = Record<LocaleCode, () => Promise<{ default: TranslationsData }>>;
type TranslationEntry = Record<LocaleCode, string>;

// TODO: refetch dynamically added translations on locale change

class TranslationsService {
  private $store!: Store;
  data: null | TranslationsData = null;

  ensureLocalizedUrl() {
    if (this.getLocaleFromUrl()) return false;

    this.changeUrl(DEFAULT_LOCALE);
    return true;
  }

  init($store: Store) {
    this.$store = $store;

    if (this.hasLocale(this.currentLocale)) {
      this.load();
    } else {
      this.load(DEFAULT_LOCALE);
    }
  }
  private async load(locale = this.currentLocale) {
    const data = await this.fetch(locale);
    this.data = {
      ...data,
      // load meta translations with EN as fallback
      meta: {
        ...META.en,
        ...(META[locale] || {}),
      },
    };

    this.$store.dispatch(localeLoaded());
  }
  private async fetch(fullLocale: string): Promise<TranslationsData> {
    const [locale] = fullLocale.split('-');
    if (!locale) throw new Error(`Invalid locale ${ fullLocale }`);

    const module = await import(/* webpackChunkName: "locales" */ `assets/locales/${ locale }`);
    return module.default;
  }

  async add(fetchers: TranslationsFetcher) {
    const translations = await fetchers[this.currentLocale]();
    this.data = {
      ...(this.data || {}),
      ...translations.default,
    };

    this.$store.dispatch(localeLoaded());
  }

  async changeLocale(locale: LocaleCode) {
    if (!this.hasLocale(locale)) throw new Error(`[TRANSLATIONS] Unknown locale '${ locale }'!`);

    // only change url for anonymous user
    const isAnonymous = !Services.get('store').select(isLoggedIn);
    if (isAnonymous) {
      this.changeUrl(locale);
    } else {
      this.load(locale);
      await this.changeLocaleRemote(locale);
    }
  }
  private changeLocaleRemote(locale: LocaleCode) {
    if (!Services.get('store').select(isLoggedIn)) return;
    return Services.get('api').post('languages/update_user_language', { languageCode: locale });
  }

  translate(data: TranslationEntry, values = {}) {
    const locale = this.currentLocale;
    if (!data.hasOwnProperty(locale)) throw new Error(`Locale "${ locale }" not found in data!`);

    return translateSingle(data[locale], values);
  }

  detectLocale = detectLocale;
  getLocaleFromUrl = getLocaleFromUrl;
  changeUrl = changeLocaleUrl;

  get currentLocale(): LocaleCode {
    return getCurrentLocale(this.$store.getState());
  }

  private hasLocale(locale: LocaleCode) {
    return SUPPORTED_LOCALES.includes(locale);
  }
}

export default TranslationsService;
