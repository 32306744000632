import React from 'react';
import { Route, RouteProps } from 'react-router';
import { connect } from 'react-redux';
import { StoreState, MapActionsObject } from 'types';
import { userFetch } from 'store/actions';
import { isUserChecked, isLoggedIn } from 'store/selectors';

const select = (state: StoreState) => ({
  isChecked: isUserChecked(state),
  isAuthenticated: isLoggedIn(state),
});

const mapActions = {
  userFetch,
};

type MappedProps = ReturnType<typeof select>;
type MappedActions = MapActionsObject<typeof mapActions>;
type Props = {
  preload?: () => void,
};

class UserAwareRoute extends React.Component<MappedProps & MappedActions & RouteProps & Props> {
  componentDidMount() {
    if (this.props.isChecked) return;
    this.props.userFetch();

    if (this.props.preload) this.props.preload();
  }

  render() {
    const { component: Component, children, isChecked, isAuthenticated, ...rest } = this.props;
    if (!isChecked) return null;

    if (!Component) {
      return <Route { ...rest }>{ children }</Route>;
    }

    return (
      <Route
        { ...rest }
        component={ Component }
      />
    );
  }
}

export default connect<MappedProps, typeof mapActions, RouteProps, StoreState>(
  select,
  mapActions,
)(UserAwareRoute);
