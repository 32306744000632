import { localize } from 'helpers/router';

export const ABOUT = localize('/about-us');
export const TERMS_OF_USE = localize('/terms');
export const PRIVACY_POLICY = localize('/privacy-policy');
export const AML_POLICY = localize('/aml');
export const REFUND_POLICY = localize('/refund-policy');
export const COOKIE_POLICY = localize('/cookie-policy');
export const CONTACT = localize('/contact-us');
export const FEES = localize('/fees');
export const UNSUBSCRIBE = localize('/unsubscribe');
export const NOT_FOUND = '*';
