import { DepositAddresses, DepositTags, Deposit, CoinCode, StandardResponse, Notification } from 'types';
import { pick } from 'utils';
import { depositUpdated, buysellChange } from 'store/actions';
import { getPossibleLeftCoinCodes } from 'store/selectors';
import { getTranslation } from 'components/Translate';
import Services from 'services';
import { fixedClean } from 'helpers/number';

export default class DepositsService {
  async fetch(): Promise<Deposit[]> {
    const result = await Services.get('api').get<StandardResponse<{ data: Deposit[] }>>('deposit/list');
    if (!result.success) {
      throw new Error(`Error while fetching deposits: ${ result.message }`);
    }

    return result.data;
  }
  async fetchSingle(id: Deposit['id']) {
    const result = await Services.get('api').get<StandardResponse<{ data: Deposit }>>(`deposit/${ id }`);
    if (!result.success) {
      throw new Error(result.message);
    }

    return result.data;
  }

  async fetchAddresses() {
    type ResponseType = StandardResponse<{ addresses: DepositAddresses, tags: DepositTags }>;
    const result = await Services.get('api').get<ResponseType>('deposit/addresses');
    if (!result.success) {
      throw new Error(`Error while fetching deposit addresses: ${ result.message }`);
    }

    return pick(result, ['addresses', 'tags']);
  }
  async generateAddress(code: CoinCode): Promise<{ addresses: DepositAddresses, tags: DepositTags }> {
    type ResponseType = StandardResponse<{ addresses: DepositAddresses, tags: DepositTags }>;
    const result = await Services.get('api').post<ResponseType>('deposit/new_address', { currency: code });
    if (!result.success) {
      throw new Error(`Error while generating deposit address for '${ code }': ${ result.message }`);
    }

    return result;
  }

  onUpdate(deposit: Deposit) {
    Services.get('store').dispatch(depositUpdated(deposit));
    Services.get('events').emit('DEPOSIT_UPDATED', deposit);
    if (deposit.status !== 'completed') return;

    this.showNotification(deposit);
  }
  showNotification(deposit: Deposit) {
    const amount = [fixedClean(deposit.amount), deposit.currencyCode.toUpperCase()].join(' ');
    const data: Notification = {
      id: deposit.id,
      type: 'local',
      icon: {
        color: 'success',
        name: 'check-white',
      },
      coinCode: deposit.currencyCode,
      title: getTranslation('notification.deposit.completed.title'),
      description: getTranslation('notification.deposit.completed.description', { amount }),
      onClick: this.handleNotificationClick(deposit.currencyCode),
    };
    Services.get('notifications').show(data);
  }
  private handleNotificationClick(right: CoinCode) {
    const leftPromise = this.fetchLastBought();
    return async () => {
      let left = await leftPromise;
      const possibleLeft = Services.get('store').select(getPossibleLeftCoinCodes)(right);
      if (!possibleLeft.length) {
        left = CoinCode.BTC;
        right = CoinCode.EUR;
      } else if (!possibleLeft.includes(left)) {
        left = possibleLeft[0];
      }

      Services.get('store').dispatch(buysellChange({
        left,
        right,
        type: 'buy',
      }));
    };
  }
  private async fetchLastBought(): Promise<CoinCode> {
    const result = await Services.get('orders').fetcher.fetchLastBought();
    if (!result) return CoinCode.BTC;

    return result;
  }
}
