import React from 'react';
import classNames from 'classnames';
import { ReactComponent as LogoIcon } from 'assets/logo.svg';
import styles from './Logo.module.scss';

type Props = {
  className?: string,
  inverted?: boolean,
  onClick?: () => void,
};

const Logo: React.FC<Props> = ({ className = '', inverted = false, onClick }: FunctionProps<Props>) => (
  <div
    className={ classNames(styles.logo, className, { [styles.inverted]: inverted }) }
    onClick={ onClick && (() => onClick()) }
  >
    <LogoIcon />
  </div>
);

export default Logo;
