import React from 'react';
import { delay } from 'utils';

type Props = {
  delay?: number,
};

type State = {
  isMounted: boolean,
};

class AsyncRenderer extends React.PureComponent<Required<Props>, State> {
  static defaultProps: Props = {
    delay: 0,
  };
  state: State = {
    isMounted: false,
  };

  async componentDidMount() {
    if (this.props.delay > 0) {
      await delay(this.props.delay);
    }

    this.setState({ isMounted: true });
  }

  render() {
    if (!this.state.isMounted) return null;

    return this.props.children;
  }
}

export default AsyncRenderer;
