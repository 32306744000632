import { createSelector } from 'reselect';
import { StoreState } from '../index';

const getState = (state: StoreState) => state.announcementsStore || {};

export const getAnnouncements = createSelector(
  [getState],
  (state) => state.list,
);

