import { PendingOrder } from 'types';
import { createReducer } from 'utils';
import { ActionsPayload } from './types';

export type State = Readonly<{
  // keep it as array cause in future they may be multiple orders
  pending: PendingOrder[] | null,
}>;

export const initialState: State = {
  pending: null,
};


const ordersReducer = createReducer<State, ActionsPayload>(
  initialState,
  {
    'orders/PENDING_SET': (state, { payload }) => ({
      ...state,
      pending: payload,
    }),
    'orders/PENDING_REMOVE': (state) => ({
      ...state,
      pending: [],
    }),
  },
);

export default ordersReducer;
