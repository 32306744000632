import React from 'react';
import getFromObject from 'lodash/get';
import { warn } from 'utils';
import Services from 'services';

// eslint-disable-next-line no-useless-escape
const PATTERN = '[$%]?\{\\s?([a-zA-Z_0-9]+)\\s?\}';
const VARIABLE_PATTERN = new RegExp(PATTERN);
const MATCHING_PATTERN = new RegExp(PATTERN, 'g');

const KEY_PATTERN = /#([a-zA-Z_0-9.]+)#/;
const KEY_MATCHING_PATTERN = new RegExp(KEY_PATTERN, 'g');

const getTranslationsData = () => Services.get('translations').data;
export const getKeys = (key: string) => getFromObject(getTranslationsData() || {}, key, null);
export const getChildKeys = (key: string) => {
  const data = getKeys(key);
  if (!data) return [];
  return Object.keys(data);
};
export const has = (key: string) => getKeys(key) !== null;

const replaceVariables = (text: string, variables: string[], values: Record<string, any>, key: string) =>
  variables.reduce((message, variable) => {
    const match = VARIABLE_PATTERN.exec(variable);
    if (!match) return message;

    const variableName = match[1];
    if (!values.hasOwnProperty(variableName)) {
      warn('translations', `variables object for key '${ key }' does not contain '${ variableName }'!`);
      return message;
    }

    return message.replace(variable, values[variableName]);
  }, text);
const replaceLinks = (text: string, links: string[], values: Record<string, any>, key: string) =>
  links.reduce((message, link) => {
    const match = KEY_PATTERN.exec(link);
    if (!match) return message;

    const path = match[1];
    if (!has(path)) {
      warn('translations', `link key '${ key }' not found!`);
      return message;
    }

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return message.replace(link, get(path, values));
  }, text);

export const get = (key: string, values = {}): string => {
  if (!key) throw new Error('[TRANSLATIONS] key is required!');

  const data = getTranslationsData();
  if (data === null) return '';

  const msg = getFromObject(data, key, null) as string | null;
  if (msg === null) {
    warn('translations', `key '${ key }' not found!`);
    return '';
  }

  if (typeof msg !== 'string') {
    warn('translations', `value of key '${ key }' is not a string!`);
    return '';
  }

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  return translateSingle(msg, values, key);
};
export const translateSingle = (msg: string, values = {}, key = 'none'): string => {
  const variables = msg.match(MATCHING_PATTERN);
  const hasValues = typeof values === 'object' && Object.keys(values).length;
  if (variables !== null && !!variables.length) {
    if (!hasValues) {
      warn('translations', `variables object for key '${ key }' not specified`);
      return msg;
    }

    msg = replaceVariables(msg, variables, values, key);
  }

  const links = msg.match(KEY_MATCHING_PATTERN);
  if (links !== null && !!links.length) {
    msg = replaceLinks(msg, links, values, key);
  }

  return msg;
};

export const getHtml = (key: string, values = {}): React.ReactNode => {
  const msg = get(key, values);
  if (!msg) return '';

  return React.createElement('span', {
    dangerouslySetInnerHTML: {
      __html: msg,
    },
  });
};

export const translate = (path: string, variables = {}) =>
  get(path, variables);
