import { Chat } from 'types';
import { USERNAME_COLOR_MAP } from 'constants/chatbox';

export const addChatMessage = (chats: Chat[], newChat: Chat) => {
  const isChatExist = chats.some((chat) => chat.id === newChat.id);
  if (isChatExist) return chats;

  return [newChat, ...chats];
};

export const deleteChatMessage = (chats: Chat[], id: Chat['id']) => {
  const newChats = [...chats];
  const chatIndex = newChats.findIndex((chat) => chat.id === id);
  if (chatIndex < 0) return chats;

  newChats[chatIndex].deleted = true;
  return newChats;
};

export const getUserNameTextClass = (chatUsername: Chat['chatUsername']) => {
  try {
    const firstLetter = chatUsername[0].toLowerCase();
    const userColor = USERNAME_COLOR_MAP[firstLetter];
    return `username__${ userColor }`;
  } catch (e) {
    return 'username__brown';
  }
};

export const htmlToText = (html: string) => {
  const parser = new DOMParser();
  const dom = parser.parseFromString(`<html><body>${ html }</body></html>`, 'text/html');
  return dom.body.textContent || '';
};

export const sanitizeHtml = (html: string) => {
  // eslint-disable-next-line no-useless-escape
  html = html.replace(/\>/gim, '&gt;').replace(/\</gim, '&lt;');
  return html;
};
