import { AsyncAction, MarketPlain, StandardResponse } from 'types';
import { createTyped } from 'utils';
import Services from 'services';
import { ActionsPayload } from './types';
import { getMarketById, getMarketsList } from './selectors';

const createTypedAction = createTyped<ActionsPayload>();

export const marketsSetList = createTypedAction('markets/SET_LIST');
export const marketsSetDelisted = createTypedAction('markets/SET_DELISTED');
const marketsFetch = (): AsyncAction<Promise<MarketPlain[]>> =>
  async (dispatch) => {
    const data = await Services.get('api').get<MarketPlain[]>('v2/markets');
    dispatch(marketsSetList(data));
    return data;
  };

export const marketsGet = (): AsyncAction<void> =>
  (dispatch, getState) => {
    if (getMarketsList(getState()).length) return;
    dispatch(marketsFetch());
  };

export const marketUpdated = createTypedAction('markets/UPDATED');

export const marketStarToggle = (marketId: MarketPlain['id']): AsyncAction<void> =>
  async (dispatch, getState) => {
    try {
      // optimistic update for better UX
      const market = getMarketById(getState())(marketId);
      if (market) {
        dispatch(marketUpdated({ id: marketId, isStarred: !market.isStarred }));
      }

      type Response = StandardResponse<{ market: { isFavorite: boolean }}>;
      const result = await Services.get('api').post<Response>('user/toggle_fav_market', { marketId });
      if (!result.success) return;

      dispatch(marketUpdated({ id: marketId, isStarred: result.market.isFavorite }));
    } catch (e) {
      // TODO: handle
    }
  };
