import { User } from 'types';
import { ITracker } from './index';

class MixpanelTracker implements ITracker {
  onLogin(user: User) {
    if (this.mixpanel) this.mixpanel.identify(user.uuid);
  }
  onLogout() {
    if (this.mixpanel) this.mixpanel.reset();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onOrderSuccess() {}

  private get mixpanel() {
    return window.mixpanel;
  }
}

export default MixpanelTracker;
