/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-misused-promises */

import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Services from 'services';
import { coinsGet } from 'store/actions';
import { loadable } from 'helpers/component';
import { LANDING, PRICES, } from 'constants/routes';

import AppRoute from './App';
import AuthRoute from './Auth';
import UserAwareRoute from './UserAware';
import Static from './Statics';

import { COIN_DETAILS_ROUTES } from './CoinDetails/routes-group';
import { ONBOARDING_ROUTES } from './Onboarding/routes-group';
import { STATIC_ROUTES } from './Statics/routes-group';
import { GUIDES_ROUTES } from './Guides/routes-group';
import { SETTINGS, SETTINGS_ACCOUNT } from './Settings/routes';
import { REFERRAL_AUTHENTICATED, REFERRAL_ANONYMOUS } from './Referral/routes';
import Dashboard from './Dashboard';
import { DASHBOARD_ROUTES } from './Dashboard/routes-group';

const Onboarding = loadable(() => import(/* webpackChunkName: "onboarding" */ 'routes/Onboarding'));
const Settings = loadable(() => import(/* webpackChunkName: "settings" */ 'routes/Settings'));
const CoinDetails = loadable(() => import(/* webpackChunkName: "coin-details" */ 'routes/CoinDetails'));
const Referral = loadable(() => import(/* webpackChunkName: "referral" */ 'routes/Referral'));
const Landing = loadable(() => import(/* webpackChunkName: "landing" */ 'routes/Landing'));
const Prices = loadable(() => import(/* webpackChunkName: "prices" */ 'routes/Prices'));
const Guides = loadable(() => import(/* webpackChunkName: "guides" */ 'routes/Guides'));

class Router extends Component {
  render() {
    return (
      <Switch>
        <AuthRoute exact={ true } path={ DASHBOARD_ROUTES }>
          <Dashboard />
        </AuthRoute>

        <Redirect from={ SETTINGS } to={ SETTINGS_ACCOUNT } exact={ true } />
        <AuthRoute path={ SETTINGS }>
          <AppRoute>
            <Settings />
          </AppRoute>
        </AuthRoute>

        <AuthRoute path={ REFERRAL_AUTHENTICATED }>
          <AppRoute>
            <Referral />
          </AppRoute>
        </AuthRoute>

        <Route path={ REFERRAL_ANONYMOUS } exact={ true }>
          <AppRoute>
            <Referral />
          </AppRoute>
        </Route>

        <UserAwareRoute
          path={ COIN_DETAILS_ROUTES }
          exact={ true }
          preload={ () => {
            CoinDetails.preload();
            Services.get('store').dispatch(coinsGet());
          } }
        >
          <AppRoute>
            <CoinDetails />
          </AppRoute>
        </UserAwareRoute>

        <UserAwareRoute path={ PRICES }>
          <AppRoute>
            <Prices />
          </AppRoute>
        </UserAwareRoute>

        <Route path={ LANDING } exact={ true } component={ Landing } />
        <Route path={ ONBOARDING_ROUTES } component={ Onboarding } />
        <UserAwareRoute path={ GUIDES_ROUTES } exact={ true }>
          <Guides />
        </UserAwareRoute>

        { /* has to be last route for 404 handling */ }
        <UserAwareRoute path={ STATIC_ROUTES } exact={ true }>
          <Static />
        </UserAwareRoute>
      </Switch>
    );
  }
}

export default Router;
