import { createSelector } from 'reselect';
import { StoreState } from '../index';

const getState = (state: StoreState) => state.referralStore || {};

export const getReferralStats = createSelector(
  [getState],
  (state) => state.stats,
);
export const hasReferralClaimed = createSelector(
  [getReferralStats],
  (stats) => stats && stats.claimed,
);
