import { Notification, CoinCode, Transfer, PriceAlert, ChannelName, ChannelHandler,
  VerificationStatusChangeMessage } from 'types';
import { notificationShow } from 'store/notifications';
import Services from 'services';
import { randomId } from 'helpers/id';

const CHANNEL: ChannelName = 'NotificationChannel';
type Channel = typeof CHANNEL;

class NotificationsService {
  show(data: Notification) {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    Services.get('store').dispatch(notificationShow(data));
  }
  showSuccess(data: Omit<Notification, 'id' | 'icon'>) {
    this.show({
      id: randomId(),
      icon: {
        name: 'check',
        color: 'success',
      },
      ...data,
    });
  }

  subscribe() {
    this.unsubscribe();
    return Services.get('cable').subscribe<Channel>({
      channel: CHANNEL,
      handler: this.handleNotifications,
    });
  }
  private handleNotifications: ChannelHandler<Channel> = (message) => {
    if (!message) return;

    const { command, data } = message;
    if (command === 'withdraw') {
      Services.get('transfers').withdrawals.onUpdate(data as Transfer);
    }

    if (command === 'deposit') {
      Services.get('transfers').deposits.onUpdate(data as Transfer);
    }

    if (command === 'alert') {
      Services.get('priceAlerts').notification.handle(data as PriceAlert);
    }

    if (command === 'verification') {
      Services.get('verification').onStatusChange(data as VerificationStatusChangeMessage);
    }
  };
  unsubscribe() {
    Services.get('cable').unsubscribe({ channel: CHANNEL });
  }
}

if (__DEV__) {
  // eslint-disable-next-line no-underscore-dangle
  window.__NOTIFICATION_SHOW = (data: Partial<Notification> = {}) => window.__STORE.dispatch(notificationShow({
    id: '123',
    type: 'local',
    icon: {
      color: 'success',
      name: 'trending-up',
    },
    coinCode: CoinCode.BTC,
    title: 'Bitcoin (BTC) price is above 4440.00$',
    description: 'You can manage your alerts in settings',
    onClick: () => null,
    onClose: () => null,
    ...data,
  }));
}

export default NotificationsService;
