import classnames from 'classnames';
import React from 'react';
import { FiatCurrency, Locale, StoreAction } from 'types';

import Icon from 'components/Icon';
import Logo from 'components/Logo';
import MobileAppsButtons from 'components/MobileAppsButtons';
import FiatCurrencyPicker from 'components/Picker/FiatCurrency/Select';
import LocalePicker from 'components/Picker/Locale';
import Text from 'components/Text';

import styles from './AppFooter.module.scss';
import Links from './Links';

type Props = {
  isAuthenticated: boolean,

  currencies: FiatCurrency[],
  currencyCurrent: FiatCurrency['code'],
  currencySet: StoreAction<'fiatCurrencyChange'>,
  currenciesFetch: StoreAction<'fiatListGet'>,

  isLocaleLoaded: boolean,
  locales: Locale[],
  localeCurrent: Locale['code'],
  localeSet: StoreAction<'localeChange'>,
  localesFetch: StoreAction<'localesFetch'>,
};

class Footer extends React.PureComponent<Props> {
  render() {
    const { isAuthenticated } = this.props;

    /* eslint-disable max-len */
    return (
      <div className={ classnames(styles.wrap, {[styles.wrapNotAuth]: !isAuthenticated}) }>
        <div className={ styles.footer }>
          <div className="row mt-md-4">
            { !isAuthenticated &&
              <>
                <div className="col-lg-2 my-3">
                  <Logo className={ styles.footerLogo }/>
                </div>
                <div className="col-lg-7 mb-lg-0 mb-md-4">
                  <div className="row">
                    <Links />
                  </div>
                </div>
                <div className={ classnames('col col-12 col-md-6 d-lg-none mt-md-2 mt-5 p-md-3 p-0 d-flex justify-content-md-start justify-content-center', styles.authAppsMobileCol) }>
                  { this.renderApps() }
                </div>
                <div className={ classnames('col col-12 d-lg-none mt-md-2 mt-4 p-md-3 p-0 d-flex justify-content-md-start justify-content-center', styles.authCopyMobileCol) }>
                  { this.renderCopy() }
                </div>
                <div className={ classnames('col-lg-3 col-md-6 col-12 d-flex justify-content-md-end justify-content-center', styles.authPickersCol) }>
                  { this.renderPickers() }
                </div>
              </>
            }
            { isAuthenticated &&
              <>
                <div className="col-md-6 align-self-end mt-md-0 d-flex justify-content-md-start justify-content-center order-last order-md-first">
                  { this.renderCopy() }
                </div>
                <div className="col-md-6 col-12 d-flex justify-content-md-end justify-content-center order-first order-md-last">
                  { this.renderPickers() }
                </div>
              </>
            }
          </div>
        </div>
        { !isAuthenticated &&
          <div className={ classnames('d-none d-lg-block', styles.footer) }>
            { this.renderCopy(true) }
          </div>
        }
      </div>
    );
  }
  private renderApps() {
    return (
      <MobileAppsButtons
        className={ styles.apps }
        iosClass={ styles.appstore }
        androidClass={ styles.gplay }
      />
    );
  }
  private renderCopy(withApps = false) {
    return (
      <div className={ classnames(styles.footerCopy, { [styles.anonymous]: !this.props.isAuthenticated }) }>
        <Text color="dark-light" className={ styles.copyText }>
          &copy; 2017-{ new Date().getFullYear() } CoinFalcon. All Rights Reserved
        </Text>

        <div className={ styles.cards }>
          <Icon.Normal name="mastercard" size="large" />
          <Icon.Normal name="visa" size="large" />
        </div>

        { withApps && this.renderApps() }
      </div>
    );
  }
  private renderPickers() {
    const {
      currencies, currencyCurrent, currencySet, currenciesFetch,
      locales, localeCurrent, localeSet, localesFetch,
    } = this.props;

    return (
      <div className={ styles.selects }>
        <LocalePicker
          { ...{
            locales,
            current: localeCurrent,
            onChange: localeSet,
            fetch: localesFetch,
          } }
        />
        <FiatCurrencyPicker
          { ...{
            currencies,
            current: currencyCurrent,
            onChange: currencySet,
            fetch: currenciesFetch,
          } }
        />
      </div>
    );
  }
}

export default Footer;
