import { createSelector } from 'reselect';
import { CoinCode, Wallet, OrderType, ReceiptValues } from 'types';
import { BALANCE_EMPTY } from 'constants/balance';
import Services from 'services';
import { areEqualObjects } from 'utils';

import { isMarketReversed, getMarketByName, getMarketsLeftCoinCodes,
  getDefaultMatchingCoinCode, getPossibleMatchingCoins, getMarketsRightCoins,
  getLatestMarketPrice } from 'store/markets/selectors';
import { getCoinByCode, getCoinsList } from 'store/coins/selectors';
import { getOrderbook } from 'store/orderbook/selectors';
import { getBalanceByCode, hasAvailableBalance, getAvailableBalanceByCode,
  getAvailableBalanceCoins } from 'store/balances/selectors';
import { getCurrentFiatCoin, getCoinFiatPrice, getCoinEuroValue } from 'store/fiat/selectors';
import { getCardsList } from 'store/payments/selectors';
import { getUserFee, hasCardsEnabled } from 'store/user/selectors';

import { isBuy, isCardPayment, isOrderType } from 'helpers/order';
import { getOrderbookType } from 'helpers/orderbook';
import { createMarketName } from 'helpers/markets';
import { roundWithPrecision, isNaN, floatMultiply } from 'helpers/number';
import { sortCoins } from 'helpers/sort';
import { isReversable, isEuro } from 'helpers/coin';

import { StoreState } from '../index';


const getState = (state: StoreState) => state.buysellStore || {};

export const getBSState = createSelector(
  [getState],
  (state) => state,
);

export const getBSOrderType = createSelector(
  [getState],
  (state) => state.type,
);
export const getBSValueType = createSelector(
  [getState],
  (state) => state.valueType,
);


export const getBSLeftCoinCode = createSelector(
  [getState],
  (state) => state.left,
);
export const getBSRightCoinCode = createSelector(
  [getState],
  (state) => state.right,
);


export const getBSPaymentMethod = createSelector(
  [getState],
  (state) => state.paymentMethod,
);
export const getBSPaymentMethodType = createSelector(
  [getBSPaymentMethod],
  (method) => method.type,
);


export const getBSQuote = createSelector(
  [getState],
  (state) => state.quote,
);
export const getBSQuoteError = createSelector(
  [getState],
  (state) => state.quoteError,
);


export const getBSReversedValue = createSelector(
  [getState],
  (state) => state.reverse,
);
export const getBSReversedFiatValue = createSelector(
  [getState],
  (state) => state.reverseFiat,
);


export const getBSMarketName = createSelector(
  [getState, isMarketReversed],
  ({ left, right }, isReversed) =>
    createMarketName(left, right, isReversed(left, right)),
);
export const getBSMarket = createSelector(
  [getBSMarketName, getMarketByName],
  (name, getMarket) => getMarket(name),
);
export const isBSMarketReversed = createSelector(
  [getState, isMarketReversed],
  ({ left, right }, isReversed) => isReversed(left, right),
);


export const getBSLeftCoin = createSelector(
  [getBSLeftCoinCode, getCoinByCode],
  (code, getCoin) => getCoin(code),
);
export const getBSRightCoin = createSelector(
  [getBSRightCoinCode, getCoinByCode],
  (code, getCoin) => getCoin(code),
);


export const getBSValue = createSelector(
  [getState],
  (state) => state.value,
);
// get current value in current left coin (so convert from fiat if currenly fiat is used)
export const getBSCoinValue = createSelector(
  [getBSValueType, getBSValue, getBSLeftCoinCode, getCoinFiatPrice],
  (type, raw, code, getPrice) => {
    if (type === 'current') return raw;

    const fiatPrice = getPrice(code);
    const value = parseFloat(raw);
    if (isNaN(value)) return raw;

    const coinValue = value / fiatPrice;
    return `${ coinValue }`;
  },
);
export const getBSCoinValueRounded = createSelector(
  [getBSCoinValue, getBSLeftCoin],
  (value, coin) => {
    if (isNaN(value)) return '0';

    return roundWithPrecision(value, coin ? coin.displayDecimals : 2).toString();
  },
);
export const getBSLeftEuroValue = createSelector(
  [getBSLeftCoinCode, getCoinEuroValue],
  (code, getValue) => (value: number | string) => getValue(code, +value),
);


export const getBSPossibleLeftCoins = createSelector(
  [getBSOrderType, getCoinsList, getMarketsLeftCoinCodes, hasAvailableBalance, getBalanceByCode],
  (type, coins, leftCoinCodes, hasAvailable, getBalance) =>
    coins.filter((coin) => {
      const isLeft = leftCoinCodes.includes(coin.code) || isReversable(coin.code);
      if (!isLeft) return false;

      if (isBuy(type) || !hasAvailable) return true;

      const balance = getBalance(coin.code);
      if (!balance) return false;

      return parseFloat(balance.availableBalance) > 0;
    }),
);

export const getBSAvailableRightCoins = createSelector(
  [getBSOrderType, getMarketsRightCoins, getAvailableBalanceByCode],
  (type, available, getBalance) =>
    available
      .filter(({ code }) => !isBuy(type) || getBalance(code))
      .sort((a, b) => sortCoins(a.code, b.code)),
);
export const getBSPossibleRightCoins = createSelector(
  [getBSOrderType, getPossibleMatchingCoins, getAvailableBalanceByCode, getAvailableBalanceCoins, getCoinByCode],
  (type, getPossible, getBalance, available, getByCode) => (left: CoinCode) => {
    // when selling any or buying EUR then crypto-crypto flow does not apply
    if (!isBuy(type) || isEuro(left)) {
      const possible = getPossible(left).filter(({ code }) => !isBuy(type) || getBalance(code));

      // make sure euro is on list if selling and left is not eur
      if (!isEuro(left) && !possible.some(({ code }) => isEuro(code))) {
        possible.push(getByCode(CoinCode.EUR)!);
      }
      return possible.sort((a, b) => sortCoins(a.code, b.code));
    }

    return available.filter(({ code }) => code !== left);
  },
);
export const getBSDefaultRightCoinCode = createSelector(
  [getBSPossibleRightCoins, getDefaultMatchingCoinCode],
  (getPossible, getDefault) => (left: CoinCode) => {
    const possible = getPossible(left);
    if (!possible.length) return getDefault(left);

    return possible[0].code;
  },
);
export const canBSPayInEuro = createSelector(
  [getBSOrderType],
  (type) => (left: CoinCode) => isBuy(type) && !isEuro(left),
);

export const getBSAvailableRightWallets = createSelector(
  [getBSAvailableRightCoins, getCardsList, hasCardsEnabled],
  (availableRight, cards, areCardsEnabled): Wallet[] => {
    const wallets: Wallet[] = availableRight.map(({ code }) =>
      ({ type: 'coin' as Wallet['type'], code, id: null }));
    if (!areCardsEnabled) return wallets;

    const cardWallets: Wallet[] = cards.map(({ id }) =>
      ({ type: 'card' as Wallet['type'], code: CoinCode.EUR, id }));

    // switch places if cardWallets have higher priority
    return [...wallets, ...cardWallets];
  },
);
export const getBSPossibleRightWallets = createSelector(
  [getBSPossibleRightCoins, canBSPayInEuro, getCardsList, hasCardsEnabled],
  (getPossibleRight, canPayInEur, cards, areCardsEnabled) => (type: OrderType, left: CoinCode): Wallet[] => {
    const possible: Wallet[] = getPossibleRight(left).map(({ code }) =>
      ({ type: 'coin' as Wallet['type'], code, id: null }));

    const canUseCard = areCardsEnabled && isBuy(type) && canPayInEur(left) && cards.length;
    if (!canUseCard) {
      return possible;
    }

    const cardWallets: Wallet[] = cards.map(({ id }) =>
      ({ type: 'card' as Wallet['type'], code: CoinCode.EUR, id }));
    return possible.concat(cardWallets);
  },
);
export const getBSDefaultRightWallet = createSelector(
  [getBSPossibleRightWallets, getDefaultMatchingCoinCode],
  (getPossible, getDefault) => (type: OrderType, left: CoinCode): Wallet => {
    const possible = getPossible(type, left);
    if (!possible.length) {
      // if no funds and is not buying EUR
      // then allow buying for EUR to make card payments work correctly
      const code = type === 'buy' && !isEuro(left)
        ? CoinCode.EUR
        : getDefault(left)!;

      return {
        type: 'coin',
        code,
        id: null,
      };
    }

    // try last used
    const last = Services.get('buySell').lastWallet;
    if (last !== null) {
      const isAvailable = possible.some((wallet) => areEqualObjects(wallet, last));
      if (isAvailable) return last;
    }

    return possible[0];
  },
);


export const canBSChangeRight = createSelector(
  [getBSOrderType, getBSPossibleRightCoins, getBSLeftCoinCode],
  (type, getPossible, left) => {
    // TODO: check on sell and reversed
    const possible = getPossible(left);
    if (!isBuy(type) || isEuro(left)) {
      return possible.length > 1;
    }

    // changing right is possible when buy and can pay in EUR
    // because new card may be added
    return isBuy(type) || possible.length > 1;
  },
);

export const getBSCard = createSelector(
  [getBSPaymentMethod, getCardsList],
  (paymentMethod, cards) => {
    if (!isCardPayment(paymentMethod)) return null;

    return cards.find(({ id }) => id === paymentMethod.id) || null;
  },
);


export const getBSOrderbookEntries = createSelector(
  [getBSOrderType, getOrderbook, isBSMarketReversed],
  (type, orderbook, isReversed) => {
    const orderbookType = getOrderbookType(type, isReversed);
    return orderbook[orderbookType];
  },
);

export const isBSCrossMarket = createSelector(
  [getBSMarket, getBSRightCoinCode],
  (market, right) => isEuro(right) && market === null,
);

export const isBSConversionNeeded = createSelector(
  [getBSMarket, getBSLeftCoinCode, getBSRightCoinCode],
  (market, left, right) => market === null && !isEuro(left) && !isEuro(right),
);
export const getBSConversionFundsNeeded = createSelector(
  [isBSConversionNeeded, getBSLeftCoinCode, getBSRightCoinCode, getBSCoinValue, getLatestMarketPrice, getUserFee],
  (needsConversion, left, right, value, getPrice, fee) => {
    if (isNaN(value) || !needsConversion) return 0;

    const btcNeeded = parseFloat(value) * getPrice(left, CoinCode.BTC);
    const btcFee = floatMultiply(btcNeeded, fee);
    const funds = (btcNeeded + btcFee) / getPrice(right, CoinCode.BTC);
    return funds;
  },
);


export const getBSLeftTotalBalance = createSelector(
  [getBSLeftCoinCode, getBalanceByCode],
  (code, getBalance) => {
    const balance = getBalance(code);
    if (!balance) return BALANCE_EMPTY;

    return balance;
  },
);
export const getBSRightTotalBalance = createSelector(
  [getBSRightCoinCode, getBalanceByCode],
  (code, getBalance) => {
    const balance = getBalance(code);
    if (!balance) return BALANCE_EMPTY;

    return balance;
  },
);

export const getBSLeftBalance = createSelector(
  [getBSLeftTotalBalance],
  (balance) => parseFloat(balance.availableBalance),
);
export const getBSRightBalance = createSelector(
  [getBSRightTotalBalance],
  (balance) => parseFloat(balance.availableBalance),
);

export const isBSFiatVisible = createSelector(
  [getBSLeftCoinCode, getCurrentFiatCoin],
  (code, fiat) => code !== fiat.code,
);


export const getBSReceiptValues = createSelector(
  [getBSQuote, getCoinByCode, getBSOrderType],
  (quote, getCoin, orderType): ReceiptValues => {
    if (!quote) {
      return {
        calculated: false,
        amount: 0, subtotal: 0, total: 0,
        fee: 0, feeCoin: null as any,
        quote: null,
      };
    }

    const result = {
      calculated: true as const,
      quote,
      fee: quote.fee,
      feeCoin: getCoin(quote.feeCurrency)!,
    };

    const isReversed = isOrderType(quote.orderType) && orderType !== quote.orderType;
    if (isBuy(orderType)) {
      if (isReversed) {
        return {
          ...result,
          amount: quote.total,
          subtotal: quote.volume,
          total: quote.volume,
        };
      }

      return {
        ...result,
        amount: quote.price,
        subtotal: quote.volume,
        total: quote.total,
      };
    } else {
      if (isReversed) {
        return {
          ...result,
          amount: quote.total,
          subtotal: quote.price,
          total: quote.price,
        };
      }

      return {
        ...result,
        amount: quote.volume,
        subtotal: quote.total,
        total: quote.total + result.fee,
      };
    }
  },
);


export const getBSTotalFundsNeeded = createSelector(
  [getBSOrderType, isBSConversionNeeded, getBSConversionFundsNeeded, getBSReceiptValues],
  (type, isConversionNeeded, conversionFunds, receipt) => {
    if (isBuy(type) && isConversionNeeded) {
      return conversionFunds;
    }

    if (!receipt.calculated) return 0;
    return isBuy(type) ? receipt.total : receipt.amount;
  },
);

export const hasBSEnoughFunds = createSelector(
  [getBSTotalFundsNeeded, getBSOrderType, getBSLeftBalance, getBSRightBalance, getBSPaymentMethod],
  (value, type, leftBalance, rightBalance, paymentMethod) => {
    if (isNaN(value) || value <= 0) return true;
    if (isCardPayment(paymentMethod)) return true;
    if (isBuy(type)) {
      return rightBalance >= value;
    }

    return leftBalance >= value;
  },
);
