import React from 'react';

const getIndex = (path: string) => {
  const match = /@(\d)x/.exec(path);
  if (!match || !match.length) return 1;
  return +match[1];
};
const getDefaultExtension = (paths: string[]) => paths.some((p) => p.includes('jpg')) ? 'jpg' : 'png';
const getPathsSorted = (paths: string[], ext: string) => paths
  .filter((p) => p.includes(`.${ ext }`))
  .sort((a, b) => getIndex(a) - getIndex(b));
const getSrcSet = (paths: string[], ext: string) => getPathsSorted(paths, ext)
  .map((p) => `${ p } ${ getIndex(p) }x`)
  .join(',\n');

type Props = {
  className?: string,
  width?: number,
  height?: number,
  paths: string[],
  alt?: string,
};

const Image: React.FC<Props> = React.memo(({
  paths,
  className,
  width,
  height,
  alt = '',
}: FunctionProps<Props>) => {
  if (!paths.length) {
    if (__DEV__ || __STAGING__) throw new Error('LocalImage received empty paths array!');
    return null;
  }

  const hasWebp = paths.some((p) => p.includes('.webp'));
  const isResponsive = paths.some((p) => p.includes('@3x'));
  const extension = getDefaultExtension(paths);
  const defaultPath = getPathsSorted(paths, extension)[0];

  return (
    <picture>
      { hasWebp &&
        <source
          className={ className }
          srcSet={ isResponsive ? getSrcSet(paths, 'webp') : undefined }
          type="image/webp"
        />
      }
      <img
        className={ className }
        width={ width }
        height={ height }
        alt={ alt }
        src={ defaultPath }
        srcSet={ isResponsive ? getSrcSet(paths, extension) : undefined }
      />
    </picture>
  );
});

export default Image;
