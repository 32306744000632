import debounce from 'lodash/debounce';
import Services from 'services';

const DISCONNECTED_CHECK_DELAY = 18 * 1000;
const CONNECTED_CHECK_DELAY = 3 * 1000;

class CableConnectionService {
  cable: ActionCable.Cable;
  connectionCheckTimer: number | null = null;

  constructor(cable: ActionCable.Cable) {
    this.cable = cable;
    this.check = debounce(this.check, 500, { leading: false, trailing: true });
  }
  check = (type: 'disconnected' | 'connected') => {
    if (this.connectionCheckTimer !== null) return;

    this.connectionCheckTimer = window.setTimeout(
      () => this.checkConnection(),
      type === 'disconnected' ? DISCONNECTED_CHECK_DELAY : CONNECTED_CHECK_DELAY,
    );
  };
  private checkConnection() {
    this.connectionCheckTimer = null;
    const connection = (this.cable as any).connection;
    const isConnected = connection.isActive() && connection.isOpen();

    Services.get('events').emit('CABLE_CONNECTION', isConnected);
  }
}

export default CableConnectionService;
